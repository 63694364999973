import { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import "../../../css/team.scss";
import { digitFormat } from '../../../utils/function';
import { translate } from "../../../utils/translate";
import InfoBox from '../Common/InfoBox';

const TeamOverview = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [teamOverviewInfo, setTeamOverviewInfo] = useState({
        personalSales: '',
        groupSales: '',
        myRank: '1',
        totalActiveMember: '',
        totalGroupMember: '',
    });
    const [rankList, setRankList] = useState([]);

    useEffect(() => {
        getTeamOverview();
    }, [props.auth.auth.token]);

    const getTeamOverview = () => {
    }

    return (
        <>
            <div className='pb-5 team'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={4} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBox
                          
                            boxLabel={translate(props.lang, "My Rank")}
                            boxValue={teamOverviewInfo.myRank}
                            loading={loading}
                            blackBg={true}
                            iconClassName={'rank-icon'}
                        />
                    </Col>
                    <Col lg={8} md={12} sm={12} xs={12} className='mb-4'>
                        <InfoBox
                            icon={require('../../../images/team/groupsales.svg').default}
                            boxLabel={translate(props.lang, "Personal Sales")}
                            boxValue={`USD ${teamOverviewInfo.personalSales ? Number(teamOverviewInfo.personalSales).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                            twoInrow={true}
                            icon2={require('../../../images/team/personalsales.svg').default}
                            boxLabel2={translate(props.lang, "Group Sales")}
                            boxValue2={`USD ${teamOverviewInfo.groupSales ? Number(teamOverviewInfo.groupSales).toLocaleString('en-US', digitFormat) : '0.00'}`}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center mx-0 px-0">
                    <Col lg={8} md={8} sm={12} xs={12} className='mb-4'>
                        <InfoBox
                            icon2={require('../../../images/team/totalgroupmember.svg').default}
                            boxLabel2={translate(props.lang, "Active Group Member")}
                            boxValue2={teamOverviewInfo.totalActiveMember ? Number(teamOverviewInfo.totalActiveMember).toLocaleString('en-US') : '0'}
                            loading={loading}
                            twoInrow={true}
                            icon={require('../../../images/team/activemember.svg').default}
                            boxLabel={translate(props.lang, "Total Group Member")}
                            boxValue={teamOverviewInfo.totalGroupMember ? Number(teamOverviewInfo.totalGroupMember).toLocaleString('en-US') : '0'}
                        />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className='mb-4'>
                        <InfoBox
                            icon={require('../../../images/team/levelbonus.svg').default}
                            boxLabel={translate(props.lang, "Level Bonus")}
                            boxValue={`USD ${teamOverviewInfo.groupSales ? Number(teamOverviewInfo.groupSales).toLocaleString('en-US', digitFormat) : '0.00'}`}
                            loading={loading}
                            yellowBg={true}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TeamOverview);