import React, { useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import swal from "sweetalert";

/// Css
import "../css/custom.scss";
import "./chart.css";
import "./index.css";
import "./step.css";

/// Layout
import routes from "../utils/route";
import Nav from "./layouts/nav";

import { ThemeContext } from "../context/ThemeContext";
import { logout } from "../store/actions/AuthActions";
import { api } from "../utils/api";
import { translate } from "../utils/translate";

const Markup = (props) => {
  const dispatch = useDispatch();
  const { menuToggle } = useContext(ThemeContext);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  useEffect(() => {
    checkUserInfo();
  }, [props.auth.auth.id_token])

  const checkUserInfo = () => {
    let responseJson1 = {
      "data": {
        "username": "demo",
        "userId": "7508474777",
        "fullName": "demo",
        "email": "demo@gmail.com",
        "gender": "Male",
        "phoneNumber": "0123456789",
        "dateOfBirth": "2023-10-10",
        "joiningDate": "2023-11-01T17:37:46.36204",
        "referral": "MU568ZVL",
        "referralCode": "KA8IW7SP",
        "address": "selangor msia",
        "country": null,
        "kycStatus": "APPROVED",
        "profilePicture": "",
        "mt5Username": null,
        "mt5Password": null,
        "bindCopyTradeStatus": null,
        "mt5AccountTwoUsername": null,
        "mt5AccountTwoPassword": null,
        "mt5AccountTwobindCopyTradeStatus": null
      }
    }
    dispatch({
      type: 'UPDATE_PROFILE',
      profile: responseJson1.data
    });
    // fetch(api.userInfo, {
    //   method: "GET",
    //   headers: new Headers({
    //     "Content-Type": "application/json",
    //     "Accept-Language": props.lang,
    //     "Authorization": "Bearer " + props.auth.auth.id_token
    //   }),
    // })
    //   .then(response => response.json())
    //   .then(responseJson => {
    //     if (responseJson.status === "ok") {
    //       dispatch({
    //         type: 'UPDATE_PROFILE',
    //         profile: responseJson.data
    //       });
    //       if (localStorage.getItem('bcg-crm')) {
    //         let data = JSON.parse(localStorage.getItem('bcg-crm'));
    //         localStorage.setItem('bcg-crm', JSON.stringify(data));
    //       }

    //     } else if (responseJson.status === 401) {
    //       dispatch(logout(props.history, 'login'));
    //     } else {
    //       console.log('error profile', responseJson.message)
    //       // swal(translate(props.lang, "Error"), responseJson.message, "error");
    //     }

    //   }).catch(error => {
    //     console.error("error", error);
    //   });
  }
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "vh-100"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav />}
        <div className={`${!pagePath ? "content-body" : ""}`} id="contentBody">
          <div className={`overflow-x-auto`}>
            <div
              className={`${!pagePath ? "container-fluid" : ""}`}
              style={{ minHeight: window.screen.height - 60 }}
            >
              <Switch>
                {routes.map((item, i) => (
                  <Route
                    key={i}
                    path={item.url}
                    exact
                    component={item.component}
                  />
                ))}
              </Switch>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}

export default withRouter(connect(mapStateToProps)(Markup));
