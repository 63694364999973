import Dashboard from "../jsx/page/Dashboard/Dashboard";
import Profile from "../jsx/page/Profile/Profile";
import WalletOverview from "../jsx/page/Wallet/Overview";
import Deposit from "../jsx/page/Wallet/Deposit";
import Withdrawal from "../jsx/page/Wallet/Withdrawal";
import TransactionHistory from "../jsx/page/Wallet/TransactionHistory";
import BonusHistoryDetails from "../jsx/page/Wallet/BonusHistoryDetails";
import TeamOverview from "../jsx/page/Team/Overview";
import Referral from "../jsx/page/Team/Referral";
import TeamInvestment from "../jsx/page/Team/TeamInvestment";
import SettingOverview from "../jsx/page/Setting/Overview";
import KYC from "../jsx/page/Setting/KYC";
import ChangePassword from "../jsx/page/Setting/ChangePassword";
import ChangeTransactionPassword from "../jsx/page/Setting/ChangeTransactionPassword";
import Notification from "../jsx/page/Notification/Notification";
import CryptoDeposit from "../jsx/page/Wallet/CryptoDeposit";
import BankInfo from "../jsx/page/Wallet/BankInfo";
import WithdrawalMethod from "../jsx/page/Wallet/WithdrawalMethod";
import GoldTrading from "../jsx/page/Investment/GoldTrading";
import TransactionRecord from "../jsx/page/Investment/TransactionRecord";
import OrderBook from "../jsx/page/Investment/OrderBook";

const routes = [
    { url: "/", component: Dashboard, name: "Dashboard" },
    { url: "/dashboard", component: Dashboard, name: "Dashboard" },
    { url: "/wallet/overview", component: WalletOverview, name: "Wallet Overview" },
    { url: "/wallet/deposit", component: Deposit, name: "Deposit" },
    { url: "/wallet/deposit/crypto", component: CryptoDeposit, name: "Fund Your Wallet" },
    { url: "/wallet/withdrawal", component: Withdrawal, name: "Withdrawal" },
    { url: "/wallet/transaction-history", component: TransactionHistory, name: "Transaction History" },
    { url: "/wallet/bonus-history-details", component: BonusHistoryDetails, name: "Bonus History Details" },
    { url: "/wallet/bankInfo", component: BankInfo, name: "Bank Info" },
    { url: "/wallet/bankInfo/withdraw-method", component: WithdrawalMethod, name: "Bank Info" },
    { url: "/team/overview", component: TeamOverview, name: "Team Overview" },
    { url: "/team/team-investment", component: TeamInvestment, name: "Team Investment" },
    { url: "/team/referral", component: Referral, name: "Referral" },
    { url: "/profile", component: Profile, name: "Profile" },
    { url: "/settings/overview", component: SettingOverview, name: "Security" },
    { url: "/settings/kyc", component: KYC, name: "KYC" },
    { url: "/settings/change-password", component: ChangePassword, name: "Change Password" },
    { url: "/settings/change-transaction-password", component: ChangeTransactionPassword, name: "Forget Transaction Password" },
    { url: "/notification", component: Notification, name: "Notification" },
    { url: "/investment/goldTrading", component: GoldTrading, name: "gold trading" },
    { url: "/investment/transactionRecord", component: TransactionRecord, name: "Transaction Record" },
    { url: "/investment/orderBook", component: OrderBook, name: "Order Book" },
];

export default routes;