import moment from "moment";
import { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row, Table } from "reactstrap";
import "../../../css/wallet.scss";
import "../../../css/circle.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";
import swal from "sweetalert";
const list1 = [
    { orderId: 1, date: '2023-09-27 12:00:02', side: 'Buy', pair: 'GOLD/USDT', tradeType: 'Market', price: '0.10', amount: '100', total: '10', status: 'active' },
    { orderId: 2, date: '2023-09-27 12:00:02', side: 'Sell', pair: 'GOLD/USDT', tradeType: 'LIMIT', price: '0.20', amount: '200', total: '40', status: 'complete' },
]

const list3 = [
    {
        date: "2023-09-27 12:00:02",
        currencyGet: "GOLD",
        currencyPay: "USDT",
        totalAmountGet: 1.0000,
        totalAmountPay: 0.1000,
        processingFee: 0.0010,
        actualAmountGet: 0.9990,
        orderNo: "ORDER001",
        orderType: "Buy",
        price: 0.1000,
        role: "TAKER",
        filled: 1.0000,
        total: 0.1000
    },
    {
        date: "2023-09-27 12:00:02",
        currencyGet: "GOLD",
        currencyPay: "USDT",
        totalAmountGet: 1.0000,
        totalAmountPay: 0.1000,
        processingFee: 0.0010,
        actualAmountGet: 0.9990,
        orderNo: "ORDER001",
        orderType: "Sell",
        price: 0.1000,
        role: "MAKER",
        filled: 1.0000,
        total: 0.1000
    },
]

const list = [
    { datetime: '2023-09-27 12:00:02', description: 'USDT Deposit', type: 'USDT Deposit', amount: '100,000,000.00', status: 'pending', remark: '2.50 从 test3@gmail.com', },
    { datetime: '2023-09-27 12:00:02', description: 'USDT Deposit', type: 'USDT Deposit', amount: '100,000,000.00', status: 'pending', remark: '2.50 从 test3@gmail.com', },
    { datetime: '2023-09-27 12:00:02', description: 'USDT Deposit', type: 'USDT Deposit', amount: '100,000,000.00', status: 'pending', remark: '2.50 从 test3@gmail.com', },
    { datetime: '2023-09-27 12:00:02', description: 'USDT Deposit', type: 'USDT Deposit', amount: '100,000,000.00', status: 'pending', remark: '2.50 从 test3@gmail.com', },
    { datetime: '2023-09-27 12:00:02', description: 'USDT Deposit', type: 'USDT Deposit', amount: '100,000,000.00', status: 'pending', remark: '2.50 从 test3@gmail.com', },
]

const TransactionRecord = (props) => {
    const dispatch = useDispatch();
    const productPerpage = window.innerWidth > 991 ? 10 : 5;
    const [pageCount, setPageCount] = useState(5);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState(list);
    const [cancelOrderId, setCancelOrderId] = useState('');
    const [tab, setTab] = useState(1);
    const [selectedAccordion, setSelectedAccordion] = useState(0);

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    useEffect(() => {
        return () => { };
    }, [props.auth.auth.token, pageNumber, props.lang]);

    const cancelOrder = (id) => {
        swal({
            title: translate(props.lang, "Warning"),
            text: translate(props.lang, "Are you sure you want to cancel this order?"),
            icon: "warning",
            buttons: [translate(props.lang, "Cancel"), translate(props.lang, "Confirm")],
            dangerMode: false,
        })
            .then((willConfirm) => {
                if (willConfirm) {
                    swal(translate(props.lang, "Success"), translate(props.lang, "Order has been cancelled"), "success");
                }
            });
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="whiteContainer py-4 px-2 px-md-4">
                            <div className="d-flex w-100 justify-content-center justify-content-md-start">
                                <div onClick={() => setTab(1)}>
                                    <span className={`font-16 font-weight-600 ${tab === 1 ? 'primary-text' : 'text-grey-8'} text-capitalize cursor-pointer`}>{translate(props.lang, "Open Orders")}</span>
                                </div>
                                <div className="px-3">
                                    <span className="font-16 font-weight-600 text-grey-8 text-capitalize">|</span>
                                </div>
                                <div onClick={() => setTab(2)}>
                                    <span className={`font-16 font-weight-600 ${tab === 2 ? 'primary-text' : 'text-grey-8'} text-capitalize cursor-pointer`}>{translate(props.lang, "Order History")}</span>
                                </div>
                                <div className="px-3">
                                    <span className="font-16 font-weight-600 text-grey-8 text-capitalize">|</span>
                                </div>
                                <div onClick={() => setTab(3)}>
                                    <span className={`font-16 font-weight-600 ${tab === 3 ? 'primary-text' : 'text-grey-8'} text-capitalize cursor-pointer`}>{translate(props.lang, "Trade History")}</span>
                                </div>
                            </div>
                            {tab === 1 ? (
                                <>
                                    {/* 订单 */}
                                    <div className="tableContainer d-none d-md-block">
                                        <Table responsive className="w-100 table-list mt-4 text-center position-relative">
                                            <thead>
                                                <tr>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Date")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Side")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Pair")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Trade Type")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Price")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Total")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}></th>
                                                </tr>
                                            </thead>
                                            {loading ? (
                                                <tbody>
                                                    <tr role="row">
                                                        <td colSpan={8}>
                                                            <LoadingV3 theme='light' />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    {list1.filter(item=>item.status === 'active').length > 0 ?
                                                        list1.filter(item=>item.status === 'active').map((item, i) => (
                                                            <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                                <td className={`font-weight-500 font-16 text-center`}>{moment(item.date).format('DD/MM/YYYY, HH:mm:ss')}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{translate(props.lang, item.side)}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.pair}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{translate(props.lang, item.tradeType.toUpperCase())}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.price}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.amount}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.total}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>
                                                                    {item.status === 'active' ? (
                                                                        <button className="cancelItemBtn px-2 py-2" disabled={(cancelOrderId === item.orderId && loading)} onClick={() => cancelOrder(item.orderId)}>
                                                                            <span className="font-weight-500 font-14">{translate(props.lang, "Cancel")}</span>
                                                                        </button>
                                                                    ) : null}
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr className="py-5 text-center bg-transparent">
                                                            <td colSpan={8}>
                                                                <img src={require('../../../images/noData.svg').default} className="img-fluid pt-4 noDataImgSize" alt="no-data" />
                                                                <span className="d-block text-black mt-4">
                                                                    {translate(props.lang, "Oops! No data found...")}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                    <div className="d-block d-md-none tableContainerAccordion mt-3">
                                        {loading ? (<LoadingV3 theme="dark" />) : (
                                            list1.filter(item=>item.status === 'active').length > 0 ? (
                                                list1.filter(item=>item.status === 'active').map((item, i) => (
                                                    <div key={i} className={`border-bottom`}>
                                                        <Row className="py-2 align-items-start px-0 mx-0">
                                                            <Col sm={3} xs={3}>
                                                                <div className="text-start mb-1">
                                                                    <span className="font-10 font-weight-500 text-grey-2">{translate(props.lang, item.tradeType)} / <span className={`${item.orderType === "Buy" ? 'text-success' : 'text-red'}`}>{translate(props.lang, item.side)}</span></span>
                                                                </div>
                                                                <div className={`c100 ${"p50"} green`}>
                                                                    <span>50%</span>
                                                                    <div className="slice">
                                                                        <div className="bar"></div>
                                                                        <div className="fill"></div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <span className="font-14 font-weight-600 page-title-color">USDT</span>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, 'Amount')}: </span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">10</span>
                                                                        <span className="font-12 font-weight-600 text-grey-2"> / 20</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-1">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, 'Price')}: </span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">{item.price}</span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="text-end">
                                                                    <span className="font-12 font-weight-500 text-grey-2 break-all-word">{item.date}</span>
                                                                </div>
                                                                <div className="text-end mt-2">
                                                                    {item.status === 'active' ? (
                                                                        <button className="cancelItemBtn px-2 py-2" disabled={(cancelOrderId === item.orderId && loading)} onClick={() => cancelOrder(item.orderId)}>
                                                                            <span className="font-weight-500 font-14">{translate(props.lang, "Cancel")}</span>
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="py-5 text-center bg-transparent">
                                                    <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                    <span className="d-block text-black mt-4">
                                                        {translate(props.lang, "Oops! No data found...")}
                                                    </span>
                                                </div>)
                                        )}
                                    </div>
                                </>
                            ) : tab === 2 ? (
                                <>
                                    {/* 订单记录 */}
                                    <div className="tableContainer d-none d-md-block">
                                        <Table responsive className="w-100 table-list mt-4 text-center position-relative">
                                            <thead>
                                                <tr>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Date")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Side")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Pair")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Trade Type")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Price")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Total")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}></th>
                                                </tr>
                                            </thead>
                                            {loading ? (
                                                <tbody>
                                                    <tr role="row">
                                                        <td colSpan={8}>
                                                            <LoadingV3 theme='light' />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    {list1.length > 0 ?
                                                        list1.map((item, i) => (
                                                            <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                                <td className={`font-weight-500 font-16 text-center`}>{moment(item.date).format('DD/MM/YYYY, HH:mm:ss')}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{translate(props.lang, item.side)}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.pair}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{translate(props.lang, item.tradeType.toUpperCase())}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.price}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.amount}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.total}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>
                                                                    {item.status === 'active' ? (
                                                                        <button className="cancelItemBtn px-2 py-2" disabled={(cancelOrderId === item.orderId && loading)} onClick={() => cancelOrder(item.orderId)}>
                                                                            <span className="font-weight-500 font-14">{translate(props.lang, "Cancel")}</span>
                                                                        </button>
                                                                    ) : null}
                                                                </td>
                                                            </tr>
                                                        )) :
                                                        <tr className="py-5 text-center bg-transparent">
                                                            <td colSpan={8}>
                                                                <img src={require('../../../images/noData.svg').default} className="img-fluid pt-4 noDataImgSize" alt="no-data" />
                                                                <span className="d-block text-black mt-4">
                                                                    {translate(props.lang, "Oops! No data found...")}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                    <div className="d-block d-md-none tableContainerAccordion mt-3">
                                        {loading ? (<LoadingV3 theme="dark" />) : (
                                            list1.length > 0 ? (
                                                list1.map((item, i) => (
                                                    <div key={i} className={`border-bottom`}>
                                                        <Row className="py-2 align-items-start px-0 mx-0">
                                                            <Col sm={3} xs={3}>
                                                                <div className="text-start mb-1">
                                                                    <span className="font-10 font-weight-500 text-grey-2">{translate(props.lang, item.tradeType)} / <span className={`${item.orderType === "Buy" ? 'text-success' : 'text-red'}`}>{item.side}</span></span>
                                                                </div>
                                                                <div className={`c100 ${"p50"} green`}>
                                                                    <span>50%</span>
                                                                    <div className="slice">
                                                                        <div className="bar"></div>
                                                                        <div className="fill"></div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>
                                                                    <span className="font-14 font-weight-600 page-title-color">USDT</span>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, 'Amount')}: </span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">10</span>
                                                                        <span className="font-12 font-weight-600 text-grey-2"> / 20</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-1">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, 'Price')}: </span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">{item.price}</span>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="text-end">
                                                                    <span className="font-12 font-weight-500 text-grey-2 break-all-word">{item.date}</span>
                                                                </div>
                                                                <div className="text-end mt-2">
                                                                    {item.status === 'active' ? (
                                                                        <button className="cancelItemBtn px-2 py-2" disabled={(cancelOrderId === item.orderId && loading)} onClick={() => cancelOrder(item.orderId)}>
                                                                            <span className="font-weight-500 font-14">{translate(props.lang, "Cancel")}</span>
                                                                        </button>
                                                                    ) : null}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="py-5 text-center bg-transparent">
                                                    <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                    <span className="d-block text-black mt-4">
                                                        {translate(props.lang, "Oops! No data found...")}
                                                    </span>
                                                </div>)
                                        )}
                                    </div>
                                </>
                            ) : tab === 3 ? (
                                <>
                                    {/* 交易记录 */}
                                    <div className="tableContainer d-none d-md-block">
                                        <Table responsive className="w-100 table-list mt-4 text-center position-relative">
                                            <thead>
                                                <tr>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Type")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Price")}(USDT)</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Filled")}(GOLD)</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Role")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Fee")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Received")}</th>
                                                    <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Datetime")}</th>
                                                </tr>
                                            </thead>
                                            {loading ? (
                                                <tbody>
                                                    <tr role="row">
                                                        <td colSpan={7}>
                                                            <LoadingV3 theme='light' />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            ) : (
                                                <tbody>
                                                    {list3.length > 0 ?
                                                        list3.map((item, i) => (
                                                            <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                                <td className={`font-weight-500 font-16 text-center ${item.orderType === "Buy" ? 'text-success' : 'text-red'}`}>{translate(props.lang, item.orderType)}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.price}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.filled}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{translate(props.lang,item.role)}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.processingFee} {item.orderType === 'BUY' ? 'GOLD' : 'USDT'}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{item.actualAmountGet} {item.orderType === 'BUY' ? 'GOLD' : 'USDT'}</td>
                                                                <td className={`font-weight-500 font-16 text-center`}>{moment(item.date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                                            </tr>
                                                        )) :
                                                        <tr className="py-5 text-center bg-transparent">
                                                            <td colSpan={7}>
                                                                <img src={require('../../../images/noData.svg').default} className="img-fluid pt-4 noDataImgSize" alt="no-data" />
                                                                <span className="d-block text-black mt-4">
                                                                    {translate(props.lang, "Oops! No data found...")}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            )}
                                        </Table>
                                    </div>
                                    <div className="d-block d-md-none tableContainerAccordion mt-3">
                                        {loading ? (<LoadingV3 theme="dark" />) : (
                                            list3.length > 0 ? (
                                                list3.map((item, i) => (
                                                    <div key={i} className={`border-bottom`}>
                                                        <Row className="py-2 align-items-center px-0 mx-0">
                                                            <Col sm={12} xs={12}>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className={`font-14 font-weight-600 ${item.orderType === "Buy" ? 'text-success' : 'text-red'}`}>{translate(props.lang, item.orderType)}</span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{moment(item.date).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, 'Price')} (USDT)</span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">
                                                                            {item.price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, 'Filled')} (GOLD)</span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">
                                                                            {item.filled.toLocaleString('en-US', { maximumFractionDigits: 5, minimumFractionDigits: 5 })}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, "Role")}</span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">{translate(props.lang, item.role)}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, "Fee")} ({item.orderType === 'BUY' ? 'GOLD' : 'USDT'})</span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">{item.orderType === 'BUY' ? item.processingFee.toLocaleString('en-US', { maximumFractionDigits: 5, minimumFractionDigits: 5 }) : item.processingFee.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex w-100 justify-content-between mt-2">
                                                                    <div className="text-start">
                                                                        <span className="font-12 font-weight-400 text-grey-2">{translate(props.lang, 'Received')} ({item.orderType === 'BUY' ? 'GOLD' : 'USDT'})</span>
                                                                    </div>
                                                                    <div className="text-end">
                                                                        <span className="font-12 font-weight-600 page-title-color">
                                                                            {item.orderType === 'BUY' ? item.actualAmountGet.toLocaleString('en-US', { maximumFractionDigits: 5, minimumFractionDigits: 5 }) : item.actualAmountGet.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="py-5 text-center bg-transparent">
                                                    <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                    <span className="d-block text-black mt-4">
                                                        {translate(props.lang, "Oops! No data found...")}
                                                    </span>
                                                </div>)
                                        )}
                                    </div>
                                </>
                            ) : null}
                            <div className="pt-4">
                                {!loading && result.length > 0 ? (
                                    <div className="mt-3">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            forcePage={pageNumber - 1}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-black"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TransactionRecord);