import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AuthHeader from '../../layouts/nav/AuthHeader';
import { translate } from '../../../utils/translate';
import "../../../css/auth.scss";


function Success(props) {
    const history = useHistory();
    const goBack = () => {
        history.push('/login');
    }

    return (
        <>
            <div className='login min-vh-100'>
                <AuthHeader logoColor={'blue'} />
                <div className={`authincation d-flex flex-column flex-lg-row flex-column-fluid h-100 pt-lg-0 pt-5`}>

                    <div className="w-100 pt-0 min-vh-100 d-flex align-items-center justify-content-center">
                        <Container className='h-100'>
                            <Row className="h-100 align-items-center justify-content-center mx-0 px-0 py-5">
                                <Col lg={6} md={12} sm={12} xs={12} className='mt-5'>
                                    <Row className='px-0 mx-0 align-items-center justify-content-center'>
                                        <Col lg={12} md={12} sm={12} xs={12} className='mb-5'>
                                            <div className='text-center'>
                                                <img src={require('../../../images/logo/logo.png').default} alt="logo" className='logoSize' />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='whiteContainer px-3 py-5 p-md-5'>
                                        <div className='text-center'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128" fill="none">
                                                <g clipPath="url(#clip0_551_34627)">
                                                    <path d="M92.291 43.6924C94.2441 45.6455 94.2441 48.8115 92.291 50.7637L58.748 84.3076C56.7949 86.2598 53.6299 86.2598 51.6768 84.3076L35.709 68.3389C33.7559 66.3867 33.7559 63.2207 35.709 61.2686C37.6611 59.3154 40.8271 59.3154 42.7793 61.2686L55.2119 73.7012L85.2197 43.6924C87.1729 41.7402 90.3389 41.7402 92.291 43.6924ZM128 64C128 99.376 99.3711 128 64 128C28.624 128 0 99.3711 0 64C0 28.624 28.6289 0 64 0C99.376 0 128 28.6289 128 64ZM118 64C118 34.1514 93.8447 10 64 10C34.1514 10 10 34.1553 10 64C10 93.8486 34.1553 118 64 118C93.8486 118 118 93.8447 118 64Z" fill="#D5A529" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_551_34627">
                                                        <rect width="128" height="128" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className='text-center mt-4'>
                                            <span className='font-weight-600 font-40 text-black-1 text-capitalize d-block'>{translate(props.lang, "Success")}</span>
                                            <span className='font-weight-500 font-16 text-grey-1 d-block mt-3 px-5 line-height-24'>{translate(props.lang, "Congratulations, your account has been successfully created.")}</span>
                                        </div>
                                        <div className='text-center mt-4'>
                                            <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => goBack()} >
                                                <span className={`font-weight-600 font-20 line-height-30 text-white`}>{translate(props.lang, "Continue")}</span>
                                            </button>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
        lang: i18n.lang,
    };
};
export default withRouter(connect(mapStateToProps)(Success));
