import moment from "moment";
import { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import ReactPaginate from "react-paginate";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row, Table } from "reactstrap";
import "../../../css/wallet.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";

const bonustrxList = [
    { trxDate: '27/09/2023  12:06:21', bonusType: 'USDT Deposit', amount: 100, status: 'pending', remark: '-' },
    { trxDate: '27/09/2023  12:06:21', bonusType: 'USDT Deposit', amount: 100, status: 'pending', remark: '-' },
]

const BonusHistoryDetails = (props) => {
    const dispatch = useDispatch();
    const productPerpage = window.innerWidth > 991 ? 10 : 5;
    const [pageCount, setPageCount] = useState(4);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [result, setResult] = useState(bonustrxList);
    const [selectedAccordion, setSelectedAccordion] = useState(0);

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    useEffect(() => {
        getBonusTransaction();
        return () => {};
    }, [props.auth.auth.token, pageNumber, props.lang]);

    const getBonusTransaction = () => {

    }

    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="whiteContainer py-4 px-4">
                            <div className="d-flex align-items-center w-100">
                                <Link to="/wallet/overview" className="cursor-pointer">
                                    <div className="d-flex align-items-center">
                                        <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                    </div>
                                </Link>
                                <div className="ms-3">
                                    <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Bonus History Details")}</span>
                                </div>
                            </div>
                            <div className="tableContainer d-none d-md-block">
                                <Table responsive className="w-100 table-list mt-4 text-center position-relative">
                                    <thead>
                                        <tr>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Date & Time")}</th>
                                            <th className={`font-weight-600 font-16 text-black text-center px-1`}>{translate(props.lang, "Type Of Bonus")}</th>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount")} (USD)</th>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Remark")}</th>
                                        </tr>
                                    </thead>
                                    {loading ? (
                                        <tbody>
                                            <tr role="row">
                                                <td colSpan={4}>
                                                    <LoadingV3 theme='light' />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {result.length > 0 ?
                                                result.map((item, i) => (
                                                    <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                        <td className={`font-weight-500 font-16 text-center`}>{item.trxDate}</td>
                                                        <td className={`font-weight-500 font-16 text-center text-capitalize`}>{item.bonusType.toLowerCase().replaceAll('_', ' ')}</td>
                                                        <td className={`font-weight-500 font-16 text-center`}>{item.amount.toLocaleString('en-US', digitFormat)}</td>
                                                        <td className={`font-weight-500 font-16 text-center px-1 description lh-base`}>{item.remark.replaceAll('\n ', '\n')}</td>
                                                    </tr>
                                                )) :
                                                <tr className="py-5 text-center bg-transparent">
                                                    <td colSpan={4}>
                                                        <img src={require('../../../images/noData.svg').default} className="img-fluid pt-4 noDataImgSize" alt="no-data" />
                                                        <span className="d-block text-black mt-4">
                                                            {translate(props.lang, "Oops! No data found...")}
                                                        </span>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    )}
                                </Table>
                            </div>

                            <div className="d-block d-md-none tableContainerAccordion mt-3">
                                {loading ? (<LoadingV3 theme="dark" />) : (
                                    result.length > 0 ? (
                                        <Accordion preExpanded={[0]} className="pt-2 text-left">
                                            {result.map((item, i) => (
                                                <AccordionItem key={i} uuid={i} className={`${i === selectedAccordion ? "mb-4 accordion-item" : "mb-4 accordion-item-inactive"} ${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} onClick={() => setSelectedAccordion(i)}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton className="px-4 py-3">
                                                            <Row className="mx-0 px-0 justify-content-center align-items-center">
                                                                <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                                                                    <div>
                                                                        <span className={`font-weight-500 font-12 text-grey-1`}>{item.trxDate}</span>
                                                                    </div>
                                                                    <div className="d-flex justify-content-start align-items-center pt-2">
                                                                        <span className={`font-weight-600 font-18 text-center text-black`}>
                                                                            {item.amount.toLocaleString('en-US', digitFormat)}
                                                                            <span className={`font-weight-600 text-center text-black text-capitalize `}> {item.walletType}</span>
                                                                        </span>
                                                                    </div>
                                                                    <div className="pt-2">
                                                                        <span className={`font-weight-500 font-18 text-center text-capitalize text-black`}>
                                                                            {item.bonusType.toLowerCase().replaceAll('_', ' ')}
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={2} md={2} sm={2} xs={2} className="px-0">
                                                                    <div className="text-end">
                                                                        {
                                                                            selectedAccordion === i ? (<img src={require('../../../images/icon/up.svg').default} alt="up" />) : <img src={require('../../../images/icon/down.svg').default} alt="down" />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel className={`${selectedAccordion === i ? "d-block" : "d-none"}`}>
                                                        <div className="accordionContent px-4">
                                                            <div className="border-top py-3">
                                                                <Row className="justify-content-center align-items-start">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-2">
                                                                        <span className={`font-weight-500 font-14 text-center text-black-1 break-all-word`}>{translate(props.lang, "Remark")}</span>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-2">
                                                                        <span className={`font-weight-600 font-14 text-center text-black break-white-space overflow-wrap-anywhere lh-base`}>{item.remark.replaceAll('\n ', '\n')}</span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    ) : (
                                        <div className="py-5 text-center bg-transparent">
                                            <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                            <span className="d-block text-black mt-4">
                                                {translate(props.lang, "Oops! No data found...")}
                                            </span>
                                        </div>)
                                )}
                            </div>

                            <div className="pt-4">
                                {!loading && result.length > 0 ? (
                                    <div className="mt-3">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCount}
                                            onPageChange={changePage}
                                            forcePage={pageNumber - 1}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-black"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(BonusHistoryDetails);