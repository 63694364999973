import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import ReactPaginate from "react-paginate"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import moment from "moment";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import 'react-accessible-accordion/dist/fancy-example.css';
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import LoadingV3 from "../../components/Loading/LoadingV3";
import "../../../css/settings.scss";
import { digitFormat } from "../../../utils/function";

const trxList = [
    { trxDateTime: '27/09/2023  12:06:21', trxType: 'USDT Deposit', amountType: 'USDT', amount: 100, status: 'pending', remark: '-' },
    { trxDateTime: '27/09/2023  12:06:21', trxType: 'USDT Deposit', amountType: 'USDT', amount: 100, status: 'approved', remark: '-' },
    { trxDateTime: '27/09/2023  12:06:21', trxType: 'USDT Deposit', amountType: 'USDT', amount: 100, status: 'rejected', remark: '-' },
]

const TransactionHistory = (props) => {
    const dispatch = useDispatch();
    const productPerpage = window.innerWidth > 991 ? 10 : 5;
    const [pageCount, setPageCount] = useState(5);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedAccordion, setSelectedAccordion] = useState(0);
    const [result, setResult] = useState(trxList);
    const [trxDetail, setTrxDetail] = useState(null);

    const changePage = (event) => {
        setPageNumber(event.selected + 1);
    }

    useEffect(() => {
        getTransactionList();
        setSelectedAccordion(0);
    }, [pageNumber, props.auth.auth.token, props.lang]);

    const getTransactionList = () => {
    }


    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="whiteContainer py-4 px-4">
                            <div className="d-flex align-items-center w-100">
                                <Link to="/wallet/overview" className="cursor-pointer">
                                    <div className="d-flex align-items-center">
                                        <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                    </div>
                                </Link>
                                <div className="ms-3">
                                    <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Wallet Transaction History")}</span>
                                </div>
                            </div>

                            <div className="tableContainer d-none d-md-block">
                                <Table responsive className="w-100 table-list mt-4 text-center position-relative">
                                    <thead>
                                        <tr>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Date & Time")}</th>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Type")}</th>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount Type")}</th>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount")} (USD)</th>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Status")}</th>
                                            <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Remark")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loading ? (
                                            <tr>
                                                <td colSpan={6}>
                                                    <LoadingV3 theme='light' />
                                                </td>
                                            </tr>
                                        ) : (
                                            result.length > 0 ? (
                                                result.map((item, i) => (
                                                    <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                        <td className={`font-weight-400 font-16 text-center lh-base`}>{item.trxDateTime}</td>
                                                        <td className={`font-weight-400 font-16 text-center`}>{translate(props.lang, item.trxType.replaceAll('_', ' '))}</td>
                                                        <td className={`font-weight-400 font-16 text-center text-capitalize`}>{item.amountType.toLowerCase()}</td>
                                                        <td className={`font-weight-400 font-16 text-center`}>{Number(item.amount).toLocaleString('en-US', digitFormat)}</td>
                                                        <td className={`font-weight-400 font-16 text-center`}>
                                                        {item.status ? (
                                                                    <div className={`py-2 px-2 d-inline-block text-center status-box text-capitalize ${item.status.toUpperCase()}`}>
                                                                        <span className="font-weight-400 font-14 statustext">{translate(props.lang, item.status.toUpperCase())}</span>
                                                                    </div>
                                                                ) : '-'}
                                                        </td>
                                                        <td className={`font-weight-400 font-16 text-center`}>{item.remark}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={6}>
                                                        <div className="py-5 text-center bg-transparent">
                                                            <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                            <span className="d-block text-black mt-4">
                                                                {translate(props.lang, "Oops! No data found...")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="d-block d-md-none tableContainerAccordion mt-3">
                                {loading ? (<LoadingV3 theme="dark" />) : (
                                    result.length > 0 ? (
                                        <Accordion preExpanded={[0]} className="pt-2 text-left">
                                            {result.map((item, i) => (
                                                <AccordionItem key={i} uuid={i} className={`${i === selectedAccordion ? "mb-4 accordion-item" : "mb-4 accordion-item-inactive"} ${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} onClick={() => setSelectedAccordion(i)}>
                                                    <AccordionItemHeading>
                                                        <AccordionItemButton className="px-4 py-3">
                                                            <Row className="mx-0 px-0 justify-content-center align-items-center">
                                                                <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                                                                    <div>
                                                                        <span className={`font-weight-500 font-13 text-grey-1`}>{item.trxDateTime}</span>
                                                                    </div>
                                                                    <div className="d-flex justify-content-start align-items-center pt-2">
                                                                        <span className={`font-weight-600 font-26 text-center text-capitalize page-title-color`}>
                                                                            <span className={`font-weight-600 font-18 text-center text-black-1`}>{item.amount.toLocaleString('en-US', digitFormat)} {item.walletType}</span></span>
                                                                    </div>
                                                                    <div className="d-flex justify-content-start align-items-center pt-2">
                                                                        <div>
                                                                            <span className={`font-weight-600 font-18 text-center page-title-color`}>
                                                                                {item.trxType.replaceAll('_', ' ')}
                                                                            </span>
                                                                        </div>
                                                                        <div className="mx-2">
                                                                            {item.status ? (
                                                                                <div className={`py-2 px-2 d-inline-block text-center status-box text-capitalize ${item.status.toUpperCase()}`}>
                                                                                    <span className="font-weight-400 font-14 statustext">{translate(props.lang, item.status.toUpperCase())}</span>
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={2} md={2} sm={2} xs={2} className="px-0">
                                                                    <div className="text-end">
                                                                        {
                                                                            selectedAccordion === i ? (<img src={require('../../../images/icon/up.svg').default} alt="up" />) : <img src={require('../../../images/icon/down.svg').default} alt="down" />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </AccordionItemButton>
                                                    </AccordionItemHeading>
                                                    <AccordionItemPanel className={`${selectedAccordion === i ? "d-block" : "d-none"}`}>
                                                        <div className="accordionContent px-4">
                                                            <div className="border-top py-3">
                                                                <Row className="justify-content-center align-items-start">
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1">
                                                                        <span className={`font-weight-500 font-16 text-center text-black-1 break-all-word`}>{translate(props.lang, "Amount Type")}</span>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-3">
                                                                        <span className={`font-weight-600 font-16 text-center text-black break-all-word text-capitalize`}>{item.amountType ? item.amountType.toLowerCase() : '-'}</span>
                                                                    </Col>
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1">
                                                                        <span className={`font-weight-500 font-16 text-center text-black-1 break-all-word`}>{translate(props.lang, "TXID")}</span>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12} className="mb-3">
                                                                        <span className={`font-weight-600 font-16 text-center text-black break-all-word lh-base`}>{item.id ? item.id : '-'}</span>
                                                                    </Col>
                                                                    <Col lg={5} md={5} sm={12} xs={12} className="mb-1">
                                                                        <span className={`font-weight-500 font-16 text-center text-black-1`} >{translate(props.lang, "Remark")}</span>
                                                                    </Col>
                                                                    <Col lg={7} md={7} sm={12} xs={12}>
                                                                        <span className={`font-weight-600 font-16 text-center text-black break-all-word lh-base`}>{item.remark ? item.remark : '-'}</span>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </AccordionItemPanel>
                                                </AccordionItem>
                                            ))}

                                        </Accordion>
                                    ) : (
                                        <div className="py-5 text-center bg-transparent">
                                            <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                            <span className="d-block text-black mt-4">
                                                {translate(props.lang, "Oops! No data found...")}
                                            </span>
                                        </div>
                                    )
                                )}
                            </div>
                            <div className="pt-4">
                                {!loading && result.length > 0 ? (
                                    <div className="mt-3">
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCount}
                                            forcePage={pageNumber - 1}
                                            onPageChange={changePage}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-black"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(TransactionHistory);