import { connect, useDispatch } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import Select from "react-select";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { FaSpinner } from "react-icons/fa";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import imageCompression from 'browser-image-compression';
import customSelectStyles from "../SelectStyle";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import { logout } from "../../../store/actions/AuthActions";
import "../../../css/settings.scss";

const KYC = (props) => {
    const dispatch = useDispatch();
    let errorsObj = {
        name: '',
        gender: '',
        phoneNo: '',
        identityNo: '',
        address: '',
        country: '',
        front: '',
        back: '',
        holdingIC: '',
        dateOfBirth: '',
    };
    const [errors, setErrors] = useState(errorsObj);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [countryOption, setCountryOption] = useState([]);
    const [img, setImg] = useState({
        front: null,
        back: null,
        holdingIC: null
    });
    const [formData, setFormData] = useState({
        name: '',
        gender: '',
        phoneNo: '',
        identityNo: '',
        address: '',
        country: '',
        front: '',
        back: '',
        holdingIC: '',
        dateOfBirth: null,
    })
    const [kycInfo, setKycInfo] = useState({
        status: null,
        referral_code: '',
        joiningDate: '',
        remark: '',
    });
    const genderOption = [
        { value: "Male", label: translate(props.lang, 'Male') },
        { value: 'Female', label: translate(props.lang, 'Female') },
    ]

    useEffect(() => {
        getCountryList();
        // getKyc();
    }, [props.auth.auth.token]);

    const getKyc = () => {
        setLoadingData(true);
        fetch(api.kyc, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(async responseJson => {
                setLoadingData(false);
                if (responseJson.status === "ok") {
                    if (responseJson.data) {
                        setKycInfo({ ...responseJson.data })
                        let newFormData = { ...formData };
                        newFormData.name = responseJson.data.name;
                        newFormData.identityNo = responseJson.data.identityNo;
                        newFormData.country = responseJson.data.country;
                        newFormData.gender = responseJson.data.gender;
                        newFormData.address = responseJson.data.address;
                        newFormData.dateOfBirth = responseJson.data.dateOfBirth ? responseJson.data.dateOfBirth : '';
                        newFormData.phoneNo = responseJson.data.phoneNo;

                        const frontImgObj = await urlToObject(responseJson.data.frontIcPath);
                        const backImgObj = await urlToObject(responseJson.data.backIcPath);
                        const userWithIcImgObj = await urlToObject(responseJson.data.holdingIcPath);

                        newFormData.front = responseJson.data.frontIcPath ? frontImgObj : '';
                        newFormData.back = responseJson.data.backIcPath ? backImgObj : '';
                        newFormData.holdingIC = responseJson.data.holdingIcPath ? userWithIcImgObj : '';
                        setFormData({ ...newFormData });

                        const newImg = { ...img };
                        newImg.front = responseJson.data.frontIcPath;
                        newImg.back = responseJson.data.backIcPath;
                        newImg.holdingIC = responseJson.data.holdingIcPath;
                        setImg(newImg);
                    }
                } else if (responseJson.status === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    console.log('error', responseJson.message)
                }

            }).catch(error => {
                console.error("error", error);
            });
    }

    const getCountryList = () => {
       
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        var regPhone = /^[\d -]+$/;

        if (!formData.name) {
            errorMsg.name = 'This field is required.';
            check = false;
        }
        if (!formData.gender) {
            errorMsg.gender = 'This field is required.';
            check = false;
        }

        if (!formData.identityNo) {
            errorMsg.identityNo = 'This field is required.';
            check = false;
        }

        if (!formData.dateOfBirth) {
            errorMsg.dateOfBirth = 'This field is required.';
            check = false;
        }

        if (!formData.phoneNo) {
            errorMsg.phoneNo = 'This field is required.';
            check = false;
        } else {
            if (!regPhone.test(formData.phoneNo)) {
                errorMsg.phoneNo = 'Only Numbers are allowed in this field';
                check = false;
            }
        }

        if (!formData.country) {
            errorMsg.country = 'This field is required.';
            check = false;
        }

        if (!formData.address) {
            errorMsg.address = 'This field is required.';
            check = false;
        }

        if (!formData.front) {
            errorMsg.front = "This field is required.";
            check = false;
        } else {
            if (img.front !== formData.front) {
                if (!formData.front.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/i)) {
                    errorMsg.front = "Only allow format jpg, png or jpeg";
                    check = false;
                } else {
                    let size = (formData.front.size / 1024 / 1024).toFixed(2);
                    if (size > 2) {
                        errorMsg.front = "Image size cannot exceed 2mb";
                        check = false;
                    }
                }
            }
        }
        if (!formData.back) {
            errorMsg.back = "This field is required.";
            check = false;
        } else {
            if (img.back !== formData.back) {
                if (!formData.back.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/i)) {
                    errorMsg.back = "Only allow format jpg, png, jpeg, gif or svg";
                    check = false;
                } else {
                    let size = (formData.back.size / 1024 / 1024).toFixed(2);
                    if (size > 2) {
                        errorMsg.back = "Image size cannot exceed 2mb";
                        check = false;
                    }
                }
            }
        }
        if (!formData.holdingIC) {
            errorMsg.holdingIC = "This field is required.";
            check = false;
        } else {
            if (img.holdingIC !== formData.holdingIC) {
                if (!formData.holdingIC.name.match(/\.(jpg|jpeg|png|JPG|PNG|JPEG)$/i)) {
                    errorMsg.holdingIC = "Only allow format jpg, png or jpeg";
                    check = false;
                } else {
                    let size = (formData.holdingIC.size / 1024 / 1024).toFixed(2);
                    if (size > 2) {
                        errorMsg.holdingIC = "Image size cannot exceed 2mb";
                        check = false;
                    }
                }
            }
        }
        setErrors(errorMsg)
        if (check) {
            setLoading(true);
            const form = new FormData();
            form.append("name", formData.name);
            form.append("gender", formData.gender);
            form.append("phoneNo", formData.phoneNo.toString());
            form.append("identityNo", formData.identityNo);
            form.append("address", formData.address);
            form.append("country", formData.country);
            form.append("front", formData.front);
            form.append("back", formData.back);
            form.append("holdingIC", formData.holdingIC);
            form.append("dateOfBirth", formData.dateOfBirth)
            
        }
    }

    const uploadImage = async (e, type) => {
        if (e.target.files[0]) {
            let size = (e.target.files[0].size / 1024 / 1024).toFixed(2);
            if (size < 2) {
                const newFormData = { ...formData };
                const newImg = { ...img };
                newFormData[type] = e.target.files[0];
                newImg[type] = URL.createObjectURL(e.target.files[0]);
                setFormData(newFormData);
                setImg(newImg);
            } else {
                const options = {
                    maxSizeMB: 1,
                    useWebWorker: true,
                    maxWidthOrHeight: 1024,
                }
                try {
                    const compressedFile = await imageCompression(e.target.files[0], options);
                    var file = new File([compressedFile], compressedFile.name, { lastModified: Math.floor(Date.now() / 1000) });
                    const newFormData = { ...formData };
                    const newImg = { ...img };
                    newFormData[type] = file;
                    newImg[type] = URL.createObjectURL(file);
                    setFormData(newFormData);
                    setImg(newImg);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }

    const handleDateChange = (e, type) => {
        const newFormData = { ...formData };
        newFormData[type] = moment(e).format('YYYY-MM-DD');
        setFormData(newFormData);
    }

    const urlToObject = async (url) => {
        if(url){
            let filename = url.split("/").pop();
            const response = await fetch(url, {
                method: 'GET',
                headers: {}
            })
    
            const blob = await response.blob();
            const file = new File([blob], filename, { type: 'image/' + filename.split(".").pop() });
    
            return file;
        } else {
            return null;
        }
        
    }

    return (
        <>
            <div className='settings pb-5'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className={`whiteContainer mb-4 ${errors.country || errors.contact ? 'py-5' : 'pt-5 pb-4'}`}>
                            <Row className="align-items-center justify-content-center mx-0 px-0">
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.name ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Full Name")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="name"
                                                    placeholder={translate(props.lang, "Your Full Name")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.name}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED" ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.name)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-select px-md-4 px-3 mb-1 ${errors.gender ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Gender")} <span className='text-red'>*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={
                                                        genderOption.map((item, i) => ({ value: item.value, label: item.label }))
                                                    }
                                                    placeholder={translate(props.lang, "Select Gender")}
                                                    className="input-transparent w-100 text-capitalize "
                                                    styles={customSelectStyles}
                                                    value={
                                                        genderOption.filter(item => item.value == formData.gender).length > 0 ?
                                                            (genderOption.filter(item => item.value == formData.gender).map((item, i) => ({ value: item.value, label: item.label })))
                                                            : null
                                                    }
                                                    isSearchable={false}
                                                    name="gender"

                                                    isDisabled={kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED" ? true : false}
                                                    onChange={(e) => handleOptionSelected(e, 'gender')}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.gender)}</span>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.identityNo ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "IC / passport no.")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="identityNo"
                                                    placeholder={translate(props.lang, "Your IC / Passport No.")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.identityNo}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED" ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.identityNo)}</span>
                                    </div>
                                </Col>
                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2 mb-md-0">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.phoneNo ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Phone Number")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="phoneNo"
                                                    placeholder={translate(props.lang, "Your phone number")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.phoneNo}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED" ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.phoneNo)}</span>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.dateOfBirth ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Date of Birth")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        label=""
                                                        clearable
                                                        format="dd/MM/yyyy"
                                                        disableFuture
                                                        className="form-control input-transparent py-1 px-md-4 px-3 date text-black"
                                                        placeholder={translate(props.lang, "Your Date of Birth")}
                                                        value={formData.dateOfBirth ? new Date(formData.dateOfBirth) : null}
                                                        onChange={(date) => handleDateChange(date, 'dateOfBirth')}
                                                        disabled={kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED"}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.dateOfBirth)}</span>
                                    </div>
                                </Col>

                                <Col lg={6} md={6} sm={12} xs={12} className="mb-2 mb-md-0">
                                    <div className="text-start">
                                        <div className={`groupLabel-select px-md-4 px-3 mb-1 ${errors.country ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Country")} <span className='text-red'>*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={
                                                        countryOption.length > 0 ? countryOption.map((item, i) => ({ value: item.countryName, label: props.lang === 'EN' ? item.countryName.toLowerCase() : item.countryNameCn })) : [{ value: '', label: translate(props.lang, "No countries available") }]
                                                    }
                                                    placeholder={translate(props.lang, "Select Country")}
                                                    className="input-transparent w-100 text-capitalize px-0"
                                                    styles={customSelectStyles}
                                                    value={
                                                        countryOption.filter(item => item.countryName == formData.country).length > 0 ?
                                                            (countryOption.filter(item => item.countryName == formData.country).map((item, i) => ({ value: item.countryName, label: props.lang === 'EN' ? item.countryName.toLowerCase() : item.countryNameCn, key: i })))
                                                            : null
                                                    }
                                                    name="country"
                                                    onChange={(e) => handleOptionSelected(e, 'country')}
                                                    isDisabled={kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED" ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.country)}</span>
                                    </div>
                                </Col>

                                

                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="mt-2 text-start">
                                        <div className={`groupLabel-input py-1 mb-1 ${errors.address ? 'errorBorder' : ''}`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Address")} <span className='text-red'>*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type={'text'}
                                                    name="address"
                                                    placeholder={translate(props.lang, "Your address")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.address}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                    disabled={kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED" ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.address)}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className="whiteContainer mb-4">
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={4} md={4} sm={12} xs={12} className="px-1">
                                    <div className="d-flex justify-content-center justify-content-md-start w-100 py-4 border-right-box px-lg-5 px-3">
                                        <div className="text-center text-md-start">
                                            <span className="font-weight-400 font-13 line-height-18 text-black d-block text-capitalize">{translate(props.lang, "Joining Date")}</span>
                                            <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-2">{kycInfo ? kycInfo.joiningDate ? moment(kycInfo.joiningDate).format('YYYY-MM-DD HH:mm:ss') : "-" : "-"}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12} className="px-1">
                                    <div className="d-flex justify-content-center justify-content-md-start w-100 py-4 border-right-box px-lg-5 px-3">
                                        <div className="text-center text-md-start">
                                            <span className="font-weight-400 font-13 line-height-18 text-black d-block text-capitalize">{translate(props.lang, "Referral Code")}</span>
                                            <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-2">{kycInfo ? kycInfo.referralCode ? kycInfo.referralCode : '-' : "-"}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} md={4} sm={12} xs={12} className="px-1">
                                    <div className="d-flex justify-content-center justify-content-md-start w-100 py-4 px-lg-5 px-3">
                                        <div className="text-center text-md-start w-100">
                                            <span className="font-weight-400 font-13 line-height-18 text-black d-block text-capitalize">{translate(props.lang, "KYC Status")}</span>
                                            <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-2">
                                                <svg width="10" height="10" className="me-2" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="10" height="10" rx="5" fill={kycInfo.status === "APPROVED" ? '#00DA3C' : kycInfo.status === 'REVIEW' ? '#DAA621' : kycInfo.status === 'REJECTED' ? '#C10808' : '#6D6D6D'} />
                                                </svg>
                                                {!kycInfo.status ? translate(props.lang, "Not Verified") : translate(props.lang, kycInfo.status)}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={`whiteContainer mb-4 ${kycInfo && kycInfo.remark ? 'd-block' : 'd-none'}`}>
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="py-4 px-4">
                                        <div className="text-center text-md-start">
                                            <span className="font-weight-400 font-13 line-height-18 text-black text-capitalize">{translate(props.lang, "Remark:")}  </span>
                                            <span className="font-weight-600 font-18 line-height-18 text-black">{kycInfo && kycInfo.remark ? kycInfo.remark : "-"}</span>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <Row className="mx-0 px-0">
                            <Col lg={4} md={4} sm={12} xs={12} className="mb-4 px-1">
                                <div className={`whiteContainer h-100  ${errors.front ? 'redBorder' : ""}`}>
                                    <label htmlFor="front" className={`d-flex align-items-center justify-content-center h-100 w-100`}>
                                        {img.front ? (
                                            <div className="h-100 d-flex flex-column justify-content-between w-100 align-items-center">
                                                <div className="text-center pt-3 my-auto">
                                                    <img src={img.front} className="img-fluid doc-preview" alt="document" />
                                                </div>
                                                <div className="text-center mt-3 pb-3">
                                                    <span className="font-weight-600 font-18 text-black text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport front")}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="py-5">
                                                <div className="text-center">
                                                    <img src={require('../../../images/settings/icfront.svg').default} className="img-fluid" />
                                                </div>
                                                <div className="text-center mt-3">
                                                    <span className="font-weight-600 font-18 text-black text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport front")}</span>
                                                </div>
                                            </div>
                                        )}
                                    </label>
                                    {kycInfo ? !kycInfo.status || kycInfo.status === 'PENDING' || kycInfo.status === 'REJECTED' ? (
                                        <input type='file' id="front" className="d-none" onChange={(e) => uploadImage(e, "front")} />
                                    ) : null : <input type='file' id="front" className="d-none" onChange={(e) => uploadImage(e, "front")} />}
                                </div>
                                <div className="mx-3 mt-1">
                                    <span className="errorTextRed">{translate(props.lang, errors.front)}</span>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12} className="mb-4 px-1">
                                <div className={`whiteContainer h-100  ${errors.back ? 'redBorder' : ""}`}>
                                    <label htmlFor="back" className={`d-flex align-items-center justify-content-center h-100 w-100`}>
                                        {img.back ? (
                                            <div className="h-100 d-flex flex-column justify-content-between w-100 align-items-center">
                                                <div className="text-center pt-3 my-auto">
                                                    <img src={img.back} className="img-fluid doc-preview" alt="document" />
                                                </div>
                                                <div className="text-center mt-3 pb-3">
                                                    <span className="font-weight-600 font-18 text-black text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport Back")}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="py-5">
                                                <div className="text-center">
                                                    <img src={require('../../../images/settings/icBack.svg').default} className="img-fluid" />
                                                </div>
                                                <div className="text-center mt-3">
                                                    <span className="font-weight-600 font-18 text-black text-capitalize line-height-22">{translate(props.lang, "Upload IC/ Passport Back")}</span>
                                                </div>
                                            </div>
                                        )}
                                    </label>
                                    {kycInfo ? !kycInfo.status || kycInfo.status === 'PENDING' || kycInfo.status === 'REJECTED' ? (
                                        <input type='file' id="back" className="d-none" onChange={(e) => uploadImage(e, "back")} />
                                    ) : null : <input type='file' id="back" className="d-none" onChange={(e) => uploadImage(e, "back")} />}
                                </div>
                                <div className="mx-3 mt-1">
                                    <span className="errorTextRed">{translate(props.lang, errors.back)}</span>
                                </div>
                            </Col>
                            <Col lg={4} md={4} sm={12} xs={12} className="mb-4 px-1">
                                <div className={`whiteContainer h-100  ${errors.holdingIC ? 'redBorder' : ""}`}>
                                    <label htmlFor="holdingIC" className={`d-flex align-items-center justify-content-center h-100 w-100`}>
                                        {img.holdingIC ? (
                                            <div className="h-100 d-flex flex-column justify-content-between w-100 align-items-center">
                                                <div className="text-center pt-3 my-auto">
                                                    <img src={img.holdingIC} className="img-fluid doc-preview" alt="document" />
                                                </div>
                                                <div className="text-center mt-3 pb-3">
                                                    <span className="font-weight-600 font-18 text-black text-capitalize line-height-22">{translate(props.lang, "Upload Photo With Holding IC/Passport")}</span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="py-5">
                                                <div className="text-center">
                                                    <img src={require('../../../images/settings/holdIc.svg').default} className="img-fluid" />
                                                </div>
                                                <div className="text-center mt-3">
                                                    <span className="font-weight-600 font-18 text-black text-capitalize line-height-22">{translate(props.lang, "Upload Photo With Holding IC/Passport")}</span>
                                                </div>
                                            </div>
                                        )}
                                    </label>
                                    {kycInfo ?
                                        !kycInfo.status || kycInfo.status === 'PENDING' || kycInfo.status === 'REJECTED' ? (
                                            <input type='file' id="holdingIC" className="d-none" onChange={(e) => uploadImage(e, "holdingIC")} />
                                        ) : null : <input type='file' id="holdingIC" className="d-none" onChange={(e) => uploadImage(e, "holdingIC")} />}
                                </div>
                                <div className="mx-3 mt-1">
                                    <span className="errorTextRed">{translate(props.lang, errors.holdingIC)}</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {/* {loadingData ? null : (
                        kycInfo.status === "REVIEW" || kycInfo.status === "APPROVED" ? null : ( */}
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => submitForm()} disabled={loading} >
                                    <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Submit")}</span>
                                </button>
                            </Col>
                        {/* )
                    )} */}
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(KYC);