import $ from 'jquery';
import swal from 'sweetalert';
import { translate } from './translate';

const copyText = (value, lang) => {
    if (value !== '') {
        var $temp = $("<input>");
        $("body").append($temp);
        var dummy = $temp.val(value).select();
        dummy.focus();
        document.execCommand("copy");
        $temp.remove();
    }
    swal('', translate(lang, 'Copied'), "success");
}
const digitFormat = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
};


export { copyText, digitFormat };
