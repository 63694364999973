//only when user submit kyc and status is review or rejected only can edit
import { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import moment from 'moment';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { translate } from "../../../utils/translate";
import { logout } from "../../../store/actions/AuthActions";
import customSelectStyles from '../SelectStyle';
import "../../../css/profile.scss";
import { api } from "../../../utils/api";
import LoadingV3 from '../../components/Loading/LoadingV3';

const Profile = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [loadingProfile, setLoadingProfile] = useState(false);
    const [editable, setEditable] = useState(false);
    const [rank, setRank] = useState('');
    const [profile, setProfile] = useState(null);
    const [formData, setFormData] = useState({
        gender: '',
        dateOfBirth: '',
        phoneNumber: '',
        address: '',
    });
    const genderOption = [
        { value: "Male", label: translate(props.lang, 'Male') },
        { value: 'Female', label: translate(props.lang, 'Female') },
    ]

    useEffect(() => {
        getProfile();
    }, [props.auth.auth.token])

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const getProfile = () => {
        let responseJson = {
            "data": {
                "username": "demo",
                "userId": "7508474777",
                "fullName": "demo",
                "email": "demo@gmail.com",
                "gender": "Male",
                "phoneNumber": "0123456789",
                "dateOfBirth": "2023-10-10",
                "joiningDate": "2023-11-01T17:37:46.36204",
                "referral": "MU568ZVL",
                "referralCode": "KA8IW7SP",
                "address": "selangor msia",
                "country": null,
                "kycStatus": "APPROVED",
                "profilePicture": "",
                "mt5Username": null,
                "mt5Password": null,
                "bindCopyTradeStatus": null,
                "mt5AccountTwoUsername": null,
                "mt5AccountTwoPassword": null,
                "mt5AccountTwobindCopyTradeStatus": null
            }
        }
        dispatch({
            type: 'UPDATE_PROFILE',
            profile: responseJson.data
        });
        setProfile(responseJson.data);
        let newFormData = { ...formData };
        newFormData.gender = responseJson.data.gender;
        newFormData.dateOfBirth = responseJson.data.dateOfBirth;
        newFormData.phoneNumber = responseJson.data.phoneNumber;
        newFormData.address = responseJson.data.address;
        setFormData(newFormData);
    }

    const updateProfile = () => {
        setLoading(true);
        var data = new FormData();
        data.append("gender", formData.gender);
        data.append("phoneNo", formData.phoneNumber);
        data.append("address", formData.address);
        data.append("dateOfBirth", formData.dateOfBirth);
        fetch(api.changeProfile, {
            method: "POST",
            headers: new Headers({
                "Accept-Language": props.lang.toLowerCase(),
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
            body: data
        })
            .then(response => response.json())
            .then(responseJson => {
                setLoading(false);
                if (responseJson.status === "ok") {
                    setEditable(false);
                    swal(translate(props.lang, "Success"), translate(props.lang, "Profile successful updated"), "success");
                    getProfile();
                } else if (responseJson.status === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });

    }

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleDateChange = (e, type) => {
        const newFormData = { ...formData };
        newFormData[type] = moment(e).format('YYYY-MM-DD');
        setFormData(newFormData);
    }


    return (
        <>
            <div className='pb-5 profile'>
                <Row className='mx-0 px-0 align-items-md-stretch mt-3'>
                    <Col lg={4} md={12} sm={12} xs={12} className="mb-4">
                        <div className="card-qr-bg h-100 w-100 px-3 px-md-5 py-5 text-center d-flex align-items-start">
                            <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                                <div>
                                    <Row className='justify-content-center align-items-center h-100'>
                                      
                                    </Row>
                                    <div className="mt-5">
                                        <span className="font-weight-500 font-18 line-height-27 text-white break-all-word">{profile && profile.email ? profile.email : "-"}</span>
                                    </div>
                                    <div className="mt-4 px-3 textFieldBg py-3">
                                        <Row className='px-0 mx-0 text-start'>
                                            <Col lg={2} md={2} sm={2} xs={2}>
                                                <span className="font-weight-400 font-16 text-white">{translate(props.lang, "ID")}</span>
                                            </Col>
                                            <Col lg={10} md={10} sm={10} xs={10} className='text-end'>
                                                <span className="font-weight-400 font-16 text-white overflow-wrap-anywhere">{profile ? profile.userId : "-"}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} md={12} sm={12} xs={12} className="mb-4">
                        <div className="whiteContainer px-3 px-md-5 py-5 h-100">
                            {loadingProfile && !profile ? (<LoadingV3 theme='dark' />) : (
                                <Row className='mx-0 px-0 justify-content-start'>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize break-all-word">{translate(props.lang, "Full Name")}</span>
                                        <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1 break-all-word">{profile && profile.fullName ? profile.fullName : "-"}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize break-all-word">{translate(props.lang, "Email Address")}</span>
                                        <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1 break-all-word">{profile && profile.email ? profile.email : "-"}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        {editable ? (
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Gender")}</span>
                                                <div className={`groupLabel-select px-md-4 px-3 mb-1 mt-2 py-1`}>
                                                    <div className={`filter-input-group`}>
                                                        <Select
                                                            options={
                                                                genderOption.map((item, i) => ({ value: item.value, label: item.label }))
                                                            }
                                                            placeholder={translate(props.lang, "Select Gender")}
                                                            className="input-transparent w-100 text-capitalize px-0"
                                                            styles={customSelectStyles}
                                                            value={
                                                                formData.gender ? genderOption.filter(item => item.value === formData.gender).map((item) => ({ value: item.value, label: item.label })) : ''
                                                            }
                                                            name="gender"
                                                            onChange={(e) => handleOptionSelected(e, 'gender')}
                                                        />

                                                    </div>
                                                </div>
                                            </>
                                        ) :
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Gender")}</span>
                                                <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1">{profile && profile.gender ? genderOption.filter(item => item.value === profile.gender)[0].label : "-"}</span>
                                            </>
                                        }

                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        {editable ? (
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Phone Number")}</span>
                                                <div className={`groupLabel-input py-1 mb-1 mt-2`}>
                                                    <div className={`input-group d-flex justify-content-start align-items-center`}>
                                                        <Input
                                                            type={'text'}
                                                            name="phoneNumber"
                                                            placeholder={translate(props.lang, "Your phone number")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.phoneNumber}
                                                            autoComplete="off"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) :
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Phone Number")}</span>
                                                <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1">{profile && profile.phoneNumber ? profile.phoneNumber : "-"}</span>
                                            </>
                                        }
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        {editable ? (
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Date of Birth")}</span>
                                                <div className={`groupLabel-input py-1 mb-1 mt-2`}>
                                                    <div className={`filter-input-group d-flex justify-content-start align-items-center`}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <DatePicker
                                                                autoOk
                                                                label=""
                                                                clearable
                                                                format="yyyy-MM-dd"
                                                                disableFuture
                                                                className="form-control input-transparent py-1 px-md-4 px-3 date"
                                                                placeholder={translate(props.lang, "Your Date of Birth")}
                                                                value={formData.dateOfBirth ? moment(formData.dateOfBirth) : null}
                                                                onChange={(date) => handleDateChange(date, 'dateOfBirth')}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                </div>
                                            </>
                                        ) :
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Date of Birth")}</span>
                                                <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1">{profile && profile.dateOfBirth ? profile.dateOfBirth : "-"}</span>
                                            </>
                                        }


                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        {editable ? (
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Address")}</span>
                                                <div className={`groupLabel-input py-1 mb-1 mt-2`}>
                                                    <div className={`input-group`}>
                                                        <Input
                                                            type={'text'}
                                                            name="address"
                                                            placeholder={translate(props.lang, "Your address")}
                                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                                            value={formData.address}
                                                            autoComplete="off"
                                                            onChange={e => handleAddFormChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        ) :
                                            <>
                                                <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Address")}</span>
                                                <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1">{profile && profile.address ? profile.address : "-"}</span>
                                            </>
                                        }

                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Upline")}</span>
                                        <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1">{profile && profile.referral ? profile.referral : "-"}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "My Referral Code")}</span>
                                        <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1">{profile && profile.referralCode ? profile.referralCode : "-"}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Joining Date")}</span>
                                        <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1">{profile && profile.joiningDate ? moment(profile.joiningDate).format('DD/MM/YYYY, HH:mm:ss') : "-"}</span>
                                    </Col>

                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "Country")}</span>
                                        <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1 text-capitalize">{profile && profile.country ? profile.country.toLowerCase() : "-"}</span>
                                    </Col>
                                    <Col lg={6} md={6} sm={12} xs={12} className="mb-md-4 mb-3">
                                        <span className="font-weight-400 font-16 line-height-18 text-grey-8 d-block text-capitalize">{translate(props.lang, "KYC Status")}</span>
                                        <span className="font-weight-600 font-18 line-height-18 text-black d-block mt-1 text-capitalize">{profile && profile.kycStatus ? translate(props.lang, profile.kycStatus).toLowerCase() : "-"}</span>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12}>
                                        {/* {profile && profile.kycStatus ? (
                                            profile.kycStatus == 'REVIEW' || profile.kycStatus == 'REJECTED' ? ( */}
                                                <Row className='align-items-center justify-content-end mx-0 px-0 pt-4 pt-md-0'>
                                                    <Col lg={6} md={6} sm={6} xs={6} className='px-0 px-md-0'>
                                                        {editable ? (
                                                            <div className='pe-1'>
                                                                <button className="cancel-btn py-2 text-center w-100" onClick={() => setEditable(false)}>
                                                                    <span className="font-weight-600 font-20 line-height-30 text-white">{translate(props.lang, "Cancel")}</span>
                                                                </button>

                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                    {/* {profile && profile.kycStatus ? (
                                                        profile.kycStatus == 'REVIEW' || profile.kycStatus == 'REJECTED' ? ( */}
                                                            <Col lg={6} md={6} sm={6} xs={6} className='px-0 px-md-0'>
                                                                <div>
                                                                    {!editable ? (
                                                                        <button className="primary-btn py-2 text-center w-100" onClick={() => setEditable(true)} >
                                                                            <span className={`font-weight-600 font-20 line-height-30 text-white`}>{translate(props.lang, "Edit")}</span>
                                                                        </button>
                                                                    ) :
                                                                        <button className="ms-1 primary-btn py-2 text-center w-100" onClick={() => updateProfile()} disabled={loading}>
                                                                            <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Update")}</span>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        {/* ) : null
                                                    ) : null} */}
                                                </Row>
                                            {/* ) : null) : null} */}
                                    </Col>
                                    
                                </Row>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Profile);