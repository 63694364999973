import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/settings.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import customSelectStyles from "../SelectStyle";

const Withdrawal = (props) => {
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [receipt, setReceipt] = useState({
        withdrawType: 'CRYPTO_WITHDRAW',
        currency: '',
        walletAddress: '',
        chain: '',
        amount: '',
        receiveAmount: '',
        status: '',
        bankCountry: '',
        bankName: '',
        accHolderName: '',
        accNumber: '',
        bankSwiftCode: '',
    });

    const [loadingOtp, setLoadingOtp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [withdrawType, setWithdrawType] = useState('');
    const timerRef = useRef(null);
    const [withdrawInfo, setWithdrawInfo] = useState({
        bonusBalance: 0,
        mt5Balance: 0,
        walletType: [],
        bankInfo: [],
        chainType: [],
        bankInfoChainType: []
    });
    const [receiveAmountInfo, setReceiveAmountInfo] = useState('')
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        amount: '',
        walletType: 'USD',
        withdrawId: '',
        password2: '',
        walletAddress: '',
        emailCode: ''
    });
    let errorsObj = { amount: '', withdrawId: '', emailCode: '', password2: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [countdownSecond, setCountdownSecond] = useState(0);

    useEffect(() => {
        getWalletWithdrawInfo();
        return () => { };
    }, [props.auth.auth.token]);

    useEffect(() => {
        if (countdownSecond > 0) {
            setTimeout(() => {
                setCountdownSecond(countdownSecond - 1);
            }, 1000);
        }
        return () => { };
    }, [countdownSecond]);

    const getWalletWithdrawInfo = () => {
    }

    const requestCode = () => {
        setCountdownSecond(60);
        swal(translate(props.lang, "Success"), translate(props.lang, "Verification code has sent to").replace('$email', props.auth.profile.email), "success");
      
    }

    const handleOptionSelect = async (e, type) => {
        const { value } = e;
        setWithdrawType(e.type);
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
        if (newFormData.amount > 0) {
            calculateWithdrawal();
        }
    }

    const calculateWithdrawal = () => {
        if (formData.withdrawId && formData.amount > 0) {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(() => {
                fetch(api.calculateWithdrawal, {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        "Accept-Language": props.lang.toLowerCase(),
                        "Authorization": "Bearer " + props.auth.auth.id_token
                    }),
                    body: JSON.stringify(formData)
                })
                    .then(response => response.json())
                    .then(responseJson => {
                        setLoading(false);
                        if (responseJson.status === "ok") {
                            setReceiveAmountInfo(responseJson.data)
                        } else {
                            swal(translate(props.lang, "Error"), responseJson.message, "error");
                        }
                    }).catch(error => {
                        console.error("error", error);
                    });
            }, 1000);
        }
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorsObj };
        var onlyNumber = /^\d+(\.\d+)?$/;
        if (!formData.amount) {
            errorMsg.amount = "This field is required.";
            check = false
        } else {
            if (!onlyNumber.test(formData.amount)) {
                errorMsg.amount = 'Only Numbers are allowed in this field';
                check = false;
            } else {
                if (formData.amount <= 0) {
                    errorMsg.amount = "Amount must greater than 0.";
                    check = false
                } else if (formData.amount > Number(withdrawInfo.bonusBalance)) {
                    errorMsg.amount = "Insufficient balance";
                    check = false
                }
            }
        }
        if (!formData.emailCode) {
            errorMsg.emailCode = "This field is required.";
            check = false
        }
        if (!formData.withdrawId) {
            errorMsg.withdrawId = "This field is required.";
            check = false
        }
        if (!formData.password2) {
            errorMsg.password2 = "This field is required.";
            check = false
        }
        setErrors(errorMsg);
        if (check) {
            setLoading(true);
            let newFormData = { ...formData };

            if (withdrawType === "FIAT_WITHDRAW") {
                newFormData.exchangeRate = receiveAmountInfo.exchangeRate;
            }

            fetch(api.walletWithdrawalSubmit, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    "Accept-Language": props.lang.toLowerCase(),
                    "Authorization": "Bearer " + props.auth.auth.id_token
                }),
                body: JSON.stringify(newFormData)
            })
                .then(response => response.json())
                .then(responseJson => {
                    setLoading(false);
                    if (responseJson.status === "ok") {
                        setReceiveAmountInfo('');
                        swal(translate(props.lang, "Success"), responseJson.message, "success");
                        setFormData({ ...errorsObj })
                        getWalletWithdrawInfo();
                        history.push('/wallet/transaction-history');
                    } else if (responseJson.status === 401) {
                        dispatch(logout(props.history, 'login'));
                    } else {
                        swal(translate(props.lang, "Error"), responseJson.message, "error");
                    }
                }).catch(error => {
                    console.error("error", error);
                });
        }
    }

    const getOptionLabel = (option) => (
        option.value ? (
            <div>
                <img src={option.type == 'CRYPTO_WITHDRAW' ? require('../../../images/wallet/cryptowithdraw.svg').default : require('../../../images/wallet/fiatwithdraw.svg').default} width="25" height="25" className="mb-1" />
                <span className="ms-2 mt-2 text-capitalize">{option.label}</span>
            </div>

        ) : (
            <div className="text-center">
                <span className="mt-2 text-capitalize">{option.label}</span>
            </div>
        )

    );


    return (
        <>
            <div className='pb-5 wallet'>
                <div className="whiteContainer px-md-5 px-2 py-5 my-3">
                    <Row className="justify-content-center align-items-center mx-0 px-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={2} md={2} xs={12} sm={12} className="mb-3 px-0 text-center text-md-start">
                                    <img src={require('../../../images/wallet/balanceIcon.svg').default} className="img-fluid" alt="icon-wallet" />
                                </Col>
                                <Col lg={10} md={10} xs={12} sm={12} className="mb-3 px-2">
                                    <Row className="px-0 mx-0 align-items-center">
                                        <Col lg={3} md={3} sm={12} xs={12} className="mb-3 mb-md-0">
                                            <div className="text-center text-md-start">
                                                <span className="font-20 font-weight-400 text-grey-8">{translate(props.lang, "Withdrawal From Wallet")}</span>
                                            </div>
                                            <div className="mt-2 text-center text-md-start">
                                                <span className="font-20 text-black font-weight-600">{translate(props.lang, "Balance Available")}</span>
                                            </div>
                                        </Col>
                                        <Col lg={9} md={9} sm={12} xs={12}>
                                            <div className="blackFieldBg d-flex w-100 justify-content-between px-3 px-lg-4 py-3">
                                                <div>
                                                    <span className="font-24 text-white font-weight-500 line-height-18 break-all-word break-white-space">USD</span>
                                                </div>
                                                <div>
                                                    <span className="font-24 text-white font-weight-500 line-height-18 break-all-word break-white-space">{Number(withdrawInfo.bonusBalance).toLocaleString('en-US', digitFormat)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="justify-content-center align-items-center mx-0 px-0">
                        <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                            <div className="text-start">
                                <div className={`groupLabel-select px-md-4 px-3 mb-1  ${errors.withdrawId ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Account withdrawal type")} <span className="errorTextRed">*</span></span>
                                    <div className={`filter-input-group`}>
                                        <Select
                                            options={withdrawInfo.bankInfo.length > 0 ? withdrawInfo.bankInfo.map((item, i) => ({ value: item.id, label: item.remark, type: item.withdrawType, key: i })) : [{ value: '', label: translate(props.lang, "No option available") }]}
                                            placeholder={translate(props.lang, "Select withdrawal type")}
                                            className="input-transparent w-100 text-capitalize px-0"
                                            styles={customSelectStyles}
                                            name="withdrawId"
                                            value={formData.withdrawId ? withdrawInfo.bankInfo.filter(item => item.id === formData.withdrawId).map((item, i) => ({ value: item.id, label: item.remark, type: item.withdrawType, key: i })) : ''}
                                            onChange={(e) => handleOptionSelect(e, 'withdrawId')}
                                            getOptionLabel={getOptionLabel}
                                        />
                                    </div>
                                </div>
                                <div className="ms-4">
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.withdrawId)}</span>
                                </div>
                            </div>
                        </Col>

                        <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.amount ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Withdraw Amount")} <span className="errorTextRed">*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type='number'
                                            step={0.01}
                                            onWheel={event => event.currentTarget.blur()}
                                            name="amount"
                                            placeholder={translate(props.lang, "Your Withdraw Amount")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.amount}
                                            onKeyUp={(e) => calculateWithdrawal()}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <div className="ms-4">
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.amount)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={12} sm={12} className="mb-3">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.password2 ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Transaction Password")} <span className="errorTextRed">*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            name="password2"
                                            placeholder={translate(props.lang, "Your Transaction Password")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.password2}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? (
                                                <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                            ) : (
                                                <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-4">
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.password2)}</span>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6} md={6} xs={12} sm={12} className="mb-3">
                            <div className="text-start">
                                <div className={`groupLabel-input px-md-4 px-3 py-1 mb-1 ${errors.emailCode ? 'errorBorder' : ''}`}>
                                    <div className='d-flex justify-content-between align-items-center w-100'>
                                        <div className="w-75">
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Email Verification Code")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group `}>
                                                <Input
                                                    type="text"
                                                    name="emailCode"
                                                    placeholder={translate(props.lang, "Your Email Verification Code")}
                                                    className="form-control input-transparent py-1"
                                                    value={formData.emailCode}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            {countdownSecond === 0 ? (
                                                <button className="requestCodeBtn px-lg-3 px-2 py-3 cursor-pointer" disabled={loadingOtp} onClick={() => requestCode()}>
                                                    <span className={`font-weight-600 font-16 text-white text-nowrap`}>{loadingOtp ? (<FaSpinner className="fa-spin" />) : translate(props.lang, "Send")}</span>
                                                </button>
                                            ) : (
                                                <button className="requestCodeBtn px-lg-3 px-2 py-3 cursor-pointer" disabled>
                                                    <span className={`font-weight-600 font-16 text-white text-nowrap`}>{countdownSecond}</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-4">
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.emailCode)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                            {withdrawType === "CRYPTO_WITHDRAW" ? (
                                <div className="px-4 py-3 card-custom-bg">
                                    <span className="font-13 text-black font-weight-500">{translate(props.lang, "Receive Amount")}</span>
                                    <div className="mt-3">
                                        <span className="font-30 text-black font-weight-600">{receiveAmountInfo ? receiveAmountInfo.cryptoReceiveAmount : 0} </span><span className="font-13 page-title-color font-weight-500"></span>
                                    </div>
                                    <div className="mt-3">
                                        <span className="font-13 text-black font-weight-400">{translate(props.lang, "Network Fee:")}{receiveAmountInfo ? receiveAmountInfo.cryptoNetworkFee : 0}</span>
                                    </div>
                                </div>
                            ) : withdrawType === "FIAT_WITHDRAW" ? (
                                <div className="px-4 py-3 card-custom-bg">
                                    <span className="font-13 text-black font-weight-500">{translate(props.lang, "Receive Amount")}</span>
                                    <div className="mt-3">
                                        <span className="font-30 text-black font-weight-600">{receiveAmountInfo ? receiveAmountInfo.fiatReceiveAmount : 0} </span>
                                    </div>
                                    <div className="mt-3">
                                        <span className="font-13 text-black font-weight-400">{translate(props.lang, "Exchange Rate:")}{receiveAmountInfo ? receiveAmountInfo.fiatExchangeRate : 0}</span>
                                    </div>
                                    <div className="mt-3">
                                        <span className="font-13 text-black font-weight-400">{translate(props.lang, "Charge Fee:")}{receiveAmountInfo ? receiveAmountInfo.fiatWithdrawFee : 0}</span>
                                    </div>
                                </div>
                            ) : null
                            }
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12}>
                            <button className="primary-btn px-4 py-2 text-center mx-auto w-100" disabled={loading} onClick={() => submitForm()}>
                                <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Submit")}</span>
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal className="fade bgImgModal" show={successModal} centered>
                <Modal.Body className="border-0 px-4 py-5">
                    <Row className="justify-content-center align-items-center mx-0 px-0">
                        <Col lg={11} md={11} sm={12} xs={12}>
                            <div className="text-center mb-4">
                                <img src={require('../../../images/dashboard/transferSuccess.svg').default} className='img-fluid' alt="success" />
                            </div>
                            <div className="text-center mb-3">
                                <span className="font-weight-600 font-30 text-grey-9 line-height-24 d-block mt-3 text-capitalize">{translate(props.lang, "Withdrawal Request Submitted")}</span>
                            </div>

                            <Row className="mx-0 px-0 justify-content-between mb-3">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                    <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Withdraw Type")}</span>
                                </Col>
                                <Col className="text-end">
                                    <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word text-capitalize">{translate(props.lang, receipt.withdrawType)}</span>
                                </Col>
                            </Row>
                            {receipt.withdrawType === 'CRYPTO_WITHDRAW' ? (
                                <>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Coin")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.currency}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Network")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.chain}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Wallet Address")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.walletAddress}</span>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Currency")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.currency}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Bank Country")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.bankCountry}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Bank Name")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.bankName}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Bank Account Holder Name")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.accHolderName}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Bank Account Number")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.accNumber}</span>
                                        </Col>
                                    </Row>
                                    <Row className="mx-0 px-0 justify-content-between mb-3">
                                        <Col lg={5} md={5} sm={5} xs={5}>
                                            <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Bank Swift Code")}</span>
                                        </Col>
                                        <Col className="text-end">
                                            <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.bankSwiftCode}</span>
                                        </Col>
                                    </Row>
                                </>
                            )}

                            <Row className="mx-0 px-0 justify-content-between mb-3">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                    <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Withdraw Amount")}</span>
                                </Col>
                                <Col className="text-end">
                                    <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.amount}</span>
                                </Col>
                            </Row>
                            <Row className="mx-0 px-0 justify-content-between mb-3">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                    <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Receive Amount")}</span>
                                </Col>
                                <Col className="text-end">
                                    <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.receiveAmount}</span>
                                </Col>
                            </Row>
                            <Row className="mx-0 px-0 justify-content-between mb-3">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                    <span className="font-weight-400 font-18 text-grey-9 line-height-24">{translate(props.lang, "Status")}</span>
                                </Col>
                                <Col className="text-end">
                                    <span className="font-weight-600 font-20 text-grey-9 line-height-24 text-grey-1 break-all-word">{receipt.status}</span>
                                </Col>
                            </Row>

                            <div className="mt-5">
                                <button className="primary-btn px-4 py-2 text-center mx-auto w-100" disabled={loading} onClick={() => history.push('/wallet/transaction-history')}>
                                    <span className={`font-weight-600 font-20 line-height-30 text-white`}>{translate(props.lang, "Close")}</span>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Withdrawal);