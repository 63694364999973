/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link, useLocation, withRouter } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { translate } from "../../../utils/translate";
import "../../../css/sidebar.scss";
import { logout } from "../../../store/actions/AuthActions";
import SidebarMenu from "../../../utils/menu";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = (props) => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);
  const [path, setPath] = useState("/");
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var navBackdrop = document.querySelector(".navigation-backdrop-control");
    var aaa = document.querySelector("#main-wrapper");
    btn.addEventListener("click", () => toggleMenuFunc());
    navBackdrop.addEventListener("click", () => toggleMenuFunc(true));
  }, []);

  const toggleMenuFunc = (forceClose = false) => {
    var body = document.querySelector("body");
    var aaa = document.querySelector("#main-wrapper");
    var aaabackdrop = document.querySelector(".navigation-backdrop-control");
    if (forceClose) {
      aaabackdrop.classList.add('d-none');
      aaa.classList.remove("menu-toggle");
      body.classList.remove("fixedPosition");
      return;
    }
    if (aaa.classList.contains("menu-toggle")) {
      aaabackdrop.classList.add('d-none');
      aaa.classList.remove("menu-toggle");
      body.classList.remove("fixedPosition");
      return;
    } else {
      aaabackdrop.classList.remove('d-none');
      aaa.classList.add("menu-toggle");
      body.classList.add("fixedPosition");
      return
    }
  }

  let scrollPosition = useScrollPosition();
  /// Path
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const closeMenu = (hasChildren) => {
    if (!hasChildren) {
      if (document.querySelector('body').getAttribute("data-sidebar-style") === 'overlay') {
        document.querySelector(".nav-control").click();
      }
    }
  }

  return (
    <div
      className={`deznav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >

      <PerfectScrollbar className="deznav-scroll">
        <div className="mobileLogo mt-5 mx-auto">
          <img src={require('../../../images/logo/logo-sidebar.png').default} className="img-fluid" alt="logo" />
        </div>
        <MM className="metismenu" id="menu">
          {SidebarMenu.map((parent, i) => (
              <li key={i} className={`${parent.display.includes('mobile') ? 'd-block d-lg-none d-md-none' : ''} mx-4 px-0 mb-2 w-75 mx-auto ${parent.allPath.includes(path) ? "mm-active mm-parent-active" : "main-menu"}`}>
                <Link className={`ai-icon py-3 w-100 px-2`} to={parent.pathName === "#" ? "#" : parent.pathName} onClick={() => closeMenu(parent.children.length > 0 ? true : false)}>
                  <div className="d-flex align-items-center justify-content-center">
                    <img src={parent.inactiveImg} className="icon-inactive" alt={parent.menu} />
                    <img src={parent.activeImg} className="icon-active" alt={parent.menu} />
                  </div>
                  <span className={`nav-text parent font-weight-600 font-16 ms-2 text-capitalize`}>
                    {translate(props.lang, parent.menu)}
                  </span>
                </Link>
                {parent.children.length > 0 ? (
                  <ul className={`submenu ${parent.allPath.includes(path) ? "mm-show" : "mm-collapse"}`}>
                    {parent.children.map((child, key) => (
                      <li key={key} className={`${child.allPath.includes(path) ? "menu-active" : "menu-inactive"}`} onClick={() => closeMenu(false)}>
                        <Link to={child.pathName}>
                          <span className={`font-weight-400 font-16 text-capitalize`}>{translate(props.lang, child.menu)}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            ))
          }
        </MM>
      </PerfectScrollbar>
    </div>
  );
}

const mapStateToProps = state => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
}
export default withRouter(connect(mapStateToProps)(SideBar));