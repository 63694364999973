const EN = {
    "Welcome Back": "Welcome Back",
    "Don’t have an account yet?": "Don’t have an account yet?",
    "Sign up now": "Sign up now",
    "Email Address": "Email Address",
    "Your Email Address": "Your Email Address",
    "Password": "Password",
    "Your Password": "Your Password",
    "Remember me": "Remember me",
    "Forgot Password?": "Forgot Password?",
    "Login": "Login",
    "This field is required.": "This field is required.",
    "Forgot Password": 'Forgot Password',
    "Already have an account?": 'Already have an account?',
    "Back To Login": 'Back To Login',
    "Verification Code": 'Verification Code',
    "Your Verification Code": "Your Verification Code",
    "Send": "Send",
    "Confirm Password": "Confirm Password",
    "Confirm Your Password": "Confirm Your Password",
    "Submit": "Submit",
    "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.": "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.",
    "Invalid Email Format": "Invalid Email Format",
    "Password must at least 6 characters": "Password must at least 6 characters",
    "Confirm password need to same with password": "Confirm password need to same with password",
    "Verification code has sent to": "A verification code has been sent to your $email mailbox. Please check your mailbox or junk mailbox",
    "Create Personal Account": "Create Personal Account",
    "Login now": "Login now",
    "Full Name": "Full Name",
    "Your Full Name": "Your Full Name",
    "Country": "Country",
    "Select Country": "Select Country",
    "No countries available": "No countries available",
    "Transaction Password" : "Transaction Password",
    "Your Transaction Password" : "Your Transaction Password",
    "Referral Code (optional)": "Referral Code (optional)",
    "Create Account Now": "Create Account Now",
    "Register Successful": "Register Successful",
    "Success": "Success",
    "Error": "Error",
    "Your Referral Code": "Your Referral Code" ,
    "Dashboard": "Dashboard",
    "Investment": "Investment",
    "Wallet": "Wallet",
    "Wallet Overview": "Overview",
    "Deposit": "Deposit",
    "Withdrawal": "Withdrawal",
    "Bank Info": "Bank Info",
    "Transaction History": "Transaction History",
    "Bonus History Details": "Bonus History Details",
    "Team": "Team",
    "Team Overview": "Team Overview",
    "Team Investment": "Team Investment",
    "Performance": "Performance",
    "Referral": "Referral",
    "Profile": "Profile",
    "Settings": "Settings",
    "KYC": "KYC",
    "Security": "Security",
    "Wallet Withdrawal": "Wallet Withdrawal",
    "Change Password": 'Change Password',
    "Forget Transaction Password": 'Forgot Transaction Password',
    "Notification": "Notification",
    "Hi,": "Hi,",
    "Stop Trading": 'Stop Trading',
    "Attention": "Attention",
    "Are you sure you want to stop trading?": "Are you sure you want to stop trading?",
    "Yes, I am sure!": "Yes, I am sure!",
    "Cancel": "Cancel",
    "New password must at least 6 characters": 'New password must at least 6 characters',
    "Confirm password need to same with new password": "Confirm password need to same with new password",
    "Password update successful": 'Password update successful',
    'Back': "Back",
    "Old Password": "Old Password",
    "New Password": "New Password",
    "Your Old Password": "Your Old Password",
    "Your New Password": "Your New Password",
    "Confirm New Password": "Confirm New Password",
    "Confirm Your New Password": "Your New Password",
    "Update": "Update",
    "New Transaction Password": "New Transaction Password",
    "Your new transaction password": 'Your New Transaction Password',
    "Confirm New Transaction Password": 'Confirm New Transaction Password',
    "Confirm your new confirm transaction password": "Your Confirm New Transaction Password",
    "Transaction password update successful": 'Transaction password update successful',
    "Transaction password must at least 6 characters": 'Transaction password must at least 6 characters',
    "Logout": "Logout",
    "Referral Code": "Referral Code",
    "Referral Link": "Referral Link",
    "Close": "Close",
    "Copied": "Copied",
    "Oops! No data found...": "Oops! No data found...",
    "Loading Data": "Loading Data",

    "Male" : "Male",
    "Female" : "Female",
    "Not Verified": "Not Verified",
    "Only Numbers are allowed in this field": 'Only Numbers are allowed in this field',
    "Image size cannot exceed 2mb": 'Image size cannot exceed 2mb',
    "Only allow format jpg, png or jpeg": "Only allow format jpg, png or jpeg",
    "Gender": "Gender",
    "Select Gender": "Select Gender",
    "IC / passport no.": "Identity card / passport no.",
    "Your IC / Passport No.": "Your Identity card / Passport No.",
    "Date of Birth": 'Date of Birth',
    "Your Date of Birth": 'Your Date of Birth',
    "Phone Number": 'Phone Number',
    "Your phone number": 'Your phone number',
    "Address": "Address",
    "Your address": 'Your address',
    "Joining Date": 'Joining Date',
    "KYC Status": 'KYC Status',
    "Remark": "Remark",
    "Upload IC/ Passport front": "Upload IC/ Passport front",
    "Upload IC/ Passport Back": "Upload IC/ Passport Back",
    "Upload Photo With Holding IC/Passport": "Upload Photo With Holding IC/Passport",
    "APPROVED": "APPROVED",
    "REVIEW": "REVIEW",
    "REJECTED": "REJECTED",
    "PENDING": "PENDING",
    "Congratulations, your account has been successfully created.": "Congratulations, your account has been successfully created.",
    "Continue": 'Continue',
    "Profile successful updated": "Profile successful updated",
    "ID": "ID",
    "Upline": 'Upline',
    "My Referral Code": "My Referral Code",
    "Edit" : "Edit",
    "share my QR code": 'share my QR code',
    "My Rank": "My Rank",
    "Active Group Member": "Active Group Member",
    "Total Group Member": "Total Group Member",
    "Direct Referral Members": "Direct Referral Members",
    "Personal Sales": "Personal Sales",
    "Group Sales": "Group Sales",
    "Self Deposit": "Self Deposit",
    "Ranking": "Ranking",
    "Wallet Balance:": "Wallet Balance:",
    "Total Member": "Total Member",
    "Total Active Member": "Total Active Member",
    "Total Sales": "Total Sales",
    "Personal Deposit": "Personal Deposit",
    "Type Of Bonus": "Type Of Bonus",
    "Amount": 'Amount',
    "Date & Time": "Date & Time",
    "View More": "View More",
    "Wallet Transaction History": "Wallet Transaction History",
    "Description": "Description",
    "Type": "Type",
    "Amount Type": "Type",
    "Transaction Hash" : "Transaction Hash",
    "TXID": 'TXID',
    "Status": "Status",
    "MT5 Balance": "MT5 Balance",
    "Crypto Deposit": 'Crypto Deposit',
    "Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.": 'Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.',
    "Coin": "Coin",
    "Network": 'Network',
    "Select Network": "Select Network",
    'Deposit Address': "Deposit Address",
    "Wallet Address": "Wallet Address",
    "Minimum Deposit": "Minimum Deposit",
    "Please Select Network": "Please Select Network",
    "Total Invested": "Total Invested",
    "Total Profit/Loss": "Total Profit/Loss",
    "Investment Details": "Investment Details",
    "Order ID": "Order ID",
    "Price": "Price",
    "Trading Pair": "Trading Pair",
    "Profit/Loss %": "Profit/Loss",
    "Lot Size": "Lot Size",
    "Price Enter": "Enter Price",
    "Price Close": "Close Price",
    "Announcement": "Announcement",
    "No Announcement For Now": "No Announcement For Now",
    "Group Sales Performance": "Group Sales Performance",
    "Sales": "Sales",
    "Read More": "Read More",
    "My MT5 Info": "My MT5 Info",
    "Wallet Total Balance": "Wallet",
    "Trading Profit": "Trading Profit",
    "Total Investment": "MT5 Balance",
    "Total Bonus": "Total Bonus",
    "My QR Referral Code": "My QR Referral Code",
    "Transfer": "Transfer",
    "From": "From",
    "To": "To",
    "Transfer Amount": "Transfer Amount",
    "Balance Available:": "Balance Available:",
    "Your Transfer Amount": 'Your Transfer Amount',
    "Successful": "Successful",
    "Unsuccessful": "Unsuccessful",
    "Insufficient balance": "Insufficient balance",
    "Add New Withdrawal Method" : "Add New Withdrawal Method",
    "Withdrawal Method Available" : "Withdrawal Method Available",
    "Invalid email or password": 'Invalid email or password',
    "Fiat Withdrawal": 'Fiat Withdrawal',
    "Crypto Withdrawal": "Crypto Withdrawal",
    "Bank Country": "Bank Country",
    "Bank Name": "Bank Name",
    "Bank Account Holder Name": "Bank Account Holder Name",
    "Bank Account Number": "Bank Account Number",
    "Bank Address": "Bank Address",
    "Bank Swift Code": "Bank Swift Code",
    "Select Your Bank country": "Select Your Bank country",
    "Select Your Bank Name": "Select Your Bank Name",
    "Your Bank Account Holder Name": "Your Bank Account Holder Name",
    "Your Bank Account Number": "Your Bank Account Number",
    "Your Bank Address": "Your Bank Address",
    "Your Bank Swift Code": "Your Bank Swift Code",
    "Your Bank Name": "Your Bank Name",
    "Your remark": "Your Remark",
    "Your Wallet Address": "Your Wallet Address",
    "Select Your Network" : "Select Your Network",
    "Save": 'Save',
    "Select Bank Name": 'Select Bank Name',
    "No network available": 'No network available',
    "Withdrawal From Wallet": "Withdrawal From Wallet",
    "Email Verification Code": 'Email Verification Code',
    "Your Email Verification Code": "Your Email Verification Code",
    "Withdraw Amount ": "Withdraw Amount ",
    "Your Withdraw Amount": "Your Withdraw Amount",
    "Select Type": "Select Type",
    "No option available": 'No option available',
    "Select bank transfer": 'Select bank transfer',
    "Receive Amount" : "Receive Amount",
    "Network Fee:" : "Network Fee : ",
    "Exchange Rate:" : "Exchange Rate : ",
    "Charge Fee:" : "Charge Fee : ",
    "Merchant " : "Merchant ",
    "Select Merchant" : "Select Merchant",
    "Order Number": "Order Number",
    "Your Order Number": "Your Order Number",
    "Payment Currency ": "Payment Currency ",
    "Select Payment Currency": "Select Payment Currency",
    "No currency available": "No currency available",
    "Deposit Amount": "Deposit Amount",
    "Your Deposit Amount": "Your Deposit Amount",
    "Minimum to maximum transaction is 1000-4000 USD": "Minimum to maximum transaction is 1,000-4,000 USD",
    "Remove Withdrawal Method": "Remove Withdrawal Method",
    "Withdrawal Type": 'Withdrawal Type',
    "fiat Withdraw": "fiat withdraw",
    "crypto Withdraw": "crypto withdraw",
    "Delete" : "Delete",
    "Balance Available": "Balance Available",
    "Amount must greater than 0.": 'Amount must greater than 0.',
    "Remark:": "Remark:",
    "Amount must only within 1000 - 4000": 'Amount must only within 1,000 - 4,000',
    "View Details": 'View Details',
    "Details": 'Details',
    "Investment Package": "Investment Package",
    "Select Your Package": "Select Your Package",
    "Monthly Return": "Monthly Return",
    "Minimum investment amount is": 'Minimum investment amount is',
    "Maximum investment amount is": "Maximum investment amount is",
    "Purchase Package Details": "Purchase Package Details",
    "Purchase Datetime": "Purchase Datetime",
    "Matured Datetime": 'Matured Datetime',
    "Investment Allocation": "Investment Allocation",
    "Surrender": "Surrender",
    "Successfully surrender": "Successfully surrender",
    "active": "active",
    "Account Type": "Account Type",
    "Select Account Type": "Select Account Type",
    "No account type available": "No account type available",
    "Days": "Days",
    "Processing Fee": "Processing Fee",
    "Invalid withdraw amount": 'Invalid withdraw amount',
    "Withdraw": "Withdraw",
    "Account": 'Account',
    "Payment Amount": 'Payment Amount',
    "Your Payment Amount": 'Your Payment Amount',
    "Convert": "Convert",
    "Exchange Rate": "Exchange Rate : ",
    "Your IP does not support the operation": "Your IP does not support the operation",
    "to": "to",
    "inactive": "inactive",
    "Account withdrawal type": "Withdrawal Type",
    "Select withdrawal type": "Select withdrawal type",
    "MT5 Login ID": "MT5 Login ID",
    "completed": "completed",
    "Investment Amount": "Investment Amount",
    "Level Bonus": 'Level Bonus',
    "Global performance distribution rewards": "Global Performance\nDistribution Rewards",
    "gold trading": "Gold Trading",
    "Transaction Record": "Transaction Record",
    "hierarchical bonus": "Hierarchical Bonus",
    "Buy": "Buy",
    "Sell": "Sell",
    "Enter price": 'Enter price',
    "Enter amount": 'Enter amount',
    "Transaction Type": "Transaction Type",
    "Total": "Total",
    "Time": "Time",
    "1 Day": "1 Day",
    "1 Week": "1 Week",
    "1 Month": "1 Month",
    "Amount of Gold": "Amount of Gold",
    "Wallet Balance": "Wallet Balance",
    "Gold Trading": "Gold Trading",
    "Real time gold price": "Real time gold price",
    "Historical gold price": "Historical gold price",
    "Order": "Order",
    "Order record": 'Order record',
    "Order has been cancelled": "Order has been cancelled",
    "Are you sure you want to cancel this order?": "Are you sure you want to cancel this order?",
    "Confirm": "Confirm",
    "Date": "Date",
    "Side": "Side",
    "Pair": 'Pair',
    "Trade Type": "Trade Type",
    "More": "More",
    "Order Book": 'Order Book',
    "Buy Order": 'Buy Order',
    "Sell Order": "Sell Order",
    "LIMIT": 'LIMIT',
    "MARKET": "MARKET",
    "Spot": "Spot",
    "Invalid amount": "Invalid amount",
    "Invalid price": "Invalid price",
    "24h Change": "24h Change",
    "24h High": '24h High',
    "24h Low": '24h Low',
    '24h Volume': "24h Volume",
    "MAKER": "MAKER",
    "TAKER": "TAKER",
    "Open Orders": "Open Orders",
    "Order History": "Order History",
    "Trade History": "Trade History",
    "Role": 'Role',
    "Filled": "Filled",
    "Fee": "Fee",
    "Received": "Received",
    "Datetime": "Datetime",
}

export default EN;