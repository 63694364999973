import { connect } from 'react-redux';
import "../../../css/loading.scss";
import { translate } from "../../../utils/translate";

const Loadingv3 = (props) => {
    return (
        <div className={`${props.theme === 'dark' ? 'loading3' : 'loading4'}`}>
            <div className="preloader-container py-4">
                <div>
                    <div className="progress-bar">
                        <div className="progress-color"></div>
                    </div>
                    <div className="mt-2 text-center">
                        <span className={`font-weight-400 font-16 ${props.theme === 'dark' ? 'text-white' : 'page-title-color'}`}>{translate(props.lang, "Loading Data")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(Loadingv3);