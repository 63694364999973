import { useEffect, useState } from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Input, Row, Table } from "reactstrap";
import swal from "sweetalert";
import "../../../css/investment.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
import { FaSpinner } from "react-icons/fa";
import { Range, getTrackBackground } from "react-range";
import moment from "moment";
import ReactApexChart from 'react-apexcharts';
import { Link } from "react-router-dom";

const buyorderbook = [
    { "price": 0.26, "amount": 100, "total": 70.12, "sum": 269.70 },
    { "price": 0.25, "amount": 200, "total": 115.24, "sum": 730.66 },
    { "price": 0.24, "amount": 300, "total": 1001.20, "sum": 4902.33 },
    { "price": 0.23, "amount": 400, "total": 144.93, "sum": 5532.50 },
    { "price": 0.22, "amount": 500, "total": 101.09, "sum": 5992.05 }
]
const buybookamount = 1500;

const sellorderbook = [
    { "price": 0.26, "amount": 100, "total": 70.12, "sum": 269.70 },
    { "price": 0.25, "amount": 200, "total": 115.24, "sum": 730.66 },
    { "price": 0.24, "amount": 300, "total": 1001.20, "sum": 4902.33 },
    { "price": 0.23, "amount": 400, "total": 144.93, "sum": 5532.50 },
    { "price": 0.22, "amount": 500, "total": 101.09, "sum": 5992.05 }
]
const sellbookamount = 1500;

const GoldTrading = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [duration, setDuration] = useState('day');
    let errorObj = { price: '', amount: '', totalPay: '' };
    const [buyErrors, setBuyErrors] = useState(errorObj)
    const [sellErrors, setSellErrors] = useState(errorObj)
    const [loadingBuy, setLoadingBuy] = useState(false);
    const [loadingSell, setLoadingSell] = useState(false);
    const [tradeType, setTradeType] = useState('LIMIT');
    const [chartLoading, setChartLoading] = useState(true);
    const [buyFormData, setBuyFormData] = useState({
        price: '',
        amount: '',
        totalPay: '',
        walletType: 'USDT',
    });
    const [sellFormData, setSellFormData] = useState({
        price: '',
        amount: '',
        totalPay: '',
        walletType: 'GOLD',
    });
    const [usdtWallet, setUsdtWallet] = useState({
        walletBalance: 0,
    })
    const [goldWallet, setGoldWallet] = useState({
        walletBalance: 0,
    })
    const [buyRangeValues, setBuyRangeValues] = useState([0]);
    const [sellRangeValues, setSellRangeValues] = useState([0]);
    const [candlestickSeries, setCandlestickSeries] = useState([{
        data: []
    }]);
    const STEP = 25;
    const MIN = 0;
    const MAX = 100;
    const marketOption = [
        { label: translate(props.lang, 'Amount'), value: 'GOLD' },
        { label: translate(props.lang, 'Total'), value: 'USDT' }
    ];

    const [candlestickOption, setCandlestickOption] = useState({
        chart: {
            type: 'candlestick',
            height: 350
        },
        title: {
            text: '',
            align: 'left'
        },
        animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 300,
            animateGradually: {
                enabled: true,
                delay: 100
            },
            dynamicAnimation: {
                enabled: true,
                speed: 150
            }
        },
        stroke: {
            width: 1
        },
        xaxis: {
            type: 'Datetime',
            rotate: 90,
            innerWidth: 100,
            labels: {
                formatter: function (value) {
                    let date = "";
                    if (duration === 'hour') {
                        date = moment(value).format('HH:mm');
                    } else if (duration === 'month') {
                        date = moment(value).format('DD MMM YY');
                    } else {
                        date = moment(value).format('DD MMM');
                    }

                    return date;
                }
            }
        },
        yaxis: {
            tooltip: {
                enabled: true
            },

        },
        plotOptions: {
            candlestick: {
                colors: {
                    upward: '#0EcB81',
                    downward: '#F6465D'
                }
            }
        },
    });

    const handleBuyFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...buyFormData };
        newFormData[fieldName] = fieldValue;
        setBuyFormData(newFormData);
    }

    const handleSellFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...sellFormData };
        newFormData[fieldName] = fieldValue;
        setSellFormData(newFormData);
    }

    useEffect(() => {
        generateGraph();
    }, [])

    const setBuyRange = (val) => {
        let newFormData = { ...buyFormData };
        setBuyRangeValues(val);
        if (!val[0]) {
            if (newFormData.amount && newFormData.price) {
                newFormData.totalPay = (newFormData.amount * newFormData.price);
                setBuyFormData(newFormData);
            }
        } else {
            let totalPay = (usdtWallet.walletBalance * val[0] / 100);
            if (!newFormData.amount && !newFormData.price) {
                newFormData.totalPay = totalPay;
                setBuyFormData(newFormData);
            } else {
                if (newFormData.price) {
                    newFormData.amount = (totalPay / newFormData.price);
                    newFormData.totalPay = totalPay;
                    setBuyFormData(newFormData);
                } else {
                    if (newFormData.amount) {
                        if (totalPay > 0) {
                            newFormData.price = totalPay / newFormData.amount;
                        }
                        newFormData.totalPay = totalPay;
                        setBuyFormData(newFormData);
                    }
                }
            }

        }

    }

    const setSellRange = (val) => {
        let newFormData = { ...sellFormData };
        let amount = (goldWallet.walletBalance * val[0] / 100);

        newFormData.amount = amount;
        setSellRangeValues(val)
        if (!val[0]) {
            if (newFormData.amount && newFormData.price) {
                newFormData.totalPay = (newFormData.amount * newFormData.price);
            }
            setSellFormData(newFormData);
        } else {
            if (newFormData.price) {
                newFormData.totalPay = (newFormData.price * amount);
            }
            setSellFormData(newFormData);
        }
    }

    const submitBuyForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (tradeType === 'LIMIT') {
            if (!buyFormData.price) {
                errorMsg.price = "This field is required.";
                check = false;
            } else {
                if (buyFormData.price < 0) {
                    errorMsg.price = "Invalid price.";
                    check = false;
                }
            }
        }
        if (!buyFormData.amount) {
            errorMsg.amount = "This field is required.";
            check = false;
        } else {
            if (buyFormData.amount < 0) {
                errorMsg.amount = "Invalid amount";
                check = false;
            }
        }
        setBuyErrors(errorMsg)
        if (check) {

        }
    }

    const submitSellForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (tradeType === 'LIMIT') {
            if (!sellFormData.price) {
                errorMsg.price = "This field is required.";
                check = false;
            } else {
                if (sellFormData.price < 0) {
                    errorMsg.price = "Invalid price.";
                    check = false;
                }
            }
        }
        if (!sellFormData.amount) {
            errorMsg.amount = "This field is required.";
            check = false;
        } else {
            if (sellFormData.amount < 0) {
                errorMsg.amount = "Invalid amount";
                check = false;
            }
        }
        setSellErrors(errorMsg)
        if (check) {

        }
    }

    const generateGraph = async () => {
        let data = [
            { openPrice: 0.15, closePrice: 0.16, highPrice: 0.21, lowPrice: 0.13, openTime: 1693152000, closeTime: 1693756800 },
            { openPrice: 0.16, closePrice: 0.17, highPrice: 0.2, lowPrice: 0.15, openTime: 1693756800, closeTime: 1694361600 },
            { openPrice: 0.17, closePrice: 0.19, highPrice: 0.19, lowPrice: 0.15, openTime: 1694361600, closeTime: 1694966400 },
            { openPrice: 0.19, closePrice: 0.17, highPrice: 0.23, lowPrice: 0.16, openTime: 1694966400, closeTime: 1695571200 },
            { openPrice: 0.17, closePrice: 0.15, highPrice: 0.18, lowPrice: 0.13, openTime: 1695571200, closeTime: 1696176000 },
            { openPrice: 0.15, closePrice: 0.13, highPrice: 0.18, lowPrice: 0.13, openTime: 1696176000, closeTime: 1696780800 },
            { openPrice: 0.13, closePrice: 0.14, highPrice: 0.15, lowPrice: 0.13, openTime: 1696780800, closeTime: 1697385600 },
            { openPrice: 0.14, closePrice: 0.21, highPrice: 0.21, lowPrice: 0.12, openTime: 1697385600, closeTime: 1697990400 },
            { openPrice: 0.21, closePrice: 0.24, highPrice: 0.27, lowPrice: 0.18, openTime: 1697990400, closeTime: 1698595200 },
            { openPrice: 0.24, closePrice: 0.22, highPrice: 0.25, lowPrice: 0.17, openTime: 1698595200, closeTime: 1699200000 },
            { openPrice: 0.22, closePrice: 0.27, highPrice: 0.3, lowPrice: 0.2, openTime: 1699200000, closeTime: 1699804800 },
            { openPrice: 0.27, closePrice: 0.24, highPrice: 0.28, lowPrice: 0.15, openTime: 1699804800, closeTime: 1700409600 },
            { openPrice: 0.24, closePrice: 0.26, highPrice: 0.28, lowPrice: 0.22, openTime: 1700409600, closeTime: 1701014400 },
            { openPrice: 0.26, closePrice: 0.29, highPrice: 0.29, lowPrice: 0.22, openTime: 1701014400, closeTime: 1701619200 },
            { openPrice: 0.29, closePrice: 0.32, highPrice: 0.38, lowPrice: 0.27, openTime: 1701619200, closeTime: 1702224000 },
            { openPrice: 0.32, closePrice: 0.31, highPrice: 0.33, lowPrice: 0.3, openTime: 1702224000, closeTime: 1702828800 },
            { openPrice: 0.31, closePrice: 0.37, highPrice: 0.4, lowPrice: 0.27, openTime: 1702828800, closeTime: 1703433600 },
            { openPrice: 0.37, closePrice: 0.38, highPrice: 0.43, lowPrice: 0.33, openTime: 1703433600, closeTime: 1704038400 },
            { openPrice: 0.38, closePrice: 0.33, highPrice: 0.43, lowPrice: 0.3, openTime: 1704038400, closeTime: 1704643200 },
            { openPrice: 0.33, closePrice: 0.3, highPrice: 0.35, lowPrice: 0.27, openTime: 1704643200, closeTime: 1705248000 },
            { openPrice: 0.3, closePrice: 0.24, highPrice: 0.3, lowPrice: 0.2, openTime: 1705248000, closeTime: 1705852800 },
            { openPrice: 0.24, closePrice: 0.21, highPrice: 0.21, lowPrice: 0.2, openTime: 1705852800, closeTime: 1706457600 }
        ];
        let tempData = [];
        data.map((item) => {
            let data = {
                x: new Date(item.openTime),
                y: [item.openPrice, item.highPrice, item.lowPrice, item.closePrice]
            }
            tempData.push(data);
        })
        await setCandlestickSeries([{ data: tempData }])
        setTimeout(() => {
            setChartLoading(false);
        }, 1000);
    }

    const changeTradeType = (type) => {
        setBuyErrors(errorObj);
        setSellErrors(errorObj);
        setBuyFormData({
            price: '',
            amount: '',
            totalPay: '',
            walletType: 'USDT',
        })
        setSellFormData({
            price: '',
            amount: '',
            totalPay: '',
            walletType: 'GOLD',
        })
        setTradeType(type);
    }

    return (
        <>
            <div className='pb-5 investment'>
                <Row className="align-items-center justify-content-center mx-0 px-0">
                    <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                        <div className="whiteContainer py-4">
                            <Row className="mb-4 border-bottom-grey px-0 mx-0">
                                <Col lg={2} md={3} sm={6} xs={6} className="mb-3">
                                    <span className="font-weight-500 font-22 letter-spacing--001 text-black">GOLD/USDT</span>
                                </Col>
                                <Col lg={2} md={3} sm={6} xs={6} className="mb-3">
                                    <span className="font-weight-400 font-16 letter-spacing--001 text-black d-block mb-1">{translate(props.lang, "24h Change")}</span>
                                    <span className={`font-weight-700 font-20 letter-spacing--001 text-green`}>100</span>
                                </Col>
                                <Col lg={2} md={3} sm={6} xs={6} className="mb-3">
                                    <span className="font-weight-400 font-16 letter-spacing--001 text-black d-block mb-1">{translate(props.lang, "24h High")}</span>
                                    <span className="font-weight-700 font-20 letter-spacing--001 text-black">300.00</span>
                                </Col>
                                <Col lg={2} md={3} sm={6} xs={6} className="mb-3">
                                    <span className="font-weight-400 font-16 letter-spacing--001 text-black d-block mb-1">{translate(props.lang, "24h Low")}</span>
                                    <span className="font-weight-700 font-20 letter-spacing--001 text-black">287.00</span>
                                </Col>
                                <Col lg={2} md={3} sm={6} xs={6} className="mb-3">
                                    <span className="font-weight-400 font-16 letter-spacing--001 text-black d-block mb-1">{translate(props.lang, "24h Volume")}(GOLD)</span>
                                    <span className="font-weight-700 font-20 letter-spacing--001 text-black">100,000</span>
                                </Col>
                                <Col lg={2} md={3} sm={6} xs={6} className="mb-3">
                                    <span className="font-weight-400 font-16 letter-spacing--001 text-black d-block mb-1">{translate(props.lang, "24h Volume")}(USDT)</span>
                                    <span className="font-weight-700 font-20 letter-spacing--001 text-black">300,000</span>
                                </Col>
                            </Row>
                            <Row className="px-0 mx-0">
                                <Col lg={3} md={4} sm={12} xs={12} className="mb-4 mb-md-0">
                                    <div className="border tradeTable">
                                        <div className={`table-responsive`}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center"><span className="font-weight-500 font-10 text-black">{translate(props.lang, "Price")}(USDT)</span></th>
                                                        <th className="text-center"><span className="font-weight-500 font-10 text-black">{translate(props.lang, "Amount")}(Gold)</span></th>
                                                        <th className="text-center"><span className="font-weight-500 font-10 text-black">{translate(props.lang, "Total")}</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {sellorderbook.map((data, i) => (
                                                        <tr key={i} style={{ background: `linear-gradient(90deg, white ${100 - (data.amount / sellbookamount * 100).toString() + "%"}, rgba(193, 8, 8, 0.2) 0%)` }}>
                                                            <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{data.price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                            <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{data.amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                            <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{data.total.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        </tr>
                                                    ))}
                                                    {sellorderbook.length === 0 ? (
                                                        <tr>
                                                            <td colSpan={3} className="text-center border-bottom-none">
                                                                <span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "No Results Found")}</span>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    <tr>
                                                        <td colSpan={2} className={`text-center border-bottom-none text-red pt-3`}>
                                                            <span className="font-weight-600 font-18">{`\u007E`}$150</span>
                                                        </td>
                                                        <td className="text-center border-bottom-none pt-3">
                                                            <Link to="/investment/orderBook" className="cursor-pointer">
                                                                <span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "More")}</span>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className={`table-responsive`}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th className="text-center"><span className="font-weight-500 font-10 text-black">{translate(props.lang, "Price")}(USDT)</span></th>
                                                        <th className="text-center"><span className="font-weight-500 font-10 text-black">{translate(props.lang, "Amount")}(Gold)</span></th>
                                                        <th className="text-center"><span className="font-weight-500 font-10 text-black">{translate(props.lang, "Total")}</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {buyorderbook.map((data, i) => (
                                                        <tr key={i} style={{ background: `linear-gradient(90deg, white ${100 - (data.amount / buybookamount * 100)}%, rgba(38, 198, 63, 0.2) 0%)` }}>
                                                            <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{data.price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                            <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{data.amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                            <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{data.total.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        </tr>
                                                    ))}
                                                    {buyorderbook.length === 0 ? (
                                                        <tr>
                                                            <td colSpan={3} className="text-center border-bottom-none">
                                                                <span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "No Results Found")}</span>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={9} md={8} sm={12} xs={12}>
                                    <Row className=" mb-4">
                                        <Col lg={12} md={12} sm={12} xs={12} className="">
                                            <div className="d-flex w-100 align-items-center">
                                                <div className="me-3">
                                                    <span className="font-16 font-weight-400 text-grey-2">{translate(props.lang, "Time")}</span>
                                                </div>
                                                <div className="px-2" onClick={() => setDuration('day')}>
                                                    <span className={`font-16 font-weight-700 cursor-pointer ${duration === 'day' ? 'primary-text' : 'text-grey-2'}`}>{translate(props.lang, "1 Day")}</span>
                                                </div>
                                                <div className="px-2" onClick={() => setDuration('week')}>
                                                    <span className={`font-16 font-weight-700 cursor-pointer ${duration === 'week' ? 'primary-text' : 'text-grey-2'}`}>{translate(props.lang, "1 Week")}</span>
                                                </div>
                                                <div className="px-2" onClick={() => setDuration('month')}>
                                                    <span className={`font-16 font-weight-700 cursor-pointer ${duration === 'month' ? 'primary-text' : 'text-grey-2'}`}>{translate(props.lang, "1 Month")}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="">
                                            {/* candlestick graph */}
                                            <ReactApexChart options={candlestickOption} series={candlestickSeries} type="candlestick" height={350} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="whiteContainer py-4">
                            <Row className="justify-content-center align-items-center px-0 mx-0 border-bottom pb-3">
                                <Col lg={3} md={3} sm={4} xs={4} className="text-start">
                                    <span className="font-weight-600 font-20 text-black">{translate(props.lang, 'Spot')}</span>
                                </Col>
                                <Col lg={9} md={9} sm={8} xs={8} className="text-end">
                                    <div className="d-flex justify-content-end align-items-end">
                                        <span onClick={() => changeTradeType('LIMIT')} className={`font-16 me-4 cursor-pointer ${tradeType === 'LIMIT' ? 'primary-text font-weight-700' : 'font-weight-500 text-grey-3'}`}>{translate(props.lang, 'LIMIT')}</span>
                                        <span onClick={() => changeTradeType('MARKET')} className={`font-16 cursor-pointer ${tradeType === 'MARKET' ? 'primary-text font-weight-700' : 'font-weight-500 text-grey-3'}`}>{translate(props.lang, 'MARKET')}</span>
                                    </div>
                                </Col>
                            </Row>
                            {/* buy / sell gold form */}
                            <Row className="justify-content-center px-0 mx-0 mt-4">
                                {/* buy gold */}
                                <Col lg={6} md={6} sm={12} xs={12} className="px-0 mb-md-0 mb-5">
                                    <Row className="px-0 mx-0">
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                            <div className="d-flex w-100 align-items-center">
                                                <div>
                                                    <span className="font-16 font-weight-600 text-capitalize text-black">{translate(props.lang, "Wallet Balance")}</span>
                                                </div>
                                                <div className="px-3">
                                                    <span className="font-16 font-weight-500 text-capitalize text-black text-uppercase">{usdtWallet.walletBalance}</span>
                                                    <span className="font-16 font-weight-500 text-capitalize text-grey-8 text-uppercase ms-2">USDT</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                            <div className="text-start">
                                                <div className={`groupLabel-input py-1 mb-1 ${buyErrors.price ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Price")} <span className='text-red'>*</span></span>
                                                    {tradeType === 'LIMIT' ? (
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={"number"}
                                                                name="price"
                                                                placeholder={translate(props.lang, "Enter price")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={buyFormData.price}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                onChange={e => handleBuyFormChange(e)}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4'>
                                                                <span className="font-weight-600 font-18 text-uppercase text-grey-8">USDT</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type='text'
                                                                name="price"
                                                                placeholder={translate(props.lang, "0.00")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={translate(props.lang, 'MARKET PRICE')}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                readOnly
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, buyErrors.price)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                            <div className="text-start">
                                                {tradeType === 'LIMIT' ? (
                                                    <div className={`groupLabel-input py-1 mb-1 ${buyErrors.amount ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Amount")} <span className='text-red'>*</span></span>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={"number"}
                                                                name="amount"
                                                                placeholder={translate(props.lang, "Enter amount")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={buyFormData.amount}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                onChange={e => handleBuyFormChange(e)}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4'>
                                                                <span className="font-weight-600 font-18 text-uppercase text-grey-8">GOLD</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className={`groupLabel-input py-1 mb-1 ${buyErrors.amount ? 'errorBorder' : ''}`}>
                                                        <div className="d-flex align-items-center mt-2">
                                                            <div className="w-25">
                                                                <Input
                                                                    name="walletType"
                                                                    type="select"
                                                                    className="input-transparent-select px-md-4 px-3"
                                                                    value={buyFormData.walletType}
                                                                    onChange={e => handleBuyFormChange(e)}
                                                                >
                                                                    {marketOption.map((item, i) => (
                                                                        <option value={item.value} key={i}>{item.label}</option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                            <div className="me-3">
                                                                <svg height="12" width="12" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                                                                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                                                </svg>
                                                            </div>
                                                            <span className='text-red'>*</span>
                                                        </div>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={"number"}
                                                                name="amount"
                                                                placeholder={translate(props.lang, "Enter amount")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={buyFormData.amount}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                onChange={e => handleBuyFormChange(e)}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4'>
                                                                <span className="font-weight-600 font-18 text-uppercase text-grey-8">{buyFormData.walletType}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, buyErrors.amount)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        {tradeType === 'LIMIT' ? (
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                                <div className="text-start d-flex w-100 align-items-center px-4">
                                                    <Range
                                                        values={buyRangeValues}
                                                        step={STEP}
                                                        min={MIN}
                                                        max={MAX}
                                                        rtl={false}
                                                        onChange={(values) => setBuyRange(values)}
                                                        renderMark={({ props, index }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    borderRadius: "50%",
                                                                    backgroundColor: index * STEP < buyRangeValues[0] ? '#fff' : '#D9D9D9',
                                                                    border: index * STEP < buyRangeValues[0] ? '5px solid #E0B830' : '5px solid #D9D9D9',
                                                                }}
                                                            />
                                                        )}
                                                        renderTrack={({ props, children }) => (
                                                            <div
                                                                onMouseDown={props.onMouseDown}
                                                                onTouchStart={props.onTouchStart}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '36px',
                                                                    display: 'flex',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <div
                                                                    ref={props.ref}
                                                                    style={{
                                                                        height: '5px',
                                                                        width: '100%',
                                                                        borderRadius: '4px',
                                                                        background: getTrackBackground({
                                                                            values: buyRangeValues,
                                                                            colors: ['#FFDB7E', '#D9D9D9'],
                                                                            min: MIN,
                                                                            max: MAX,
                                                                        }),
                                                                        alignSelf: 'center'
                                                                    }}
                                                                >
                                                                    {children}
                                                                </div>
                                                            </div>
                                                        )}
                                                        renderThumb={({ props, isDragged }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '20px',
                                                                    width: '20px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#ffffff',
                                                                    display: 'flex',
                                                                    border: '5px solid #EBB019',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    boxShadow: '0px 2px 6px #FFEFC5'
                                                                }}
                                                            >
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        ) : null}
                                        {tradeType === 'LIMIT' ? (
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 mb-1 d-flex align-items-center w-100 px-3 ${buyErrors.totalPay ? 'errorBorder' : ''}`}>
                                                        <div className="total-text-box">
                                                            <span className="font-weight-500 font-18 text-grey-8">{translate(props.lang, "Total")}</span>
                                                        </div>
                                                        <Input
                                                            type={"number"}
                                                            name="totalPay"
                                                            placeholder={'0.00'}
                                                            className="form-control input-transparent py-1 px-md-4 px-3 text-end"
                                                            value={buyFormData.totalPay}
                                                            onWheel={event => event.currentTarget.blur()}
                                                            autoComplete="off"
                                                            onChange={e => handleBuyFormChange(e)}
                                                        />
                                                        <span className="font-weight-500 font-18 text-grey-8 me-3">USDT</span>
                                                    </div>
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, buyErrors.totalPay)}</span>
                                                </div>
                                            </Col>
                                        ) : null}
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <button className="buy-btn px-4 py-2 text-center mx-auto w-100" onClick={() => submitBuyForm()} disabled={loadingBuy} >
                                                <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loadingBuy ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Buy")}</span>
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                                {/* sell gold */}
                                <Col lg={6} md={6} sm={12} xs={12} className="px-0">
                                    <Row className="px-0 mx-0">
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                            <div className="d-flex w-100 align-items-center">
                                                <div>
                                                    <span className="font-16 font-weight-600 text-capitalize text-black">{translate(props.lang, "Amount of Gold")}</span>
                                                </div>
                                                <div className="px-3">
                                                    <span className="font-16 font-weight-500 text-capitalize text-black text-uppercase">8,880,660.00</span>
                                                    <span className="font-16 font-weight-500 text-capitalize text-grey-8 text-uppercase ms-2">USDT</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                            <div className="text-start">
                                                <div className={`groupLabel-input py-1 mb-1 ${sellErrors.price ? 'errorBorder' : ''}`}>
                                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Price")} <span className='text-red'>*</span></span>
                                                    {tradeType === 'LIMIT' ? (
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={"number"}
                                                                name="price"
                                                                placeholder={translate(props.lang, "Enter price")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={sellFormData.price}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                onChange={e => handleSellFormChange(e)}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4'>
                                                                <span className="font-weight-600 font-18 text-uppercase text-grey-8">USDT</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type='text'
                                                                name="price"
                                                                placeholder={translate(props.lang, "0.00")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={translate(props.lang, 'MARKET PRICE')}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                readOnly
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, sellErrors.price)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                            <div className="text-start">
                                                {tradeType === 'LIMIT' ? (
                                                    <div className={`groupLabel-input py-1 mb-1 ${sellErrors.amount ? 'errorBorder' : ''}`}>
                                                        <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Amount")} <span className='text-red'>*</span></span>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={"number"}
                                                                name="amount"
                                                                placeholder={translate(props.lang, "Enter amount")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={sellFormData.amount}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                onChange={e => handleSellFormChange(e)}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4'>
                                                                <span className="font-weight-600 font-18 text-uppercase text-grey-8">GOLD</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className={`groupLabel-input py-1 mb-1 ${sellErrors.amount ? 'errorBorder' : ''}`}>
                                                        <div className="d-flex align-items-center mt-2">
                                                            <div className="w-25">
                                                                <Input
                                                                    name="walletType"
                                                                    type="select"
                                                                    className="input-transparent-select px-md-4 px-3"
                                                                    value={sellFormData.walletType}
                                                                    onChange={(e) => handleSellFormChange(e)}
                                                                >
                                                                    {marketOption.map((item, i) => (
                                                                        <option value={item.value} key={i}>{item.label}</option>
                                                                    ))}
                                                                </Input>
                                                            </div>
                                                            <div className="me-3">
                                                                <svg height="12" width="12" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                                                                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                                                                </svg>
                                                            </div>
                                                            <span className='text-red'>*</span>
                                                        </div>
                                                        <div className={`input-group`}>
                                                            <Input
                                                                type={"number"}
                                                                name="amount"
                                                                placeholder={translate(props.lang, "Enter amount")}
                                                                className="form-control input-transparent py-1 px-md-4 px-3"
                                                                value={sellFormData.amount}
                                                                onWheel={event => event.currentTarget.blur()}
                                                                autoComplete="off"
                                                                onChange={e => handleSellFormChange(e)}
                                                            />
                                                            <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4'>
                                                                <span className="font-weight-600 font-18 text-uppercase text-grey-8">{sellFormData.walletType}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="ms-4">
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, sellErrors.amount)}</span>
                                                </div>
                                            </div>
                                        </Col>
                                        {tradeType === 'LIMIT' ? (
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                                <div className="text-start d-flex w-100 align-items-center px-4">
                                                    <Range
                                                        values={sellRangeValues}
                                                        step={STEP}
                                                        min={MIN}
                                                        max={MAX}
                                                        rtl={false}
                                                        onChange={(values) => setSellRange(values)}
                                                        renderMark={({ props, index }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '15px',
                                                                    width: '15px',
                                                                    borderRadius: "50%",
                                                                    backgroundColor: index * STEP < sellRangeValues[0] ? '#fff' : '#D9D9D9',
                                                                    border: index * STEP < sellRangeValues[0] ? '5px solid #E0B830' : '5px solid #D9D9D9',
                                                                }}
                                                            />
                                                        )}
                                                        renderTrack={({ props, children }) => (
                                                            <div
                                                                onMouseDown={props.onMouseDown}
                                                                onTouchStart={props.onTouchStart}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '36px',
                                                                    display: 'flex',
                                                                    width: '100%'
                                                                }}
                                                            >
                                                                <div
                                                                    ref={props.ref}
                                                                    style={{
                                                                        height: '5px',
                                                                        width: '100%',
                                                                        borderRadius: '4px',
                                                                        background: getTrackBackground({
                                                                            values: sellRangeValues,
                                                                            colors: ['#FFDB7E', '#D9D9D9'],
                                                                            min: MIN,
                                                                            max: MAX,
                                                                        }),
                                                                        alignSelf: 'center'
                                                                    }}
                                                                >
                                                                    {children}
                                                                </div>
                                                            </div>
                                                        )}
                                                        renderThumb={({ props, isDragged }) => (
                                                            <div
                                                                {...props}
                                                                style={{
                                                                    ...props.style,
                                                                    height: '20px',
                                                                    width: '20px',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: '#ffffff',
                                                                    display: 'flex',
                                                                    border: '5px solid #EBB019',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    boxShadow: '0px 2px 6px #FFEFC5'
                                                                }}
                                                            >
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        ) : null}
                                        {tradeType === 'LIMIT' ? (
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                                <div className="text-start">
                                                    <div className={`groupLabel-input py-1 mb-1 d-flex align-items-center w-100 px-3 ${sellErrors.totalPay ? 'errorBorder' : ''}`}>
                                                        <div className="total-text-box">
                                                            <span className="font-weight-500 font-18 text-grey-8">{translate(props.lang, "Total")}</span>
                                                        </div>
                                                        <Input
                                                            type={"amount"}
                                                            name="totalPay"
                                                            placeholder={'0.00'}
                                                            className="form-control input-transparent py-1 px-md-4 px-3 text-end"
                                                            value={sellFormData.totalPay}
                                                            onWheel={event => event.currentTarget.blur()}
                                                            autoComplete="off"
                                                            onChange={e => handleSellFormChange(e)}
                                                        />
                                                        <span className="font-weight-500 font-18 text-grey-8">USDT</span>
                                                    </div>
                                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, sellErrors.totalPay)}</span>
                                                </div>
                                            </Col>
                                        ) : null}
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <button className="sell-btn px-4 py-2 text-center mx-auto w-100" onClick={() => submitSellForm()} disabled={loadingSell} >
                                                <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loadingSell ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Sell")}</span>
                                            </button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(GoldTrading);