
import { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { FaAngleDown } from "react-icons/fa";
import "../../../css/authHeader.scss";

const lang = [
    { name: 'English', code: 'EN' },
    { name: '中文 (简体)', code: 'ZH' },
]

const AuthHeader = (props) => {
    const dispatch = useDispatch();
    const [logo, setLogo] = useState('blue');
    const [navbarColor, setNavbarColor] = useState("navbar-transparent");
    useEffect(() => {
        const updateNavbarColor = () => {
            if (document.documentElement.scrollTop > 0 || document.body.scrollTop > 0) {
                // if (window.innerWidth <= 991) {
                    setNavbarColor("header-white");
                // }
            } else if (document.documentElement.scrollTop < 10 || document.body.scrollTop < 10) {
                setNavbarColor("header-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });
    useEffect(() => {
        if (window.innerWidth <= 991) {
            setLogo('blue')
        } else {
            setLogo(props.logoColor)
        }
    }, [window.innerWidth]);

    const changeLanguage = (language) => {
        dispatch({
            type: 'UPDATE_LANGUAGE',
            lang: language
        });
        localStorage.setItem('bcg-lang', language);
        document.getElementById('openDropdown-lg').checked = false;
    }

    useEffect(() => {
        var checkbox = document.querySelector('#openDropdown-lg');
        var icon = document.querySelector('#tooltip-parent');
        var listener = function (e) {
            if (e.target != checkbox && e.target != icon) {
                checkbox.checked = false;
                document.removeEventListener('click', listener);
            }
        };

        checkbox.addEventListener('click', function () {
            if (this.checked) {
                document.addEventListener('click', listener);
            }
        });
    })

    return (
        <div className={`w-100 py-2 px-lg-4 px-md-4 px-1 authHeader ${navbarColor}`}>
            <Row className="align-items-center mx-0">
                <Col lg={6} md={5} sm={5} xs={5} className="text-start">
                </Col>
                <Col lg={6} md={7} sm={7} xs={7} className="text-end">
                    <div className={`lang-dropdown-container position-relative`}>
                        <label htmlFor="openDropdown-lg" className={`lang-dropdown dropdown px-3 py-2 mb-0 mt-1`}>
                            <img src={require('../../../images/icon/language.svg').default} alt="language"/>
                        </label>
                        <input type="checkbox" id="openDropdown-lg" hidden />
                        <div className={`lang-dropdown-menu-lg`}>
                            <div className="lang-menu-container text-center w-100">
                                {lang.map((item, i) => (
                                    <span key={i} className={`font-weight-500 font-14 lang-text`} onClick={() => changeLanguage(item.code)}>{item.name}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang,
    };
};
export default connect(mapStateToProps)(AuthHeader);