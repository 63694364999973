import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/settings.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";
const ChangePassword = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let errorObj = {
        password: null,
        password2: null,
        confirmPassword: null,
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        password2: '',
        confirmPassword: '',
    })

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };
        if (!formData.password) {
            errorMsg.password = "This field is required.";
            check = false;
        }
        if (!formData.password2) {
            errorMsg.password2 = "This field is required.";
            check = false;
        } else {
            if (formData.password2.length < 6) {
                errorMsg.password2 = "New password must at least 6 characters";
                check = false;
            }
        }
        if (!formData.confirmPassword) {
            errorMsg.confirmPassword = "This field is required.";
            check = false;
        }
        if (formData.password2 && formData.confirmPassword) {
            if (formData.confirmPassword !== formData.password2) {
                errorMsg.confirmPassword = "Confirm password need to same with new password";
                check = false;
            }
        }
        setErrors(errorMsg);
        if (check) {
            swal(translate(props.lang, "Success"), translate(props.lang, "Password update successful"), "success");
            
        }
    }
    return (
        <>
            <div className='settings pb-5'>
                <div className="whiteContainer py-4 mt-3">
                    <Row className="align-items-center justify-content-center mx-0 px-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="d-flex align-items-center w-100">
                                <Link to="/settings/overview" className="cursor-pointer">
                                    <div className="d-flex align-items-center">
                                        <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                    </div>
                                </Link>
                                <div className="ms-3">
                                    <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Change Password")}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.password ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Old Password")} <span className='text-red'>*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            name="password"
                                            placeholder={translate(props.lang, "Your Old Password")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.password}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowPassword(!showPassword)}>
                                            {showPassword ? (
                                                <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                            ) : (
                                                <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className='ms-4'>
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.password)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.password2 ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "New Password")} <span className='text-red'>*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={showNewPassword ? "text" : "password"}
                                            name="password2"
                                            placeholder={translate(props.lang, "Your New Password")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.password2}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowNewPassword(!showNewPassword)}>
                                            {showNewPassword ? (
                                                <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                            ) : (
                                                <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className='ms-4'>
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.password2)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={12} sm={12} xs={12} className="mb-4">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.confirmPassword ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Confirm New Password")} <span className='text-red'>*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            placeholder={translate(props.lang, "Confirm Your New Password")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.confirmPassword}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {
                                                showConfirmPassword ? (
                                                    <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                                ) : (
                                                    <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                                )
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className='ms-4'>
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.confirmPassword)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => submitForm()} disabled={loading}>
                                <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Update")}</span>
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ChangePassword);