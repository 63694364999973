import moment from "moment";
import { useEffect, useState } from "react";
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row, Table } from "reactstrap";
import { Modal } from "react-bootstrap";
import "../../../css/wallet.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";
import InfoBox from "../Common/InfoBox";

const trxList = [
    { trxDateTime: '27/09/2023  12:06:21', trxType: 'USDT Deposit', amountType: 'USDT', amount: 100, status: 'pending', remark: '-' },
    { trxDateTime: '27/09/2023  12:06:21', trxType: 'USDT Deposit', amountType: 'USDT', amount: 100, status: 'approved', remark: '-' },
    { trxDateTime: '27/09/2023  12:06:21', trxType: 'USDT Deposit', amountType: 'USDT', amount: 100, status: 'rejected', remark: '-' },
]

const bonustrxList = [
    { trxDate: '27/09/2023  12:06:21', bonusType: 'USDT Deposit', amount: 100, status: 'pending', remark: '-' },
    { trxDate: '27/09/2023  12:06:21', bonusType: 'USDT Deposit', amount: 100, status: 'pending', remark: '-' },
]

const WalletOverview = (props) => {
    const [loading, setLoading] = useState(false);
    const [loadingBonusHistory, setLoadingBonusHistory] = useState(false);
    const [loadingWalletHistory, setLoadingWalletHistory] = useState(false);
    const [selectedAccordion, setSelectedAccordion] = useState(0);
    const [selectedBonusAccordion, setSelectedBonusAccordion] = useState(0);
    const dispatch = useDispatch();
    const productPerpage = 5;
    const [walletTransactionList, setWalletTransactionList] = useState(trxList);
    const [bonusHistoryList, setBonusHistoryList] = useState(bonustrxList);
    const [result, setResult] = useState({
        depositWallet: 0,
        bonusWallet: 0,
    })
    const [trxInfoModal, setTrxInfoModal] = useState(false);
    const [trxDetail, setTrxDetail] = useState(null);

    useEffect(() => {
        getStatement();
        getTransactionList();
        getBonusTransaction();
    }, [props.auth.auth.token, props.lang]);

    const getStatement = () => {
    }

    const getTransactionList = () => {
    }

    const getBonusTransaction = () => {
    }

    const openTrxInfoModal = (info) => {
        setTrxDetail(info);
        setTrxInfoModal(true);
    }

    const closeTrxInfoModal = () => {
        setTrxDetail(null);
        setTrxInfoModal(false)
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <div className="mt-3">
                    <Row className="align-items-center justify-content-start mx-0 px-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <InfoBox
                                icon={require('../../../images/wallet/bonusWallet.svg').default}
                                boxLabel={translate(props.lang, "Wallet")}
                                boxValue={`USD ${result.depositWallet.length > 0 ? Number(result.depositWallet[0].walletBalance).toLocaleString('en-US', digitFormat) : '0.00'}`}
                                loading={loading}
                                blackBg={true}
                            />
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="whiteContainer py-4 px-4">
                                <Row className="justify-content-center align-items-center px-3 text-center text-md-start">
                                    <Col lg={9} md={9} sm={12} xs={12} className="text-center text-md-start">
                                        <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Wallet Transaction History")}</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={4} xs={4} className="text-end d-none d-md-block">
                                        <Link to="/wallet/transaction-history">
                                            <span className="view-more-text font-weight-400 font-14 line-height-26">{translate(props.lang, "View More")}</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <div className="tableContainer d-none d-md-block">
                                    <Table responsive className="w-100 table-list mt-4 text-center">
                                        <thead>
                                            <tr>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Date & Time")}</th>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Type")}</th>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount Type")}</th>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount")} (USD)</th>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Status")}</th>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Remark")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadingWalletHistory ? (
                                                <tr>
                                                    <td colSpan={6}>
                                                        <LoadingV3 theme='light' />
                                                    </td>
                                                </tr>
                                            ) : (
                                                walletTransactionList.length > 0 ? (
                                                    walletTransactionList.map((item, i) => (
                                                        <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                            <td className={`font-weight-400 font-16 text-center lh-base`}>{item.trxDateTime}</td>
                                                            <td className={`font-weight-400 font-16 text-center`}>{translate(props.lang, item.trxType).replaceAll('_', ' ')}</td>
                                                            <td className={`font-weight-400 font-16 text-center text-uppercase`}>{item.amountType.toLowerCase()}</td>
                                                            <td className={`font-weight-400 font-16 text-center`}>{Number(item.amount).toLocaleString('en-US', digitFormat)}</td>
                                                            <td className={`font-weight-400 font-16 text-center`}>
                                                                {item.status ? (
                                                                    <div className={`py-2 px-2 d-inline-block text-center status-box text-capitalize ${item.status.toUpperCase()}`}>
                                                                        <span className="font-weight-400 font-14 statustext">{translate(props.lang, item.status.toUpperCase())}</span>
                                                                    </div>
                                                                ) : '-'}
                                                            </td>
                                                            {/* <td className={`font-weight-400 font-16 text-start`}>
                                                                <div className="mx-auto w-100 text-center">
                                                                    <div onClick={() => openTrxInfoModal(item)} className={`cursor-pointer py-2 px-1 d-inline-block viewBox`}>
                                                                        <span className="font-weight-400 font-14 line-height-26 break-all-word">{translate(props.lang, "View Details")}</span>
                                                                    </div>
                                                                </div>
                                                            </td> */}
                                                            <td className={`font-weight-400 font-16 text-center`}>{item.remark}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <div className="py-5 text-center bg-transparent">
                                                                <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                                <span className="d-block text-black mt-4">
                                                                    {translate(props.lang, "Oops! No data found...")}
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="d-block d-md-none tableContainerAccordion mt-3">
                                    {loadingWalletHistory ? (<LoadingV3 theme="dark" />) : (
                                        walletTransactionList.length > 0 ? (
                                            <Accordion preExpanded={[0]} className="pt-2 text-left">
                                                {walletTransactionList.map((item, i) => (
                                                    <AccordionItem key={i} uuid={i} className={`${i === selectedAccordion ? "mb-4 accordion-item" : "mb-4 accordion-item-inactive"} ${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} onClick={() => setSelectedAccordion(i)}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="px-4 py-3">
                                                                <Row className="mx-0 px-0 justify-content-center align-items-center">
                                                                    <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                                                                        <div>
                                                                            <span className={`font-weight-500 font-13 text-grey-1`}>{item.trxDateTime}</span>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start align-items-center pt-2">
                                                                            <span className={`font-weight-600 font-26 text-center text-capitalize page-title-color`}>
                                                                                <span className={`font-weight-600 font-18 text-center text-black-1`}>{item.amount.toLocaleString('en-US', digitFormat)} {item.walletType}</span></span>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start align-items-center pt-2">
                                                                            <div>
                                                                                <span className={`font-weight-600 font-18 text-capitalize text-center page-title-color`}>
                                                                                    {item.trxType.replaceAll('_', ' ')}
                                                                                </span>
                                                                            </div>
                                                                            <div className="mx-2">
                                                                                {item.status ? (
                                                                                    <div className={`py-2 px-2 d-inline-block text-center status-box text-capitalize ${item.status.toUpperCase()}`}>
                                                                                        <span className="font-weight-400 font-14 statustext">{translate(props.lang, item.status.toUpperCase())}</span>
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0">
                                                                        <div className="text-end">
                                                                            {
                                                                                selectedAccordion === i ? (<img src={require('../../../images/icon/up.svg').default} alt="up" />) : <img src={require('../../../images/icon/down.svg').default} alt="down" />
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={`${selectedAccordion === i ? "d-block" : "d-none"}`}>
                                                            <div className="accordionContent px-4">
                                                                <div className="border-top py-3">
                                                                    <Row className="justify-content-center align-items-start">
                                                                        <Col lg={5} md={5} sm={12} xs={12} className="mb-1">
                                                                            <span className={`font-weight-500 font-14 text-center text-black-1 break-all-word`}>{translate(props.lang, "Amount Type")}</span>
                                                                        </Col>
                                                                        <Col lg={7} md={7} sm={12} xs={12} className="mb-3">
                                                                            <span className={`font-weight-600 font-14 text-center text-black break-all-word text-capitalize`}>{item.amountType ? item.amountType.toLowerCase() : '-'}</span>
                                                                        </Col>
                                                                        <Col lg={5} md={5} sm={12} xs={12} className="mb-1">
                                                                            <span className={`font-weight-500 font-14 text-center text-black-1 break-all-word`}>{translate(props.lang, "TXID")}</span>
                                                                        </Col>
                                                                        <Col lg={7} md={7} sm={12} xs={12} className="mb-3">
                                                                            <span className={`font-weight-600 font-14 text-center text-black break-all-word lh-base`}>{item.id ? item.id : '-'}</span>
                                                                        </Col>
                                                                        <Col lg={5} md={5} sm={12} xs={12} className="mb-1">
                                                                            <span className={`font-weight-500 font-14 text-center text-black-1`} >{translate(props.lang, "Remark")}</span>
                                                                        </Col>
                                                                        <Col lg={7} md={7} sm={12} xs={12}>
                                                                            <span className={`font-weight-600 font-14 text-center text-black break-all-word lh-base`}>{item.remark ? item.remark : '-'}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}

                                            </Accordion>
                                        ) : (
                                            <div className="py-5 text-center bg-transparent">
                                                <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                <span className="d-block text-black mt-4">
                                                    {translate(props.lang, "Oops! No data found...")}
                                                </span>
                                            </div>
                                        )
                                    )}
                                    {walletTransactionList.length > 0 ? (
                                        <div className="d-block d-md-none text-center py-1">
                                            <Link to="/wallet/transaction-history">
                                                <span className="view-more-text font-weight-400 font-16 line-height-26 text-decoration-underline">{translate(props.lang, "View More")}</span>
                                            </Link>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="whiteContainer py-4 px-4">
                                <Row className="justify-content-center align-items-center px-3 text-center text-md-start">
                                    <Col lg={9} md={9} sm={12} xs={12} className="text-center text-md-start">
                                        <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Bonus History Details")}</span>
                                    </Col>
                                    <Col lg={3} md={3} sm={4} xs={4} className="text-end d-none d-md-block">
                                        <Link to="/wallet/bonus-history-details">
                                            <span className="view-more-text font-weight-400 font-14 line-height-26">{translate(props.lang, "View More")}</span>
                                        </Link>
                                    </Col>
                                </Row>
                                <div className="tableContainer d-none d-md-block">
                                    <Table responsive className="w-100 table-list mt-4 text-center position-relative">
                                        <thead>
                                            <tr>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Date & Time")}</th>
                                                <th className={`font-weight-600 font-16 text-black text-center px-1`}>{translate(props.lang, "Type Of Bonus")}</th>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Amount")} (USD)</th>
                                                <th className={`font-weight-600 font-16 text-black text-center`}>{translate(props.lang, "Remark")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loadingBonusHistory ? (
                                                <tr role="row" className="odd">
                                                    <td colSpan={4}>
                                                        <LoadingV3 theme='light' />
                                                    </td>
                                                </tr>
                                            ) : (
                                                bonusHistoryList.length > 0 ?
                                                    bonusHistoryList.map((item, i) => (
                                                        <tr role="row" className={`${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} key={i}>
                                                            <td className={`font-weight-400 font-16 text-center`}>{item.trxDate}</td>
                                                            <td className={`font-weight-400 font-16 text-center text-capitalize`}>{item.bonusType.toLowerCase().replaceAll('_', ' ')}</td>
                                                            <td className={`font-weight-400 font-16 text-center`}>{item.amount.toLocaleString('en-US', digitFormat)}</td>
                                                            <td className={`font-weight-400 font-16 text-center px-1 description lh-base`}>{item.remark.replaceAll('\n ', '\n')}</td>
                                                        </tr>
                                                    )) :
                                                    <tr className="py-5 text-center bg-transparent">
                                                        <td colSpan={4}>
                                                            <img src={require('../../../images/noData.svg').default} className="img-fluid pt-4 noDataImgSize" alt="no-data" />
                                                            <span className="d-block text-black mt-4">
                                                                {translate(props.lang, "Oops! No data found...")}
                                                            </span>
                                                        </td>
                                                    </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>

                                <div className="d-block d-md-none tableContainerAccordion mt-3">
                                    {loadingBonusHistory ? (<LoadingV3 theme="dark" />) : (
                                        bonusHistoryList.length > 0 ? (
                                            <Accordion preExpanded={[0]} className="pt-2 text-left">
                                                {bonusHistoryList.map((item, i) => (
                                                    <AccordionItem key={i} uuid={i} className={`${i === selectedBonusAccordion ? "mb-4 accordion-item" : "mb-4 accordion-item-inactive"} ${(i + 1) % 2 === 0 ? 'even' : 'odd'}`} onClick={() => setSelectedBonusAccordion(i)}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton className="px-4 py-3">
                                                                <Row className="mx-0 px-0 justify-content-center align-items-center">
                                                                    <Col lg={10} md={10} sm={10} xs={10} className="px-0">
                                                                        <div>
                                                                            <span className={`font-weight-500 font-12 text-grey-1`}>{item.trxDate}</span>
                                                                        </div>
                                                                        <div className="d-flex justify-content-start align-items-center pt-2">
                                                                            <span className={`font-weight-600 font-18 text-center text-black`}>
                                                                                {item.amount.toLocaleString('en-US', digitFormat)}
                                                                                <span className={`font-weight-600 text-center text-black text-capitalize `}> {item.walletType}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div className="pt-2">
                                                                            <span className={`font-weight-500 font-18 text-center text-capitalize text-black`}>
                                                                                {item.bonusType.toLowerCase().replaceAll('_', ' ')}
                                                                            </span>
                                                                        </div>
                                                                    </Col>
                                                                    <Col lg={2} md={2} sm={2} xs={2} className="px-0">
                                                                        <div className="text-end">
                                                                            {
                                                                                selectedBonusAccordion === i ? (<img src={require('../../../images/icon/up.svg').default} alt="up" />) : <img src={require('../../../images/icon/down.svg').default} alt="down" />
                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel className={`${selectedBonusAccordion === i ? "d-block" : "d-none"}`}>
                                                            <div className="accordionContent px-4">
                                                                <div className="border-top py-3">
                                                                    <Row className="justify-content-center align-items-start">
                                                                        <Col lg={5} md={5} sm={12} xs={12} className="mb-1">
                                                                            <span className={`font-weight-500 font-14 text-center text-black-1 break-all-word`}>{translate(props.lang, "Remark")}</span>
                                                                        </Col>
                                                                        <Col lg={7} md={7} sm={12} xs={12}>
                                                                            <span className={`font-weight-600 font-14 text-center text-black break-white-space overflow-wrap-anywhere lh-base`}>{item.remark.replaceAll('\n ', '\n')}</span>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        ) : (
                                            <div className="py-5 text-center bg-transparent">
                                                <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                <span className="d-block text-black mt-4">
                                                    {translate(props.lang, "Oops! No data found...")}
                                                </span>
                                            </div>)
                                    )}
                                    {bonusHistoryList.length > 0 ? (
                                        <div className="d-block d-md-none text-center py-1">
                                            <Link to="/wallet/bonus-history-details">
                                                <span className="view-more-text font-weight-400 font-16 line-height-26 text-decoration-underline">{translate(props.lang, "View More")}</span>
                                            </Link>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Modal className="fade bgImgModal" show={trxInfoModal} centered>
                <Modal.Header className="border-0 pt-4 pb-2">
                    <div className="d-flex w-100 align-items-center">
                        <div className="w-75"></div>
                        <div className="w-25 text-end" onClick={() => closeTrxInfoModal()} >
                            <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                            </svg>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className="border-0 px-3">
                    <div className='modalBody'>
                        <div>
                            <div className="text-center mb-5">
                                <span className="font-weight-600 font-30 primary-text">{translate(props.lang, "Details")}</span>
                            </div>
                            {trxDetail ? (
                                <Row className="mx-0 px-0">
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-600 font-16 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Date & Time")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-400 font-18 line-height-26 text-black text-capitalize break-all-word">{trxDetail.trxDateTime}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-600 font-16 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Type")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{trxDetail.trxType}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-600 font-16 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Amount Type")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-400 font-18 line-height-26 text-black break-all-word text-capitalize">{trxDetail.amountType.toLowerCase()}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-600 font-16 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Amount")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-400 font-18 line-height-26 text-black break-all-word">{Number(trxDetail.amount).toLocaleString('en-US', digitFormat)}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-600 font-16 line-height-26 text-black-1 break-all-word">{translate(props.lang, "TXID")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-400 font-18 text-black break-all-word lh-base">{trxDetail.id}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {trxDetail.remark ? (
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-start">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-600 font-16 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Remark")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <div>
                                                    <span className="font-weight-400 font-18 text-black break-all-word lh-base">{trxDetail.remark ? trxDetail.remark : '-'}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    ): null}
                                    {trxDetail.status ? (
                                    <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                        <Row className="px-0 mx-0 justify-content-start align-items-center">
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div>
                                                    <span className="font-weight-400 font-16 line-height-26 text-black-1 break-all-word">{translate(props.lang, "Status")}</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                {trxDetail.status ? (
                                                    <div className={`py-2 px-2 d-inline-block text-center status-box text-capitalize ${trxDetail.status.toUpperCase()}`}>
                                                        <span className="font-weight-400 font-14 statustext">{translate(props.lang, trxDetail.status.toUpperCase().replaceAll('_', ' '))}</span>
                                                    </div>
                                                ) : (
                                                    <span className="font-weight-600 font-18 line-height-26 text-black break-all-word">-</span>
                                                )}
                                            </Col>
                                        </Row>
                                    </Col>
                                    ) : null}
                                </Row>
                            ) : null}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(WalletOverview);