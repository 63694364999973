import { connect, useDispatch } from "react-redux";
import { Col, Row } from "reactstrap";
import QRCode from "react-qr-code";
import { useEffect, useState } from "react";
import { api } from "../../../utils/api";
import { logout } from "../../../store/actions/AuthActions";
import LoadingV3 from "../../components/Loading/LoadingV3";
import { copyText } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import "../../../css/team.scss";

const Referral = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getProfile();
    }, [props.auth.auth.token]);

    const getProfile = () => {
        let responseJson = {
            "data": {
                "username": "demo",
                "userId": "1100000777",
                "fullName": "demo",
                "email": "demo@gmail.com",
                "gender": "Female",
                "phoneNumber": "0123456789",
                "dateOfBirth": "2023-10-10",
                "joiningDate": "2023-11-01T17:37:46.36204",
                "referral": "MU568ZVL",
                "referralCode": "KA8IW7SP",
                "address": "penang msia",
                "country": null,
                "kycStatus": "APPROVED",
                "profilePicture": "",
                "mt5Username": null,
                "mt5Password": null,
                "bindCopyTradeStatus": null,
                "mt5AccountTwoUsername": null,
                "mt5AccountTwoPassword": null,
                "mt5AccountTwobindCopyTradeStatus": null
            },
            "message": "success",
            "status": "ok"
        }

        if (responseJson.data) {
            dispatch({
                type: 'UPDATE_PROFILE',
                profile: responseJson.data
            });
        }

    }

    const downloadImage = () => {
        const svg = document.getElementById("referralQR");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "referralQR";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };

        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }


    return (
        <>
            <div className='pb-5 team'>
                <div className="card-qr-bg px-2 py-4 px-lg-5 py-lg-5 h-100 mt-3">
                    {loading ? (
                        <LoadingV3 theme='dark' />
                    ) : (
                        <Row className='mx-0 px-0 align-items-stretch justify-content-center'>
                            <Col lg={12} md={12} xs={12} sm={12} className="d-block d-md-none text-center px-0">
                                <div className="w-100 text-center d-flex justify-content-center">
                                    <div className="profile-image w-25 text-center mb-3">
                                       
                                    </div>
                                </div>
                                <div className="w-100 text-center mb-4 mb-md-0">
                                    <span className="font-weight-600 font-26 text-white line-height-20 text-capitalize">{translate(props.lang, "share my QR code")}</span>
                                </div>
                            </Col>
                            <Col lg={2} md={4} sm={6} xs={6} className="mb-4 mb-md-0 h-100">
                                <div className="qr-container w-100 h-100" onClick={() => downloadImage()}>
                                    <QRCode
                                        id="referralQR"
                                        size={256}
                                        style={{ height: "auto", width: "100%" }}
                                        value={props.auth.profile.referralCode ? window.location.origin + '/register?referral=' + props.auth.profile.referralCode : ''}
                                        viewBox={`0 0 256 256`}
                                        bgColor="#ffffff"
                                    />
                                </div>
                            </Col>
                            <Col lg={10} md={8} sm={12} xs={12}>
                                <Row className="align-items-center mx-0 px-0 h-100">
                                    <Col lg={2} md={2} sm={3} xs={3} className="d-none d-md-block text-center px-0">
                                        <div className="profile-image">
                                            
                                        </div>
                                    </Col>
                                    <Col lg={10} md={10} sm={9} xs={9} className="d-none d-md-block px-0">
                                        <span className="font-weight-600 font-26 text-white line-height-20 text-capitalize">{translate(props.lang, "share my QR code")}</span>
                                    </Col>
                                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                        <div className="d-flex px-3 align-items-center justify-content-between referral-link-box py-3">
                                            <div className="text-start text-md-start">
                                                <span className="font-weight-400 font-16 text-white line-height-18">{props.auth.profile.referralCode ? window.location.origin + '/register?referral=' + props.auth.profile.referralCode : ''}</span>
                                            </div>
                                            <img src={require('../../../images/icon/copy.svg').default} className="img-fluid" onClick={() => copyText(props.auth.profile.referralCode ? window.location.origin + '/register?referral=' + props.auth.profile.referralCode : '', props.lang)} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Referral);