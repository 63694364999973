import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Col, Input, Row } from "reactstrap";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { copyText } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from "../../components/Loading/LoadingV3";
import customSelectStyles from "../SelectStyle";

const CryptoDeposit = (props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [selectedNetwork, setSelectedNetwork] = useState('');
    const [networkOption, setNetworkOption] = useState([]);

    useEffect(() => {
        getCryptoDeposit();
        return () => { };
    }, [props.auth.auth.token]);

    const getCryptoDeposit = () => {
        let responseJson = {
            "data": [
                {
                    "walletAddress": "0x3eb3bb21cd3ed65a61b22fc928118ee0f51e5584",
                    "chainType": "USDT (ERC 20)",
                    "network": "ETHEREUM (ERC 20)"
                },
                {
                    "walletAddress": "TEePcpLsa12bm1rgNmZDCobPubCG15ENgd",
                    "chainType": "USDT (TRC 20)",
                    "network": "TRON (TRC 20)"
                }
            ],
        }
        let array = []
        responseJson.data.map((item, i) => (
            array.push({ label: item.network, value: item.network, address: item.walletAddress })
        ))
        setNetworkOption(array);
    }

    const handleOptionSelected = async (e) => {
        const { value } = e;
        setSelectedNetwork(value);
    }

    const downloadImage = (index) => {
        const svg = document.getElementById("DepositQR" + index.toString());
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "DepositQR" + index.toString();
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }

    return (
        <>
            <div className='pb-5 wallet'>
                <Row className="mx-0 px-0 mt-3">
                    <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                        <div className="whiteContainer px-md-5 px-2 py-4">
                            <Row className="justify-content-center align-items-center mx-0 px-0">
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <div className="d-flex align-items-center w-100">
                                        <Link to="/wallet/deposit" className="cursor-pointer">
                                            <div className="d-flex align-items-center">
                                                <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                            </div>
                                        </Link>
                                        <div className="ms-3">
                                            <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Crypto Deposit")}</span>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2 mt-4">
                                    <div className="text-start">
                                        <div className={`groupDisabled-input py-1 mb-1`}>
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Coin")} <span className="errorTextRed">*</span></span>
                                            <div className={`input-group`}>
                                                <Input
                                                    type="select"
                                                    name="chainType"
                                                    value='1'
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    disabled={true} >
                                                    <option value="1" disabled="disabled">{translate(props.lang, "USDT | TetherUS")}</option>
                                                </Input>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                    <div className="text-start">
                                        <div className={`groupLabel-select px-md-4 px-3 mb-1`}>
                                            <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Network")} <span className="errorTextRed">*</span></span>
                                            <div className={`filter-input-group`}>
                                                <Select
                                                    options={networkOption.length > 0 ? networkOption.map((item, i) => ({ value: item.value, label: item.label })) : [{ value: '', label: translate(props.lang, "No network available") }]}
                                                    placeholder={translate(props.lang, "Select Network")}
                                                    className="input-transparent w-100 text-capitalize"
                                                    styles={customSelectStyles}
                                                    isSearchable={false}
                                                    value={
                                                        selectedNetwork ? networkOption.filter(item => item.value === selectedNetwork).map((item) => ({ value: item.value, label: item.label })) : ''
                                                    }
                                                    name="network"
                                                    onChange={(e) => handleOptionSelected(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                                    <div className="text-start depositAddressBg py-3 px-md-4 px-3">
                                        <div className={`py-1 mb-1`}>
                                            <Row className="justify-content-center w-100 align-items-center mx-0 px-0">
                                                <Col lg={12} md={12} sm={12} xs={12} className="mb-md-4 px-0">
                                                    <span className={`font-weight-500 font-13 text-grey-8 d-block mt-2`}>{translate(props.lang, "Deposit Address")}</span>
                                                </Col>
                                                {loading ? (
                                                    <>
                                                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4 px-0">
                                                            <div className="px-3 py-4 px-lg-5 py-lg-5 h-100">
                                                                <LoadingV3 theme="light" />
                                                            </div>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    selectedNetwork !== '' ?
                                                        networkOption.map((item, i) => (
                                                            selectedNetwork === item.value ?
                                                                <Col lg={12} md={12} sm={12} xs={12} className="px-0" key={i}>
                                                                    <Row className="justify-content-center w-100 align-items-center pt-4 px-0 mx-0">
                                                                        <Col lg={2} md={4} sm={12} xs={12} className="mb-3 px-0">
                                                                            <div className="qrDep-container p-md-3 p-3 text-center d-inline-block" onClick={() => downloadImage(i)} >
                                                                                <QRCode
                                                                                    id={"DepositQR" + (i).toString()}
                                                                                    size={128}
                                                                                    value={item.address}
                                                                                    viewBox={`0 0 256 256`}
                                                                                    bgColor="#ffffff"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={10} md={8} sm={12} xs={12} className="mb-3 px-0">
                                                                            <div className="text-start mt-4 px-md-3">
                                                                                <span className="font-weight-400 font-16 line-height-18 text-grey-1 d-block mb-2">{translate(props.lang, "Wallet Address")}</span>
                                                                                <div className="d-flex mt-3 align-items-center w-100">
                                                                                    <div>
                                                                                        <span className="font-weight-600 font-18 line-height-18 d-block break-all-word me-3 text-black overflow-wrap-anywhere">{item.address}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        <img src={require('../../../images/icon/copy.svg').default} className="img-fluid" alt="fa-copy" onClick={() => copyText(item.address, props.lang)} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Col>

                                                                        <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                                                            <div className="d-flex w-100 justify-content-between justify-content-md-start">
                                                                                <div>
                                                                                    <span className="font-weight-400 font-16 line-height-18 text-grey-1">{translate(props.lang, "Minimum Deposit")}</span>
                                                                                </div>
                                                                                <div className="ms-md-3">
                                                                                    <span className="font-weight-400 font-16 line-height-18 text-grey-1">0.00000001 USDT</span>
                                                                                </div>

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col> : null
                                                        ))
                                                        : (
                                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                                                                <div className="text-center">
                                                                    <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                                    <span className="d-block text-black-1 mt-4">
                                                                        {translate(props.lang, "Please Select Network")}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        ))}
                                            </Row >
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(CryptoDeposit);