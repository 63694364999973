import { connect } from "react-redux";
import { Col, Row, Input } from "reactstrap";
import "../../../css/settings.scss";
import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Select from "react-select";
import { FaSpinner } from "react-icons/fa";
import { api } from "../../../utils/api";
import swal from "sweetalert";
import { translate } from "../../../utils/translate";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import customSelectStyles from "../SelectStyle";

const WithdrawalMethod = (props) => {
    const dispatch = useDispatch();
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState([]);
    const [bankNameList, setBankNameList] = useState([]);
    const [chainOption, setChainOption] = useState([])
    const [formData, setFormData] = useState({
        bankCountry: '',
        bankName: '',
        bankHolderName: '',
        bankAccountNo: '',
        bankAddress: '',
        bankSwift: '',
        remark: '',
        walletAddress: '',
        withdrawType: '',
        chainType: '',
    });
    let errorsObj = { bankCountry: '', bankName: '', bankHolderName: '', bankAccountNo: '', bankAddress: '', bankSwift: '', remark: '', walletAddress: '', withdrawType: '', chainType: '' };
    const [errors, setErrors] = useState(errorsObj);
    const history = useHistory();

    useEffect(() => {
        resetForm();
        return () => { };
    }, [tab])

    useEffect(() => {
    }, [props.auth.auth.id_token]);

    const handleOptionSelected = async (e, type) => {
        const { value } = e;
        const newFormData = { ...formData };
        newFormData[type] = value;
        setFormData(newFormData);
    }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        let fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    function submitWithdrawalCrypto() {
        let check = true;
        const errorMsg = { ...errorsObj };

        if (!formData.walletAddress) {
            errorMsg.walletAddress = 'This field is required.';
            check = false;
        }

        if (!formData.chainType) {
            errorMsg.chainType = 'This field is required.';
            check = false;
        }

        if (!formData.remark) {
            errorMsg.remark = 'This field is required.';
            check = false;
        }
        setErrors(errorMsg);
        if (check) {
            swal(translate(props.lang, 'Success'), "", "success");
            resetForm();
            history.push('/wallet/bankInfo');
        }
    }

    const resetForm = () => {
        setFormData({
            bankCountry: '',
            bankName: '',
            bankHolderName: '',
            bankAccountNo: '',
            bankAddress: '',
            bankSwift: '',
            remark: '',
            walletAddress: '',
            withdrawType: '',
            chainType: '',
        })
    }

    return (
        <>
            <div className='pb-5 withdrawmethod'>
                <div className="whiteContainer px-md-5 px-2 py-4 my-3">
                    <Row className="justify-content-center align-items-center px-0 mx-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="d-flex align-items-center w-100">
                                <Link to="/wallet/bankInfo" className="cursor-pointer">
                                    <div className="d-flex align-items-center">
                                        <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                    </div>
                                </Link>
                                <div className="ms-3">
                                    <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Crypto Withdrawal")}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.coin ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Coin")} <span className="errorTextRed">*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={'text'}
                                            name="withdrawType"
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={"USDT | TetherUS"}
                                            autoComplete="off"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.coin)}</span>
                            </div>
                        </Col>

                        <Col lg={12} md={12} xs={12} sm={12} className="mb-2">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.walletAddress ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Wallet Address")}(USDT) <span className="errorTextRed">*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={'text'}
                                            name="walletAddress"
                                            placeholder={translate(props.lang, "Your Wallet Address")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.walletAddress}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.walletAddress)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} xs={12} sm={12} className="mb-2">
                            <div className="text-start">
                                <div className={`groupLabel-select px-md-4 px-3 mb-1  ${errors.chainType ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Network")} <span className="errorTextRed">*</span></span>
                                    <div className={`filter-input-group`}>
                                        <Select
                                            options={
                                                chainOption.length > 0 ? chainOption.map((item, i) => ({ value: item.value, label: item.key, key: i })) : [{ value: '', label: translate(props.lang, "No network available") }]
                                            }
                                            placeholder={translate(props.lang, "Select Your Network")}
                                            className="input-transparent w-100 text-capitalize px-0"
                                            styles={customSelectStyles}
                                            value={
                                                formData.chainType ? chainOption.filter(item => item.value === formData.chainType).map((item) => ({ value: item.value, label: item.key })) : ''
                                            }
                                            name="chainType"
                                            onChange={(e) => handleOptionSelected(e, 'chainType')}
                                        />
                                    </div>
                                </div>
                                <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.chainType)}</span>
                            </div>
                        </Col>

                        <Col lg={6} md={6} xs={12} sm={12} className="mb-3">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.remark ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Remark")} <span className="errorTextRed">*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type="text"
                                            name="remark"
                                            placeholder={translate(props.lang, "Your remark")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.remark}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                    </div>
                                </div>
                                <span className="font-weight-400 font-14 errorTextRed ms-4">{translate(props.lang, errors.remark)}</span>
                            </div>
                        </Col>
                        <Col lg={12} md={12} xs={12} sm={12}>
                            <button className="primary-btn px-4 py-2 text-center mx-auto w-100" disabled={loading} onClick={() => submitWithdrawalCrypto()}>
                                <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Save")}</span>
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(WithdrawalMethod);