
const customSelectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    control: (base, state) => ({
        ...base,
        background: "transparent",
        border: "none",
        boxShadow: state.isFocused ? null : null,
        color: "#0A0A0A",
        padding: "0px 0px",
        width: '100%',
        minHeight: '35px',
    }),
    valueContainer: (base) => ({
        ...base,
        paddingLeft: '0px',
        width: '100%',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem'
    }),
    menu: (base) => ({
        ...base,
        marginTop: 0,
        color: "#0A0A0A",
        padding: "0px 0px",
        overflow: 'hidden',
        // height: '10em',
        borderRadius: "1.25em",
        border: "2px solid #D5A529",
        background: "#FFFFFF",
        backdropFilter: "blur(10px)",
    }),
    menuList: (base) => ({
        ...base,
        padding: "0px 0px",
    }),
    singleValue: (base) => ({
        ...base,
        color: "#0A0A0A",
        padding: "0px 0px",
        alignItems: "center",
        display: "flex",
        fontSize: "1em",
        overflow: 'visible',
    }),
    placeholder: (base) => ({
        ...base,
        color: '#686868',
        fontWeight: '600'
    }),
};

export default customSelectStyles;