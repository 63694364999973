import { connect } from 'react-redux';
import "../../../css/loadingv2.scss";

const LoadingV2 = (props) => {
    return (
        <div id="loading2">
            <div className="preloader-container">
                <div className="preloader"></div>
                <div className="mt-3 text-center">
                    <img src={require('../../../images/logo/logo.png').default} alt="logo" className="preloader-logo" />
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const { i18n } = state;
    return {
        lang: i18n.lang
    }
};

export default connect(mapStateToProps)(LoadingV2);