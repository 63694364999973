import React from 'react'
import LoadingV3 from '../../components/Loading/LoadingV3'
import { Col, Row } from 'reactstrap'

function InfoBox(props) {
    return (
        <>
            {props.twoInrow ? (
                <div className={`${props.blackBg ? 'card-qr-bg' : props.yellowBg ? 'yellowContainer': 'whiteContainer'} h-100`}>
                    <Row className="justify-content-center mx-0 px-0 h-100">
                        <Col lg={6} md={6} sm={12} xs={12} className="border-right-box pt-3 pb-4">
                            <div className='d-flex align-items-center w-100 px-0 mx-0'>
                                <div className='w-100'>
                                    <div className='d-flex w-100 h-100 align-items-center'>
                                        {props.icon ? (
                                            <img src={props.icon} className="img-fluid" alt="personal-sales" />
                                        ) : null}
                                        <div className='ms-3 ms-md-4'>
                                            <span className="font-weight-600 font-18 line-height-26 white-container-title break-white-space">{props.boxLabel}</span>
                                        </div>
                                    </div>
                                    {props.loading ? (<LoadingV3 theme={`${props.blackBg ? 'dark' : 'light'}`}/>) : (
                                        <div className="textFieldBg w-100 align-items-center px-3 py-2 mt-3">
                                            <span className={`font-weight-500 font-24 break-white-space ${props.blackBg ? 'text-white' : 'text-black'}`}>{props.boxValue}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className='d-flex align-items-center h-100 w-100 px-0 mx-0 pt-3 pb-4'>
                                <div className='w-100'>
                                    <div className='d-flex w-100 h-100 align-items-center'>
                                        {props.icon2 ? (
                                            <img src={props.icon2} className="img-fluid" alt="group-sales" />
                                        ) : null}
                                        <div className='ms-3 ms-md-4'>
                                            <span className="font-weight-600 font-18 line-height-26 white-container-title">{props.boxLabel2}</span>
                                        </div>
                                    </div>
                                    {props.loading ? (<LoadingV3 theme={`${props.blackBg ? 'dark' : 'light'}`} />) : (
                                        <div className="textFieldBg w-100 align-items-center px-3 py-2 mt-3">
                                            <span className={`font-weight-500 font-24 break-white-space ${props.blackBg ? 'text-white' : 'text-black'}`}>{props.boxValue2}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className={`${props.blackBg ? 'card-qr-bg' : props.yellowBg ? 'yellowContainer' : 'whiteContainer'} h-100`}>
                    <Row className="justify-content-center mx-0 px-0 h-100">
                        <Col lg={12} md={12} sm={12} xs={12} className='pt-3 pb-4'>
                            <div className='d-flex w-100 align-items-center'>
                                {props.icon ? (
                                    <img src={props.icon} className={`img-fluid ${props.iconClassName}`} alt="icon-wallet" />
                                ) : null}
                                <div className='ms-3 ms-md-4'>
                                    <span className={`font-weight-600 font-18 line-height-26 break-white-space ${props.blackBg ? 'card-qr-bg-title' : 'white-container-title'}`}>{props.boxLabel}</span>
                                </div>
                            </div>
                            <div className='w-100 mt-3'>
                                {props.loading ? (
                                    <LoadingV3 theme={`${props.blackBg || props.yellowBg ? 'dark' : 'light'}`} />
                                ) : (
                                    <div className="textFieldBg w-100 align-items-center px-3 py-2 mt-3">
                                        <span className={`font-weight-500 font-24 break-white-space ${props.blackBg || props.yellowBg ? 'text-white' : 'text-black'}`}>{props.boxValue}</span>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    )
}

export default InfoBox