import swal from 'sweetalert';
import {
    formatError,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp
} from '../../services/AuthService';
import { api } from '../../utils/api';
import { translate } from '../../utils/translate';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
            .then((response) => {
                saveTokenInLocalStorage(response.data);
                runLogoutTimer(
                    dispatch,
                    response.data.expiresIn * 1000,
                    history,
                );
                dispatch(confirmedSignupAction(response.data));
                history.push('/dashboard');
            })
            .catch((error) => {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            });
    };
}

export function logout(history, path) {
    if (path) {
        if (path.includes('/login') || path.includes('/register') || path.includes('/forgot-password') || path.includes('/success') || path.includes('/test')) {
        } else {
            history.push('/login');
        }
    } else {
        history.push('/login');
    }
    localStorage.removeItem('bcg-crm');
    localStorage.removeItem('bcg-timer');
    return {
        type: LOGOUT_ACTION,
    };
}

// export function loginAction(email, password, history, rememberMe, lang) {
//     return (dispatch) => {
//         login(email, password)
//             .then((response) => {
//                 saveTokenInLocalStorage(response.data, rememberMe);
//                 if(!rememberMe){
//                     runLogoutTimer(
//                         dispatch,
//                         response.data.expiresIn * 1000,
//                         history,
//                     );
//                 }          

//                 dispatch(loginConfirmedAction(response.data));
// 				history.push('/dashboard');                
//             })
//             .catch((error) => {
// 				//console.log(error);
//                 const errorMessage = formatError(error.response.data);
//                 dispatch(loginFailedAction(errorMessage));
//             });
//     };
// }

export function loginAction(inputData, history, lang) {
    return (dispatch) => {
        let data = {
            "username": inputData.username,
            "password": inputData.password,
            "remember": inputData.rememberMe
        };

        let responseJson = {
            "data": {
                "id_token": "123eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJtYXg4ODhAZ21haWwuY29tIiwiYXV0aCI6IlJPTEVfQ1JNIiwianRpIjoiY2E5MDMxNjRjZDlhNDE4ZWE5ZGMzMjJkN2M2MGNmMmIiLCJleHAiOjE3MDI1NDgxNDR9.DjcN-NnozyPf7_9fa8oA-skrG4ceyR0sbjDbCHPm3t_0nb7GBcBzU3xtfpgPpjjy-_iGTeLnYbSkOlTL43OhqA"
            },
        }
        let responseJson1 = {
            "data": {
                "username": "demo",
                "userId": "7508474777",
                "fullName": "demo",
                "email": "demo@gmail.com",
                "gender": "Male",
                "phoneNumber": "0123456789",
                "dateOfBirth": "2023-10-10",
                "joiningDate": "2023-11-01T17:37:46.36204",
                "referral": "MU568ZVL",
                "referralCode": "KA8IW7SP",
                "address": "selangor msia",
                "country": null,
                "kycStatus": "APPROVED",
                "profilePicture": "",
                "mt5Username": null,
                "mt5Password": null,
                "bindCopyTradeStatus": null,
                "mt5AccountTwoUsername": null,
                "mt5AccountTwoPassword": null,
                "mt5AccountTwobindCopyTradeStatus": null
            }
        }
        dispatch({
            type: 'UPDATE_PROFILE',
            profile: responseJson1.data
        });
        history.push('/dashboard')

        saveTokenInLocalStorage(responseJson.data, inputData.rememberMe);
        dispatch(loadingToggleAction(true))
        dispatch(loginConfirmedAction(responseJson.data));
        
        // fetch(api.login, {
        //     method: "POST",
        //     headers: new Headers({
        //         "Content-Type": "application/json",
        //         "Accept-Language": lang.toLowerCase()
        //     }),
        //     body: JSON.stringify(data)
        // })
        //     .then(response => response.json())
        //     .then(responseJson => {
        //         if (responseJson.status === 'ok') {
        //             saveTokenInLocalStorage(responseJson.data, inputData.rememberMe);
        //             dispatch(loadingToggleAction(true))
        //             dispatch(loginConfirmedAction(responseJson.data));
        //             history.push('/dashboard');
        //         } else {
        //             swal(translate(lang, 'Error'), responseJson.message, "error");
        //             const errorMessage = translate(lang, 'Invalid email or password');
        //             dispatch(loginFailedAction(errorMessage));
        //             dispatch(loadingToggleAction(false))
        //         }
        //     }).catch(error => {
        //         console.error("error", error);
        //     });
    };
}


export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
