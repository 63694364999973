import { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/settings.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { translate } from "../../../utils/translate";

const ChangeTransactionPassword = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    let errorObj = {
        password: '',
        confirmPassword: '',
        verificationCode: '',
    }
    const [errors, setErrors] = useState(errorObj);
    const [loading, setLoading] = useState(false);
    const [loadingOtp, setLoadingOtp] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [countdownSecond, setCountdownSecond] = useState(0);
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        verificationCode: '',
    })

    useEffect(() => {
        if (countdownSecond > 0) {
            setTimeout(() => {
                setCountdownSecond(countdownSecond - 1);
            }, 1000);
        }
    }, [countdownSecond]);

    useEffect(() => {
        // getProfile();
    }, [props.auth.auth.id_token])

    // const getProfile = () => {
    //     fetch(api.userInfo, {
    //         method: "GET",
    //         headers: new Headers({
    //             "Content-Type": "application/json",
    //             "Accept-Language": props.lang,
    //             "Authorization": "Bearer " + props.auth.auth.id_token
    //         }),
    //     })
    //         .then(response => response.json())
    //         .then(responseJson => {
    //             if (responseJson.status === "ok") {
    //                 dispatch({
    //                     type: 'UPDATE_PROFILE',
    //                     profile: responseJson.data
    //                 });
    //                 if (localStorage.getItem('bcg-crm')) {
    //                     let data = JSON.parse(localStorage.getItem('bcg-crm'));
    //                     localStorage.setItem('bcg-crm', JSON.stringify(data));
    //                 }

    //             } else if (responseJson.status === 401) {
    //                 dispatch(logout(props.history, 'login'));
    //             } else {
    //                 swal(translate(props.lang, "Error"), responseJson.message, "error");
    //             }
    //         }).catch(error => {
    //             console.error("error", error);
    //         });
    // }

    const handleAddFormChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...formData };
        newFormData[fieldName] = fieldValue;
        setFormData(newFormData);
    }

    const submitForm = () => {
        let check = true;
        let errorMsg = { ...errorObj };

        if (!formData.password) {
            errorMsg.password = "This field is required.";
            check = false;
        } else {
            if (formData.password.length < 6) {
                errorMsg.password = 'Transaction password must at least 6 characters';
                check = false;
            }
        }
        if (!formData.verificationCode) {
            errorMsg.verificationCode = "This field is required.";
            check = false;
        }

        if (!formData.confirmPassword) {
            errorMsg.confirmPassword = "This field is required.";
            check = false;
        }
        if (formData.password && formData.confirmPassword) {
            if (formData.confirmPassword !== formData.password) {
                errorMsg.confirmPassword = "Confirm password need to same with new password";
                check = false;
            }
        }
        setErrors(errorMsg);

        if (check) {
            swal(translate(props.lang, "Success"), translate(props.lang, "Transaction password update successful"), "success");
            history.push('/settings/overview')
        }
    }

    const requestCode = () => {
        setCountdownSecond(60);
        swal(translate(props.lang, "Success"), translate(props.lang, "Verification code has sent to").replace('$email', props.auth.profile.email), "success");
    }

    return (
        <>
            <div className='settings pb-5'>
                <div className="whiteContainer py-4 mt-3">
                    <Row className="align-items-center justify-content-center mx-0 px-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="d-flex align-items-center w-100">
                                <Link to="/settings/overview" className="cursor-pointer">
                                    <div className="d-flex align-items-center">
                                        <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                    </div>
                                </Link>
                                <div className="ms-3">
                                    <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Forget Transaction Password")}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.password ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "New Transaction Password")} <span className='text-red'>*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={showNewPassword ? "text" : "password"}
                                            name="password"
                                            placeholder={translate(props.lang, "Your new transaction password")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.password}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowNewPassword(!showNewPassword)}>
                                            {showNewPassword ? (
                                                <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                            ) : (
                                                <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='ms-4'>
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.password)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.confirmPassword ? 'errorBorder' : ''}`}>
                                    <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Confirm New Transaction Password")} <span className='text-red'>*</span></span>
                                    <div className={`input-group`}>
                                        <Input
                                            type={showConfirmPassword ? "text" : "password"}
                                            name="confirmPassword"
                                            placeholder={translate(props.lang, "Confirm your new confirm transaction password")}
                                            className="form-control input-transparent py-1 px-md-4 px-3"
                                            value={formData.confirmPassword}
                                            autoComplete="off"
                                            onChange={e => handleAddFormChange(e)}
                                        />
                                        <div className='d-flex align-items-center justify-content-end transparent-bg pe-3 pe-md-4' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {showConfirmPassword ? (
                                                <img src={require('../../../images/icon/eyeslash.svg').default} className="img-fluid" alt="icon" />
                                            ) : (
                                                <img src={require('../../../images/icon/eye.svg').default} className="img-fluid" alt="icon" />
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='ms-4'>
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.confirmPassword)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="text-start">
                                <div className={`groupLabel-input py-1 mb-1 ${errors.verificationCode ? 'errorBorder' : ''}`}>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="w-75">
                                            <span className={`font-weight-500 px-md-4 px-3 font-13 text-grey-1 d-block mt-2`}>{translate(props.lang, "Verification Code")} <span className='text-red'>*</span></span>
                                            <div className={`input-group `}>
                                                <Input
                                                    type="text"
                                                    name="verificationCode"
                                                    placeholder={translate(props.lang, "Your Verification Code")}
                                                    className="form-control input-transparent py-1 px-md-4 px-3"
                                                    value={formData.verificationCode}
                                                    autoComplete="off"
                                                    onChange={e => handleAddFormChange(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className='pe-2 pe-md-3'>
                                            {countdownSecond === 0 ? (
                                                <button className="requestCodeBtn px-lg-4 px-2 py-3 cursor-pointer" onClick={(e) => requestCode(e)} disabled={loadingOtp}>
                                                    <span className={`font-weight-600 font-16 text-white text-nowrap`}>{loadingOtp ? (<FaSpinner className="fa-spin" />) : translate(props.lang, "Send")}</span>
                                                </button>
                                            ) : (
                                                <button className="requestCodeBtn px-lg-4 px-3 py-3 cursor-pointer" disabled>
                                                    <span className={`font-weight-600 font-16 text-white text-nowrap`}>{countdownSecond}</span>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-4">
                                    <span className="font-weight-400 font-14 errorTextRed">{translate(props.lang, errors.verificationCode)}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <button className="primary-btn px-4 py-2 text-center mx-auto w-100" onClick={() => submitForm()} disabled={loading}>
                                <span className={`font-weight-600 font-20 line-height-30 text-white`}>{loading ? <FaSpinner className="fa-spin" /> : translate(props.lang, "Update")}</span>
                            </button>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(ChangeTransactionPassword);