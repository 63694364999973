import React, { Fragment, useContext, useEffect, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import "../../../css/header.scss";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const [logoSmall, setLogoSmall] = useState(false);
  const { navigationHader, openMenuToggle, background } = useContext(
    ThemeContext
  );

  useEffect(() => {
    if (document.querySelector("#main-wrapper").classList.contains('menu-toggle')) {
      setLogoSmall(true);
    } else {
      setLogoSmall(false);
    }
  }, [toggle])

  return (
    <div className="nav-header">
      <div className="d-lg-inline-block d-none h-100 w-100">
        <Link to="/dashboard" className="brand-logo h-100 align-items-center px-3">
          <Fragment>
            {logoSmall ? (
              <img src={require('../../../images/logo/logo-icon.png').default} className="img-fluid header-logo" />
            ) : (
              <img src={require('../../../images/logo/logo-sidebar.png').default} className="img-fluid header-logo3 me-auto" />
            )}
          </Fragment>
        </Link>
      </div>
      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
