const SidebarMenu = [
    { 
        menu: "Dashboard", 
        activeImg: require('../images/menu/dashboard_active.svg').default,
        inactiveImg: require('../images/menu/dashboard_inactive.svg').default,
        pathName: '/dashboard',
        allPath: ['/dashboard'],
        display: ["all"],
        children: [],
    },
    { 
        menu: "Investment", 
        activeImg: require('../images/menu/investment_active.svg').default,
        inactiveImg: require('../images/menu/investment_inactive.svg').default,
        pathName: '/investment',
        allPath: ['/investment', '/investment/goldTrading', '/investment/transactionRecord'],
        display: ["all"],
        children: [
            {
                menu: "gold trading",
                pathName: '/investment/goldTrading',
                allPath: ['/investment/goldTrading'],
            },
            {
                menu: "Transaction Record",
                pathName: '/investment/transactionRecord',
                allPath: ['/investment/transactionRecord'],
            },
        ]
    },
    { 
        menu: "Wallet", 
        activeImg: require('../images/menu/wallet_active.svg').default,
        inactiveImg: require('../images/menu/wallet_inactive.svg').default,
        pathName: '/wallet',
        allPath: ['/wallet/overview', '/wallet/deposit', '/wallet/withdrawal', '/wallet/bankInfo', '/wallet/transaction-history', '/wallet/bonus-history-details', '/wallet/deposit/fiat', '/wallet/deposit/crypto'],
        display: ["all"],
        children: [
            {
                menu: "Wallet Overview",
                pathName: '/wallet/overview',
                allPath: ['/wallet/overview'],
            },
            {
                menu: "Deposit",
                pathName: '/wallet/deposit',
                allPath: ['/wallet/deposit', '/wallet/deposit/fiat', '/wallet/deposit/crypto'],
            },
            {
                menu: "Withdrawal",
                pathName: '/wallet/withdrawal',
                allPath: ['/wallet/withdrawal'],
            },
            {
                menu: "Bank Info",
                pathName: '/wallet/bankInfo',
                allPath: ['/wallet/bankInfo', '/wallet/bankInfo/withdraw-method'],
            },
            {
                menu: "Transaction History",
                pathName: '/wallet/transaction-history',
                allPath: ['/wallet/transaction-history'],
            },
            {
                menu: "Bonus History Details",
                pathName: '/wallet/bonus-history-details',
                allPath: ['/wallet/bonus-history-details'],
            },
        ]
    },
    { 
        menu: "Team", 
        activeImg: require('../images/menu/team_active.svg').default,
        inactiveImg: require('../images/menu/team_inactive.svg').default,
        pathName: '/team',
        allPath: ['/team/overview', '/team/team-investment', '/team/performance', '/team/referral'],
        display: ["all"],
        children: [
            {
                menu: "Team Overview",
                pathName: '/team/overview',
                allPath: [ '/team/overview'],
            },
            {
                menu: "Team Investment",
                pathName: '/team/team-investment',
                allPath: ['/team/team-investment'],
            },
            {
                menu: "Referral",
                pathName: '/team/referral',
                allPath: ['/team/referral'],
            },
        ],
    },
    { 
        menu: "Profile", 
        activeImg: require('../images/menu/profile_active.svg').default,
        inactiveImg: require('../images/menu/profile_inactive.svg').default,
        pathName: '/profile',
        allPath: ['/profile'],
        display: ["all"],
        children: [],
    },
    { 
        menu: "Settings", 
        activeImg: require('../images/menu/setting_active.svg').default,
        inactiveImg: require('../images/menu/setting_inactive.svg').default,
        pathName: '/settings',
        allPath: ['/settings/overview', '/settings/kyc', '/settings/change-password', '/settings/change-transaction-password'],
        display: ["all"],
        children: [
            {
                menu: "Security",
                pathName: '/settings/overview',
                allPath: ['/settings/overview', '/settings/change-password', '/settings/change-transaction-password'],
            },
            {
                menu: "KYC",
                pathName: '/settings/kyc',
                allPath: ['/settings/kyc'],
            },
        ],
    },
]

export default SidebarMenu;