import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import swal from "sweetalert";
import "../../../css/team.scss";
import "../../../css/tree2.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import Loadingv3 from "../../components/Loading/LoadingV3.js";
import { Col, Row } from "react-bootstrap";

const TeamInvestment = (props) => {
  const dispatch = useDispatch();
  const [parent, setParent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const treeRef = useRef(null);
  const [teamData, setTeamData] = useState([]);
  const [checkedItem, setCheckedItem] = useState([]);
  const showUnlimitedUserList = ['02eb05b4-0584-48bd-ab83-11291f153437']; // member_uuid that can see more than 3 level downline

  useEffect(() => {
    getTreeData();
  }, [props.auth.auth.token]);

  const getTreeData = () => {
    let responseJson = {
      "data": {
        "selfDetails": {
          "username": "demo",
          "email": "demo@gmail.com",
          "totalMember": 2,
          "totalActiveMember": 2,
          "totalSales": 0,
          "personalDeposit": 0,
          "walletBalance": 0.00,
          "registerTime": "2023-11-01T17:37:46.36204",
          "activeMember": "INACTIVE"
        },
        "downLineDetails": [{
          "username": "demo1",
          "email": "demo1@gmail.com",
          "totalMember": 1,
          "totalActiveMember": 1,
          "totalSales": 0,
          "personalDeposit": 0,
          "walletBalance": 0.00,
          "registerTime": "2023-11-01T17:37:46.36204",
          "activeMember": "INACTIVE"
        }]
      }
    }
    let tempTree = [{ ...responseJson.data.selfDetails, children: responseJson.data.downLineDetails }]
    setCheckedItem([responseJson.data.selfDetails.email]);
    setParent([responseJson.data.selfDetails])
    setTeamData(tempTree)
  }

  const getChildren = (member) => {
  }

  function findById(array, newArray, id) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].email === id || array[i].member_uuid === id) {
        if (array[i].children) {
          if (array[i].children.length !== newArray.length) {
            array[i].children = newArray;
            break;
          } else {
            break;
          }
        } else {
          array[i].children = newArray;
          break;
        }

      } else {
        if (array[i].children && array[i].children.length > 0) {
          findById(array[i].children, newArray, id);
        }
      }
    }
    return array;
  }

  const renderSingleView = (item) => {
    return (
      <div className="border-tree-column mt-2 w-100">
        <Row className="justify-content-start align-items-start mx-0 h-100 mx-0 px-0 py-2 py-md-3 w-100">
          <Col lg={3} md={2} xs={12} sm={3} className="px-2 first-column mb-3 mb-sm-0">
            <div className="d-flex align-items-center w-100">
              <div className="d-flex justify-content-md-center w-100">
                <div className="text-start text-md-center w-100 px-4 px-md-0">
                  <div className="">
                    <span className="font-20 font-weight-600 text-black break-all-word break-white-space">{item.username}</span>
                  </div>
                  <div className="mt-2 d-none d-sm-block">
                    <span className="font-13 line-height-18 text-grey-1 overflow-wrap-anywhere break-white-space">伞下用户</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={9} md={9} xs={12} sm={9} className="px-2 second-column">
            <div className="d-block d-sm-none px-4">
              <div className="mb-2">
                <span className="text-grey-8 font-16 font-weight-400 text-capitalize">{translate(props.lang, "Total Member")}: {item.totalMember.toLocaleString('en-US')}</span>
              </div>
              <div className="mb-2">
                <span className="text-grey-8 font-16 font-weight-400 text-capitalize">{translate(props.lang, "Total Active Member")}: {item.totalActiveMember.toLocaleString('en-US')}</span>
              </div>
              <div>
                <span className="text-grey-8 font-16 font-weight-400 text-capitalize">{translate(props.lang, "Total Sales")}: {item.totalSales.toLocaleString('en-US')}</span>
              </div>
            </div>
            <div className="d-none d-sm-block">
              <div className="d-flex align-items-center justify-content-start justify-content-lg-between w-100">
                <div className="text-center px-2 investment-column">
                  <div>
                    <span className="font-20 font-weight-600 text-black break-all-word overflow-wrap-anywhere break-white-space">{item.totalMember.toLocaleString('en-US')}</span>
                  </div>
                  <div className="mt-2 text-overflow-ellipsis">
                    <span className="font-weight-400 font-16 text-capitalize text-grey-1 break-all-word overflow-wrap-anywhere break-white-space">{translate(props.lang, "Total Member")}</span>
                  </div>
                </div>
                <div className="text-center px-2 investment-column">
                  <div>
                    <span className="font-20 font-weight-600 text-black break-all-word overflow-wrap-anywhere break-white-space">{item.totalActiveMember.toLocaleString('en-US')}</span>
                  </div>
                  <div className="mt-2 text-overflow-ellipsis">
                    <span className="font-weight-400 font-16 text-capitalize text-grey-1 break-all-word overflow-wrap-anywhere break-white-space">{translate(props.lang, "Total Active Member")}</span>
                  </div>
                </div>
                <div className="text-center px-2 investment-column">
                  <div>
                    <span className="font-20 font-weight-600 text-black break-all-word overflow-wrap-anywhere break-white-space">{Number(item.totalSales).toLocaleString('en-US', digitFormat)}</span>
                  </div>
                  <div className="mt-2 text-overflow-ellipsis">
                    <span className="font-weight-400 font-16 text-capitalize text-grey-1 break-all-word overflow-wrap-anywhere break-white-space">{translate(props.lang, "Total Sales")}</span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  const renderChildNode = (item, level) => {
    let checkLevel1 = false; //level < 1
    let checkLevel2 = false; //level < 2
    checkLevel2 = true;
    checkLevel1 = true
    if (item.totalMember > 1 && checkLevel2) {
      return (
        <ul className="pure-tree">
          {item.children && item.children.map((item, i) => (
            <li key={i} className={`child-node ${item.totalMember > 1 && checkLevel2 ? 'has-children' : ''}`}>
              {item.totalMember > 1 && checkLevel1 ? (
                <Input type="checkbox" className="noBorder" id={item.email} value={item.email} checked={checkedItem.includes(item.email)} onChange={() => onChangeCheckbox(item, level)} />
              ) : null}
              <div className="single-view" onClick={() => onChangeCheckbox(item, level)}>
                {renderSingleView(item)}
              </div>
              {renderChildNode(item, level + 1)}
            </li>
          ))}
        </ul>
      )
    } else {
      return null
    }
  }

  const onChangeCheckbox = (member, level) => {
    let formatItem = member;
    let arr = checkedItem;
    let checkLevel2 = false; //level < 2
    
    checkLevel2 = true;
    if (formatItem.totalMember > 1 && checkLevel2) {
      if (arr.includes(formatItem.email)) {
        //hide child
        let newArr = arr.filter(item => item !== formatItem.email)
        setCheckedItem(newArr);
      } else {
        //show child
        getChildren(formatItem);
        setCheckedItem(arr.concat(formatItem.email));
      }
    }
  }

  return (
    <>
      <div className="teaminvestment pb-5 px-1 px-lg-0 ms-2" id="tree">
        {loading ? (
          <Loadingv3 theme="light" />
        ) : (
          teamData.length > 0 ? (
            <ul id="compositions-list" className="pure-tree main-tree mt-3" ref={treeRef} >
              <li>
                {teamData[0].children && teamData[0].children.length > 0 ? (
                  <Input type="checkbox" className="noBorder" id={teamData[0].email} value={teamData[0].email} checked={checkedItem.includes(teamData[0].email)} onChange={() => onChangeCheckbox(teamData[0], 0)} />
                ) : null}
                <div className="single-view" onClick={() => onChangeCheckbox(teamData[0], 0)}>
                  {renderSingleView(teamData[0])}
                </div>
                {renderChildNode(teamData[0], 0)}
              </li>
            </ul>
          ) : null
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, i18n } = state;
  return {
    lang: i18n.lang,
    auth: auth,
  }
};

export default connect(mapStateToProps)(TeamInvestment);
