const ZH_CN = {
    "Welcome Back": "欢迎回来",
    "Don’t have an account yet?": "还没有账户？",
    "Sign up now": "立即注册",
    "Email Address": "电子邮箱",
    "Your Email Address": "输入您的电子邮箱",
    "Password": "密码",
    "Your Password": "输入您的密码",
    "Remember me": "记住账号",
    "Forgot Password?": "忘记密码？",
    "Login": "登录",
    "This field is required.": "此项为必填项",
    "Forgot Password": '忘记密码',
    "Already have an account?": "已有帐户？",
    "Back To Login": "回到登录",
    "Verification Code": "验证码",
    "Your Verification Code": "输入您的验证码",
    "Send": "发送",
    "Confirm Password": '确认密码',
    "Confirm Your Password": '输入您的确认密码',
    "Submit": "提交",
    "By clicking 'Submit' button, you agree to our Privacy and Terms of Use.": "单击“提交”按钮，即表示您同意我们的隐私和使用条款。",
    "Invalid Email Format": "电子邮箱格式无效",
    "Password must at least 6 characters": "密码必须至少为6个字符",
    "Confirm password need to same with password": "确认密码需要与密码相同",
    "Verification code has sent to": "验证码已发送至您的 $email 邮箱。请检查您的邮箱或垃圾邮箱",
    "Create Personal Account": "创建个人账户",
    "Login now": "现在登录",
    "Full Name": "姓名",
    "Your Full Name": "输入您的身份证姓名/护照姓名",
    "Country": "国家",
    "Select Country": "选择国家",
    "No countries available": "没国家可选",
    "Transaction Password": "交易密码",
    "Your Transaction Password": "输入您的交易密码",
    "Referral Code (Optional)": "邀请代码（可选）",
    "Create Account Now": "立即创建帐户",
    "Register Successful": "您已完成注册",
    "Success": "成功",
    "Error": "错误",
    "Your Referral Code": "输入您的邀请码",
    "Dashboard": "首页",
    "Investment": "交易",
    "Wallet": "钱包",
    "Wallet Overview": "明细",
    "Deposit": "存款",
    "Withdrawal": "提款",
    "Bank Info": "银行资讯",
    "Transaction History": "存取记录",
    "Bonus History Details": "奖金记录",
    "Team": "团队",
    "Team Overview": "概述",
    "Team Investment": "团队组织图",
    "Performance": "成长明细",
    "Referral": "邀请链接",
    "Profile": "个人资料",
    "Settings": "设置",
    "KYC": "实名认证",
    "Security": "安全",
    "Fund Your Wallet": "账户存款",
    "Change Password": '更改系统密码',
    "Forget Transaction Password": '更改系统交易密码',
    "Notification": "通知",
    "Hi,": "你好，",
    "Stop Trading": '停止交易',
    "Attention": "请注意!",
    "Are you sure you want to stop trading?": "您确定要停止交易吗？",
    "Yes, I am sure!": "是的，我确定！",
    "Cancel": "取消",
    "New password must at least 6 characters": '新密码必须至少为6个字符',
    "Confirm password need to same with new password": '确认新密码需要与新密码相同',
    "Password update successful": "密码已成功更新",
    'Back': "返回",
    "Old Password": "旧密码",
    "New Password": '新密码',
    "Your Old Password": '输入您的旧密码',
    "Your New Password": "输入您的新密码",
    "Confirm New Password": '确认新密码',
    "Confirm Your New Password": "确认您的新密码",
    "Update": "更新",
    "New Transaction Password": "新交易密码",
    "Your new transaction password": '输入您的新交易密码',
    "Confirm New Transaction Password": '确认新交易密码',
    "Confirm your new confirm transaction password": "确认您的新交易密码",
    "Transaction password update successful": '交易密码已成功更新',
    "Transaction password must at least 6 characters": '交易密码必须至少为6个字符',
    "Logout": "登出",
    "Referral Code": "邀请码",
    "Referral Link": "邀请链接",
    "Close": "关闭",
    "Copied": "复制成功",
    "Oops! No data found...": "未找到任何数据..",
    "Loading Data": "加载数据中",

    "Male": "男性",
    "Female": "女性",
    "Not Verified": "未审核",
    "Only Numbers are allowed in this field": '此项只允许输入数字',
    "Image size cannot exceed 2mb": '图片大小不能超过2MB',
    "Only allow format jpg, png or jpeg": "只允许 jpg, png, jpeg 格式",
    "Gender": "性别",
    "Select Gender": "选择性别",
    "IC / passport no.": '身份证/护照号码',
    "Your IC / Passport No.": '输入您的身份证/护照号码',
    "Date of Birth": '出生日期',
    "Your Date of Birth": '输入您的出生日期',
    "Phone Number": '手机号',
    "Your phone number": '输入您的手机号',
    "Address": "地址",
    "Your address": '输入您的地址',
    "Joining Date": '注册日期',
    "KYC Status": '实名认证状态',
    "Remark": "备注",
    "Upload IC/ Passport front": "上传身份证/护照正面",
    "Upload IC/ Passport Back": "上传身份证/护照背面",
    "Upload Photo With Holding IC/Passport": "上传本人持有身份证/护照的照片",
    "APPROVED": "已批准",
    "REVIEW": "审核中",
    "REJECTED": "已被拒",
    "PENDING": "待审核",
    "Congratulations, your account has been successfully created.": "恭喜，您的帐户已成功创建。",
    "Continue": '继续',
    "Profile successful updated": '个人资料已成功更新',
    "ID": "ID",
    "Upline": "我的上属",
    "My Referral Code": "我的邀请码",
    "Edit": "更改",
    "share my QR code": '分享我的邀请链接',
    "My Rank": "我的级别",
    "Active Group Member": "团队活跃人数",
    "Total Group Member": "团队总计人数",
    "Direct Referral Members": "直属推荐人数",
    "Personal Sales": "所属业绩",
    "Group Sales": "直属业绩",
    "Self Deposit": "个人净存",
    "Ranking": "级别",
    "Wallet Balance:": "钱包余额：",
    "Total Member": "总人数",
    "Total Active Member": "总活跃人数",
    "Total Sales": "总业绩",
    "Personal Deposit": "个人存款",
    "Type Of Bonus": "奖金类别",
    "Amount": "金额",
    "Date & Time": "日期&时间",
    "View More": "查看更多",
    "Wallet Transaction History": "存取记录",
    "Description": "描述",
    "Type": "资金类别",
    "Amount Type": "类别",
    "Transaction Hash": "交易明细",
    "TXID": 'TXID',
    "Status": "状态",
    "MT5 Balance": "MT5 余额",
    "Crypto Deposit": '加密货币存款',
    "Seamlessly Add Cryptocurrency To Your Account And Start Trading Effortlessly.": '轻松向您的账户存入加密货币，开始顺畅无阻的交易。',
    "Coin": "币种 ",
    "Network": "网络",
    "Select Network": "选择网络",
    'Deposit Address': "充值地址",
    "Wallet Address": "钱包地址",
    "Minimum Deposit": "最少充币数量",
    "Please Select Network": "请选择网络",
    "Total Invested": "个人投资",
    "Total Profit/Loss": "交易收益",
    "Investment Details": "交易详情",
    "Order ID": "订单号",
    "Price": "价格",
    "Trading Pair": "产品类别",
    "Profit/Loss %": "盈利/亏损",
    "Lot Size": "手数",
    "Price Enter": "入场价格",
    "Price Close": "出场价格",
    "Announcement": "公告",
    "No Announcement For Now": "暂无公告",
    "Group Sales Performance": "团队业绩",
    "Sales": "业绩",
    "Read More": "了解更多",
    "My MT5 Info": "我的MT5资料",
    "Wallet Total Balance": "钱包余额",
    "Trading Profit": "业绩奖励",
    "Total Investment": "MT5余额",
    "Total Bonus": "奖金收益",
    "My QR Referral Code": "邀请链接",
    "Transfer": "转移",
    "From": "从",
    "To": "至",
    "Transfer Amount": "转移数额",
    "Balance Available:": "可用余额：",
    "Your Transfer Amount": '输入您的转移数额',
    "Successful": "成功",
    "Unsuccessful": "未成功",
    "Insufficient balance": "余额不足",
    "Add New Withdrawal Method": "添加新的提款方式",
    "Withdrawal Method Available": "可用提款方式",
    "Invalid email or password": "无效的电子邮箱或密码",
    "Fiat Withdrawal": '法币提款',
    "Crypto Withdrawal": "加密货币提款",
    "Bank Country": "银行国家",
    "Bank Account Holder Name": "银行账户持有人姓名",
    "Bank Name": "银行名称",
    "Bank Account Number": "银行帐号",
    "Bank Address": "银行地址",
    "Bank Swift Code": "银行Swift码",
    "Select Your Bank country": "选择您的银行所在国家/地区",
    "Select Your Bank Name": "选择您的银行名称",
    "Your Bank Account Holder Name": "输入您的银行账户姓名",
    "Your Bank Account Number": "输入您的银行帐号",
    "Your Bank Address": "输入您的银行地址",
    "Your Bank Swift Code": "输入您的银行Swift码",
    "Your Bank Name": "输入您的银行名称",
    "Your remark": "输入您的备注",
    "Your Wallet Address": "输入您的钱包地址",
    "Select Your Network": "选择您的网络",
    "Save": "保存",
    "Select Bank Name": "选择银行名称",
    "No network available": "没网络可选",
    "Withdrawal From Wallet": "从钱包提款",
    "Email Verification Code": "邮箱验证码 ",
    "Your Email Verification Code": "输入您的邮箱验证码",
    "Withdraw Amount": "提款数额",
    "Your Withdraw Amount": "输入您的提款数额",
    "Select Type": "选择类型",
    "No option available": '没有选项可选',
    "Select bank transfer": '选择银行转账',
    "Receive Amount": "到款数额",
    "Network Fee:": "网络费用 ：",
    "Exchange Rate:": "汇率 ：",
    "Charge Fee:": "收费 ：",
    "Merchant": "商家",
    "Select Merchant": "选择商家",
    "Order Number": "用户订单号",
    "Your Order Number": "输入用户订单号",
    "Payment Currency": "支付货币",
    "Select Payment Currency": "选择支付货币",
    "No currency available": "没货币可选",
    "Deposit Amount": "存款数额",
    "Your Deposit Amount": "输入您的存款数额",
    "Minimum to maximum transaction is 1000-4000 USD": "最低至最高交易金额为1,000-4,000 USD",
    "Remove Withdrawal Method": "删除提款方式",
    "Withdrawal Type": "提款类型",
    "fiat withdraw": '法币提款',
    "crypto withdraw": "加密货币提款",
    "Delete": "删除",
    "Balance Available": "可用余额",
    "Amount must greater than 0.": "数额必须大于0。",
    "Remark:": "备注：",
    "Amount must only within 1000 - 4000": "数额必须在 1,000 - 4,000 之间",
    "View Details": "查看详情",
    "Details": '详情',
    "Investment Package": "投资配套",
    "Select Your Package": "选择您的配套",
    "Monthly Return": "月化收益",
    "Minimum investment amount is": '最低投资额为',
    "Maximum investment amount is": "最高投资金额为",
    "Purchase Package Details": "仓位详情",
    "Purchase Datetime": "购买日期时间",
    "Cumulative Investment Days": '累计投资天数',
    "Investment Allocation": "投资仓位",
    "Surrender": "终止",
    "Successfully surrender": "成功终止配套",
    "active": "激活",
    "Account Type": "到账类别",
    "Select Account Type": "选择到账类别",
    "No account type available": "没类别可选",
    "Days": "天",
    "Processing Fee": "手续费",
    "Invalid withdraw amount": '提款数额无效',
    "Withdraw": "提现",
    "Account": "账号",
    "Payment Amount": '付款数额',
    "Your Payment Amount": '您的付款数额',
    "Convert": "兑换",
    "Exchange Rate": "汇率 : ",
    "Your IP does not support the operation": "你的所在IP不支持操作",
    "to": "至",
    "inactive": "不活跃",
    "Account withdrawal type": "到账类别",
    "Select withdrawal type": "选择到账类别",
    "MT5 Login ID": "MT5登录ID",
    "completed": "完成",
    "Investment Amount": "投资金额",
    "Level Bonus": "平级奖励",
    "Global performance distribution rewards": "全球业绩\n分配奖励",
    "gold trading": "黄金交易",
    "Transaction Record": "交易记录",
    "hierarchical bonus": "级差奖励",
    "Buy": "购买",
    "Sell": "抛售",
    "Enter price": '输入价格',
    "Enter amount": '输入数额',
    "Transaction Type": "交易类型",
    "Total": "总数",
    "Time": "时间",
    "1 Day": "1日",
    "1 Week": "1个星期",
    "1 Month": "1个月",
    "Amount of Gold": "黄金数额",
    "Wallet Balance": "钱包数额",
    "Gold Trading": "黄金交易买卖",
    "Real time gold price": "实时金价",
    "Historical gold price": "历史金价",
    "Order": "订单",
    "Order record": '订单记录',
    "Order has been cancelled": "订单已取消",
    "Are you sure you want to cancel this order?": "确定要取消此订单吗？",
    "Confirm": "确定",
    "Date": "日期",
    "Side": "边",
    "Pair": "交易对",
    "Trade Type": "交易类型",
    "More": "更多",
    "Order Book": '订单簿',
    "Buy Order": '买单',
    "Sell Order": "卖单",
    "LIMIT": "限价",
    "MARKET": "市价",
    "Spot": "现货",
    "Invalid amount": "金额无效",
    "Invalid price": "价格无效",
    "24h Change": "24小时变化",
    "24h High": "24小时最高价",
    "24h Low": "24小时最低价",
    "24h Volume": "24小时成交量",
    "MAKER": "挂单者",
    "TAKER": "吃单者",
    "Open Orders": "未完成订单",
    "Order History": "订单历史",
    "Trade History": "交易历史",
    "Role": '角色',
    "Filled": "盈满",
    "Fee": "费用",
    "Received": "接收",
    "Datetime": "日期时间",
}

export default ZH_CN;