import React, { useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Row, Table } from 'reactstrap';
import { translate } from '../../../utils/translate';
import LoadingV3 from '../../components/Loading/LoadingV3';
import ReactPaginate from 'react-paginate';

const dummyBuy = [
    { price: 0.20, amount: 22667.99, total: 4533.59, sum: 22667.99 },
    { price: 0.19, amount: 1062.63, total: 201.89, sum: 23730.62 },
    { price: 0.18, amount: 2242.83, total: 403.70, sum: 25973.45 },
    { price: 0.17, amount: 1176.47, total: 199.99, sum: 27149.92 },
    { price: 0.16, amount: 1250.25, total: 200.04, sum: 28400.17 },
]

const dummySell = [
    { price: 0.41, amount: 1209.00, total: 495.69, sum: 9939.63 },
    { price: 0.40, amount: 4.78, total: 1.91, sum: 9443.94 },
    { price: 0.39, amount: 1306.92, total: 509.70, sum: 9442.03 },
    { price: 0.38, amount: 1630.75, total: 619.68, sum: 8932.33 },
    { price: 0.37, amount: 7.00, total: 2.59, sum: 8312.64 }
]

function OrderBook(props) {
    const [loadingBuy, setLoadingBuy] = useState(false);
    const [resultBuy, setResultBuy] = useState(dummyBuy);
    const [loadingSell, setLoadingSell] = useState(false);
    const [resultSell, setResultSell] = useState(dummySell);
    const [info, setInfo] = useState({
        marketPrice: 0.20500000000000000000,
        maximumBuyQuantity: 38574.61000000000000000000,
        maximumSellQuantity: 18405.03240000000000000000,
    })
    const [pageCountBuy, setPageCountBuy] = useState(5);
    const [pageNumberBuy, setPageNumberBuy] = useState(1);
    const [pageCountSell, setPageCountSell] = useState(5);
    const [pageNumberSell, setPageNumberSell] = useState(1);
    const changePageBuy = (event) => {
        setPageNumberBuy(event.selected + 1);
    }

    const changePageSell = (event) => {
        setPageNumberSell(event.selected + 1);
    }

    return (
        <>
            <div className='investment pb-5'>
                <div className="whiteContainer py-4 mt-3">
                    <Row className="align-items-center justify-content-center mx-0 px-0">
                        <Col lg={12} md={12} sm={12} xs={12} className="mb-4">
                            <div className="d-flex align-items-center w-100">
                                <Link to="/investment/goldTrading" className="cursor-pointer">
                                    <div className="d-flex align-items-center">
                                        <img src={require('../../../images/icon/backBtn.svg').default} className="img-fluid" alt="back" />
                                    </div>
                                </Link>
                                <div className="ms-3">
                                    <span className="font-weight-600 font-20 line-height-26 text-black">{translate(props.lang, "Order Book")}</span>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className="border">
                                <div className="px-3 py-4">
                                    <span className="font-weight-500 font-18 letter-spacing--001 page-title-color">{translate(props.lang, "Buy Order")}</span>
                                </div>
                                <Table responsive className="w-100 mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Price")}(USDT)</span></th>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Amount")}(GOLD)</span></th>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Total")}(USDT)</span></th>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Sum")}(GOLD)</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loadingBuy ? (
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoadingV3 />
                                                </td>
                                            </tr>
                                        ) : (
                                            resultBuy.length > 0 ? (
                                                resultBuy.map((item, i) => (
                                                    <tr key={i} style={{ backgroundImage: `linear-gradient(90deg, white ${100 - (item.amount / info.maximumBuyQuantity * 100)}%, rgba(38, 198, 63, 0.2) 0%)` }}>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.total.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.sum.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="py-5 text-center bg-transparent">
                                                            <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                            <span className="d-block text-black mt-4">
                                                                {translate(props.lang, "Oops! No data found...")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}

                                    </tbody>
                                </Table>
                                {!loadingBuy && resultBuy.length > 0 ? (
                                    <div className='py-3'>
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCountBuy}
                                            onPageChange={changePageBuy}
                                            forcePage={pageNumberBuy - 1}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-black"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className="border h-100">
                                <div className="px-3 py-4">
                                    <span className="font-weight-500 font-18 letter-spacing--001 page-title-color">{translate(props.lang, "Sell Order")}</span>
                                </div>
                                <Table responsive className="w-100 mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Price")}(USDT)</span></th>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Amount")}(GOLD)</span></th>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Total")}(USDT)</span></th>
                                            <th className="text-center"><span className="font-weight-500 font-12 text-grey-3">{translate(props.lang, "Sum")}(GOLD)</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {loadingSell ? (
                                            <tr>
                                                <td colSpan={4}>
                                                    <LoadingV3 />
                                                </td>
                                            </tr>
                                        ) : (
                                            resultSell.length > 0 ? (
                                                resultSell.map((item, i) => (
                                                    <tr key={i} style={{ backgroundImage: `linear-gradient(90deg, white ${100 - (item.amount / info.maximumSellQuantity * 100)}%, rgba(193, 8, 8, 0.2) 0%)` }}>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.price.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.total.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                        <td className="text-center border-bottom-none"><span className="font-weight-500 font-12 text-grey-2">{item.sum.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span></td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="py-5 text-center bg-transparent">
                                                            <img src={require('../../../images/noData.svg').default} className="noDataImgSize pt-4" alt="no-data" />
                                                            <span className="d-block text-black mt-4">
                                                                {translate(props.lang, "Oops! No data found...")}
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}

                                    </tbody>
                                </Table>
                                {!loadingSell && resultSell.length > 0 ? (
                                    <div className='py-3'>
                                        <ReactPaginate
                                            previousLabel={"<"}
                                            nextLabel={">"}
                                            pageCount={pageCountSell}
                                            onPageChange={changePageSell}
                                            forcePage={pageNumberSell - 1}
                                            containerClassName={"pagination font-weight-400 font-16 text-uppercase text-grey-2"}
                                            previousLinkClassName={"pagination__previouslink pagination-arrow text-white"}
                                            nextLinkClassName={"pagination__nextlink pagination-arrow text-white"}
                                            disabledClassName={"pagination__link--disabled text-muted"}
                                            activeClassName={"pagination__link--active text-black"}
                                            marginPagesDisplayed={window.innerWidth > 667 ? 3 : 1}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(OrderBook);