import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import "../../../css/settings.scss";
import { Link } from "react-router-dom";
import { translate } from "../../../utils/translate";
import swal from "sweetalert";
import { api } from "../../../utils/api";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";

const SettingOverview = (props) => {
    const dispatch = useDispatch();

    function stopTrading(product) {
        swal({
            title: translate(props.lang, "Attention"),
            text: translate(props.lang, "Are you sure you want to stop trading?"),
            icon: "warning",
            buttons: [
                translate(props.lang, 'Cancel'),
                translate(props.lang, 'Yes, I am sure!')
            ],
            dangerMode: true,
        }).then(function (isConfirm) {
            if (isConfirm) {
                confirmStopTrading(product);
            } 
        })
    }

    const confirmStopTrading = (product) => {
        swal(translate(props.lang, 'Success'), "交易已终止", "success");
        
    }


    return (
        <>
            <div className='settings pb-5'>
                <Row className="px-0 mx-0 mt-3">
                    <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                        <div className="whiteContainer">
                            <Link to="/settings/change-password">
                                <div className="card-content px-3 px-lg-4 py-4">
                                    <Row className="align-items-center mx-0 px-0">
                                        <Col lg={2} md={3} sm={2} xs={2} className="px-0">
                                            <img src={require('../../../images/settings/changePassword.svg').default} alt="password" className="img-fluid" />
                                        </Col>
                                        <Col lg={8} md={7} sm={9} xs={9}>
                                            <span className="font-weight-500 font-20 white-container-title break-white-space line-height-110percent">{translate(props.lang, "Password")}</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                            <div className="d-flex w-100 h-100 justify-content-end">
                                                <div className="arrow-icon-container d-inline-block text-end w-100 h-100">
                                                    <img src={require('../../../images/icon/blue-arrow.svg').default} className="img-fluid" alt="arrow" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Link>
                        </div>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                        <div className="whiteContainer">
                            <Link to="/settings/change-transaction-password">
                                <div className="card-content px-3 px-lg-4 py-4">
                                    <Row className="align-items-center mx-0 px-0">
                                        <Col lg={2} md={3} sm={2} xs={2} className="px-0">
                                            <img src={require('../../../images/settings/changeTransactionPassword.svg').default} alt="trx-password" className="img-fluid" />
                                        </Col>
                                        <Col lg={8} md={7} sm={9} xs={9}>
                                            <span className="font-weight-500 font-20 white-container-title break-white-space line-height-110percent">{translate(props.lang, "Transaction Password")}</span>
                                        </Col>
                                        <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                            <div className="d-flex w-100 h-100 justify-content-end">
                                                <div className="arrow-icon-container d-inline-block text-end w-100 h-100">
                                                    <img src={require('../../../images/icon/blue-arrow.svg').default} className="img-fluid" alt="arrow" />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Link>
                        </div>
                    </Col>
                    <Col lg={6} md={6} xs={12} sm={12} className="mb-4">
                        <div className="whiteContainer" onClick={() => stopTrading(1)}>
                            <div className="card-content px-3 px-lg-4 py-4">
                                <Row className="align-items-center mx-0 px-0">
                                    <Col lg={2} md={3} sm={2} xs={2} className="px-0">
                                        <img src={require('../../../images/settings/stopTrading.svg').default} alt="stop-trading" className="img-fluid" />
                                    </Col>
                                    <Col lg={8} md={7} sm={9} xs={9}>
                                        <span className="font-weight-500 font-20 white-container-title break-white-xs-space lh-base">{translate(props.lang, "Stop Trading")}</span>
                                    </Col>
                                    <Col lg={2} md={2} sm={1} xs={1} className="px-0">
                                        <div className="d-flex w-100 h-100 justify-content-end">
                                            <div className="arrow-icon-container d-inline-block text-end w-100 h-100">
                                                <img src={require('../../../images/icon/blue-arrow.svg').default} className="img-fluid" alt="arrow" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(SettingOverview);