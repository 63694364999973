import moment from "moment";
import { useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import QRCode from "react-qr-code";
import { connect, useDispatch } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Col, Row } from "reactstrap";
import swal from "sweetalert";
import "../../../css/dashboard.scss";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { copyText, digitFormat } from "../../../utils/function";
import { translate } from "../../../utils/translate";
import LoadingV3 from '../../components/Loading/LoadingV3';
import InfoBox from "../Common/InfoBox";


const Dashboard = (props) => {
    const [dashboardInfo, setDashboardInfo] = useState({
        username: "",
        profilePicture: "",
        referralCode: "KA8IW7SP",
        referralLink: "",
        investmentBalance: '8888.88',
        profitSharing: "888.88",
        walletBalance: "888.88",
        teamBonus: "888.88",
        announcement: [],
        ranking: [],
        groupSalesPerformance: [],
    });
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [announcementModal, setAnnouncementModal] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [tab, setTab] = useState(1);

    useEffect(() => {
        // getDashboard();
    }, [props.auth.auth.id_token, props.lang]);

    useEffect(() => {
        if (refresh) {
            // getDashboard();
            setRefresh(false);
        }
    }, [refresh]);

    const getDashboard = () => {
        setLoadingDashboard(true);
        fetch(api.getDashboardInfo, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept-Language": props.lang,
                "Authorization": "Bearer " + props.auth.auth.id_token
            }),
        })
            .then(response => response.json())
            .then(responseJson => {
                setTimeout(() => {
                    setLoadingDashboard(false);
                }, 1000);

                if (responseJson.status === "ok") {
                    setDashboardInfo(responseJson.data);

                } else if (responseJson.status === 401) {
                    dispatch(logout(props.history, 'login'));
                } else {
                    swal(translate(props.lang, "Error"), responseJson.message, "error");
                }
            }).catch(error => {
                console.error("error", error);
            });
    }

    const downloadImage = () => {
        const svg = document.getElementById("DepositDashboardQR");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = function () {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");

            const downloadLink = document.createElement("a");
            downloadLink.download = "DepositDashboardQR";
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };

        img.src = "data:image/svg+xml;base64," + btoa(svgData);
    }

    const getLinearGradient = (startColor, endColor) => {
        const ctx = document.createElement('canvas').getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, startColor);
        gradient.addColorStop(1, endColor);
        return gradient;
    };

    function openAnnouncementModal(item) {
        setSelectedAnnouncement(item);
        setAnnouncementModal(true);
    }

    const formatNumber = (value) => {
        if (value.includes('USD')) {
            let newVal = value.split(' ');
            return "USD " + Number(newVal[1]).toLocaleString('en-US', digitFormat);
        } else {
            return "USD " + value.toLocaleString('en-US', digitFormat);
        }
    }

    return (
        <>
            <div className='dashboard pb-5'>
                <Row className="justify-content-center px-0 mx-0 mt-3">
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <InfoBox
                            icon={require('../../../images/dashboard/walletBalanceIcon.svg').default}
                            boxLabel={translate(props.lang, "Wallet Total Balance")}
                            boxValue={formatNumber(dashboardInfo.walletBalance)}
                            loading={loadingDashboard}
                            yellowBg={true}
                        />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <InfoBox
                            icon={require('../../../images/dashboard/totalInvestmentIcon.svg').default}
                            boxLabel={translate(props.lang, "Investment Amount")}
                            boxValue={formatNumber(dashboardInfo.investmentBalance)}
                            loading={loadingDashboard}
                        />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <InfoBox
                            icon={require('../../../images/dashboard/profitSharingIcon.svg').default}
                            boxLabel={translate(props.lang, "Trading Profit")}
                            boxValue={formatNumber(dashboardInfo.profitSharing)}
                            loading={loadingDashboard}
                        />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <InfoBox
                            icon={require('../../../images/dashboard/teamBonusIcon.svg').default}
                            boxLabel={translate(props.lang, "Total Bonus")}
                            boxValue={formatNumber(dashboardInfo.teamBonus)}
                            loading={loadingDashboard}
                        />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <InfoBox
                            icon={require('../../../images/dashboard/globalPerformance.svg').default}
                            boxLabel={translate(props.lang, "Global performance distribution rewards")}
                            boxValue={formatNumber(dashboardInfo.teamBonus)}
                            loading={loadingDashboard}
                        />
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-4">
                        <InfoBox
                            icon={require('../../../images/dashboard/levelBonus.svg').default}
                            boxLabel={translate(props.lang, "hierarchical bonus")}
                            boxValue={formatNumber(dashboardInfo.teamBonus)}
                            loading={loadingDashboard}
                        />
                    </Col>
                </Row>
                <Row className="justify-content-center px-0 mx-0">
                    <Col lg={8} md={8} xs={12} sm={12} className="mb-4">
                        <div className="py-3 px-3 whiteContainer">
                            <Row className='align-items-between px-0 mx-0 h-100'>
                                <Col lg={12} md={12} xs={12} sm={12}>
                                    <span className="font-weight-600 font-22 line-height-26 page-title-color">{translate(props.lang, "Announcement")}</span>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mt-3 px-0">
                                    {/* {loadingDashboard ? (
                                            <LoadingV3 theme='light' />
                                        ) : (
                                            <div className="announcementScrollSection">
                                                {dashboardInfo.announcement.length > 0 ? dashboardInfo.announcement.map((item, i) => (
                                                    <div key={i} className={`py-3 ${i !== dashboardInfo.announcement.length - 1 ? 'border-bottom-grey' : ''}`}>
                                                        <Row className='mx-0 px-0'>
                                                            <Col lg={4} md={12} sm={12} xs={12} className="ps-0 mb-2">
                                                                <div className="d-flex flex-row flex-lg-column">
                                                                    <span className="text-grey-1 font-14 font-weight-500 d-block me-2">{moment(item.trxDate).format('DD/MM/YYYY')}</span>
                                                                    <span className="text-grey-1 font-14 font-weight-500 d-block">{moment(item.trxDate).format('HH:mm:ss')}</span>
                                                                </div>
                                                            </Col>
                                                            <Col lg={8} md={12} sm={12} xs={12} className="pe-0 ps-0 ps-lg-2">
                                                                <span className="font-weight-600 font-16 text-black d-block mb-3">{props.lang === "ZH" ? item.titleZh : item.title}</span>
                                                                <span className="font-weight-400 font-13 text-black">{props.lang === "ZH" ? item.descriptionZh.substring(0, 120) : item.descriptionEn.substring(0, 120)}...</span>
                                                                <div className='mt-2'>
                                                                    <span className='font-weight-500 font-15 read-more-text cursor-pointer' onClick={() => openAnnouncementModal(item)}>{translate(props.lang, "Read More")}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )) :
                                                    <div className='h-100 w-100 d-flex justify-content-center align-items-center py-3 card-white'>
                                                        <div className='text-start'>
                                                            <span className='d-block mt-3 text-black'>{translate(props.lang, "No Announcement For Now")}</span>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        )} */}
                                    <div className={`py-3 border-bottom-grey`}>
                                        <Row className='mx-0 px-0'>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="d-flex flex-row flex-lg-column w-100">
                                                    <span className="text-grey-1 font-14 font-weight-500">21/06/2023, 00:00:00</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <span className="font-weight-600 font-16 text-black d-block mb-3">欢迎加入Blockchain Gold!</span>
                                                <span className="font-weight-400 font-13 text-black">我们的策略合作伙伴.......</span>
                                                <div className='mt-2'>
                                                    <span className='font-weight-500 font-15 read-more-text cursor-pointer' onClick={() => openAnnouncementModal()}>{translate(props.lang, "Read More")}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className={`py-3`}>
                                        <Row className='mx-0 px-0'>
                                            <Col lg={12} md={12} sm={12} xs={12} className="mb-2">
                                                <div className="d-flex flex-row flex-lg-column w-100">
                                                    <span className="text-grey-1 font-14 font-weight-500">21/06/2023, 00:00:00</span>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} sm={12} xs={12}>
                                                <span className="font-weight-600 font-16 text-black d-block mb-3">欢迎加入Blockchain Gold!</span>
                                                <span className="font-weight-400 font-13 text-black">我们的策略合作伙伴.......</span>
                                                <div className='mt-2'>
                                                    <span className='font-weight-500 font-15 read-more-text cursor-pointer' onClick={() => openAnnouncementModal()}>{translate(props.lang, "Read More")}</span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={12} xs={12} className="mb-4 h-100">
                        <div className="text-center py-4 px-3 card-qr-bg" >
                            <div className="d-flex align-items-center h-100 w-100 justify-content-center">
                                <Row className='align-items-center justify-content-center px-0 mx-0 h-100'>
                                    {loadingDashboard ? (
                                        <Col lg={6} md={6} xs={12} sm={12} className="text-center">
                                            <div className="d-flex justify-content-center w-100">
                                                <LoadingV3 theme='dark' />
                                            </div>
                                        </Col>
                                    ) : (
                                        <>
                                            <Col lg={12} md={12} xs={12} sm={12}>
                                                <div className="d-flex align-items-center justify-content-center w-100 h-100">
                                                    <div onClick={() => downloadImage('../../../images/dashboard/DepositDashboardQR.png')}>
                                                        <div className="qr-container" >
                                                            <QRCode
                                                                id="DepositDashboardQR"
                                                                size={256}
                                                                value={dashboardInfo ? window.location.origin + '/register?referral=' + dashboardInfo.referralCode : ''}
                                                                viewBox={`0 0 128 128`}
                                                                style={{ width: '80%', height: 'undefined', aspectRatio: 1 }}
                                                                bgColor="#000000"
                                                                fgColor="#ffffff"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={12} md={12} xs={12} sm={12}>
                                                <Row className="px-0 mx-0 align-items-center h-100">
                                                    <Col lg={10} md={10} sm={10} xs={10}>
                                                        <div className='text-start'>
                                                            <span className="text-white font-20 font-weight-600 break-all-word lh-base">{translate(props.lang, "My QR Referral Code")}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2} md={2} sm={2} xs={2}>
                                                        <div className="w-100 cursor-pointer" onClick={() => copyText(dashboardInfo.referralCode ? window.location.origin + '/register?referral=' + dashboardInfo.referralCode : '', props.lang)}>
                                                            <img src={require('../../../images/icon/copy.svg').default} className="img-fluid" alt="fa-copy" />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={12} md={12} xs={12} sm={12} className="mt-3">
                                                <Row className="px-0 mx-0 align-items-center h-100">
                                                    <Col lg={12} md={12} sm={12} xs={12}>
                                                        <div className='text-start'>
                                                            <span className="text-white font-weight-400 font-13 break-all-word lh-base">{dashboardInfo.referralCode ? window.location.origin + '/register?referral=' + dashboardInfo.referralCode : ''}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                    )}
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal className="fade modalColorBg" show={announcementModal} centered>
                    <Modal.Header className="border-0 pt-4 pb-2">
                        <div className="modalHeader d-flex w-100 align-items-center">
                            <div className="w-75">
                            </div>
                            <div className="w-25 text-end" onClick={() => setAnnouncementModal(false)} >
                                <svg width="20" height="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M26 3L3 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                    <path d="M3.00928 3L26.0093 26" stroke="#777777" strokeWidth="5" strokeLinecap="round" />
                                </svg>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="border-0 px-4">
                        <div className='modalBody'>
                            <Row className='justify-content-center align-items-center mx-0 px-0'>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                    <span className="font-weight-500 font-18 lh-base text-black d-block">{translate(props.lang, "Announcement")}</span>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                    <span className="font-weight-600 font-20 lh-base text-black break-all-word d-block">
                                        {/* {selectedAnnouncement ? props.lang === "ZH" ? selectedAnnouncement.titleZh : selectedAnnouncement.title : ''} */}
                                        欢迎加入Blockchain Gold!
                                    </span>
                                    <span className="font-weight-600 font-14 lh-base text-black break-all-word d-block">
                                        {/* {selectedAnnouncement ? moment(selectedAnnouncement.trxDate).format('DD/MM/YYYY, HH:mm:ss') : ''} */}
                                        12/06/2023, 00:00:00
                                    </span>
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                    <hr className='announcementPopupLine my-3' />
                                </Col>
                                <Col lg={12} md={12} xs={12} sm={12} className="mb-3">
                                    {/* {selectedAnnouncement ? (
                                    <span className="font-weight-400 font-18 line-height-30 text-black d-block break-all-word">
                                        {props.lang === "ZH" ? selectedAnnouncement.descriptionZh : selectedAnnouncement.descriptionEn}
                                    </span>
                                ) : null} */}
                                    <span className="font-weight-400 font-18 line-height-30 text-black d-block break-all-word">我们的策略合作伙伴</span>
                                </Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { auth, i18n } = state;
    return {
        lang: i18n.lang,
        auth: auth,
    }
}

export default connect(mapStateToProps)(Dashboard);