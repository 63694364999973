import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useLocation, withRouter } from "react-router-dom";
import { Col, Row } from "reactstrap";
import swal from "sweetalert";
import { logout } from "../../../store/actions/AuthActions";
import { api } from "../../../utils/api";
import { copyText } from "../../../utils/function";
import routes from "../../../utils/route";
import { translate } from "../../../utils/translate";

const lang = [
	{ name: 'English', code: 'EN' },
	{ name: '中文 (简体)', code: 'ZH' },
]

const Header = (props) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [pageTitle, setPageTitle] = useState("Dashboard");

	useEffect(() => {
		let searchMenu = routes.filter(item => item.url === location.pathname);
		if (searchMenu.length > 0) {
			if (searchMenu[0].name === 'Dashboard') {
				if (props.auth.profile.fullName) {
					setPageTitle(translate(props.lang, 'Hi,') + " " + props.auth.profile.fullName);
				} else {
					setPageTitle(translate(props.lang, 'Hi,'));
				}

			} else {
				setPageTitle(translate(props.lang, searchMenu[0].name));
			}
		}
	}, [location, props.lang, props.auth.profile.fullName]);

	const changeLanguage = (language) => {
		dispatch({
			type: 'UPDATE_LANGUAGE',
			lang: language
		});
		localStorage.setItem('bcg-lang', language);
		document.getElementById('langOpenDropdown-lg').checked = false;
	}

	const onLogout = () => {
		fetch(api.logout, {
			method: "POST",
			headers: new Headers({
				"Content-Type": "application/json",
				"Accept-Language": props.lang.toLowerCase(),
				"Authorization": "Bearer " + props.auth.auth.id_token
			}),
		})
			.then(response => response.json())
			.then(responseJson => {
				if (responseJson.status === "ok") {
					dispatch(logout(props.history, 'login'));
				} else if (responseJson.status === 401) {
					dispatch(logout(props.history, 'login'));
				} else {
					swal(translate(props.lang, "Error"), responseJson.message, "error");
				}
			}).catch(error => {
				console.error("error", error);
			});
	}

	useEffect(() => {
		var checkbox = document.querySelector('#openDropdownProfile-lg');
		var icon = document.querySelector('#tooltip-parent4');
		var listener = function (e) {
			if (e.target !== checkbox && e.target !== icon) {
				checkbox.checked = false;
				document.removeEventListener('click', listener);
			}
		};

		checkbox.addEventListener('click', function () {
			if (this.checked) {
				document.addEventListener('click', listener);
			}
		});
	})

	useEffect(() => {
		var checkbox2 = document.querySelector('#langOpenDropdown-lg');
		var icon2 = document.querySelector('#tooltip-parent1');
		var listener = function (e) {
			if (e.target !== checkbox2 && e.target !== icon2) {
				checkbox2.checked = false;
				document.removeEventListener('click', listener);
			}
		};
		checkbox2.addEventListener('click', function () {
			if (this.checked) {
				document.addEventListener('click', listener);
			}
		});
	})

	return (
		<>
			<div className="header">
				<div className="header-content">
					<nav className="navbar navbar-expand">
						<div className="collapse navbar-collapse justify-content-between header-right-container">
							<div className="header-left">
								<div className={`font-weight-600 font-20 text-black page-header`}>
									{translate(props.lang, pageTitle)}
								</div>
							</div>
							<ul className="navbar-nav header-right main-notification">
								<li className="nav-item px-2">
									<div className="header-lang-dropdown-container position-relative">
										<label htmlFor="langOpenDropdown-lg" className="lang-dropdown dropdown mb-0">
											<div className="position-relative tooltip-parent">
												<img src={require('../../../images/icon/language.svg').default} alt="language" className="header-profile-icon"/>
											</div>
										</label>
										<input type="checkbox" id="langOpenDropdown-lg" className="checkDropdown" hidden />
										<div className={`lang-dropdown-menu-lg`}>
											<div className="lang-menu-container text-center w-100">
												{lang.map((item, i) => (
													<span key={i} className={`font-weight-500 font-16 text-black-1`} onClick={() => changeLanguage(item.code)}>{item.name}</span>
												))}
											</div>
										</div>
									</div>
								</li>
								<li className="px-2 d-block d-lg-block d-md-block">
									<div className="nav-item">
										<div className="header-profile-dropdown-container position-relative">
											<label htmlFor="openDropdownProfile-lg" className="profile-dropdown dropdown mb-0 px-2">
												<div className="header-emptyImg-container">
													<img src={require('../../../images/avatar/profileSmall.svg').default} className="img-fluid mx-auto" id="tooltip-parent4" />
												</div>
											</label>
											<input type="checkbox" id="openDropdownProfile-lg" className="checkDropdown" hidden />
											<div className={`profile-dropdown-menu-lg py-3 px-3`}>
												<div className="profile-menu-container text-center w-100">
													<Row className="justify-content-center align-items-center w-100 mx-auto">
														<Col lg={4} md={4} xs={4} sm={4} className="text-center px-1">
															<div className="header-emptyImg-container mx-auto">
																{props.auth.profile.profilePicture ? (
																	<img src={props.auth.profile.profilePicture} className="img-fluid" />
																) : (
																	<img src={require('../../../images/avatar/profile.svg').default} className="img-fluid" />
																)}
															</div>
														</Col>
													</Row>
													<div className="mt-3 w-100">
														<Row className="justify-content-center align-items-center w-100 mx-auto">
															<Col lg={12} md={12} xs={12} sm={12} className="text-center px-1">
																<span className="font-weight-600 font-16 text-black text-break overflow-wrap-anywhere">{props.auth.profile.email}</span>
																<div className="mt-2">
																	<span className="font-weight-400 font-13 text-black text-break overflow-wrap-anywhere">{props.auth.profile.userId ? props.auth.profile.userId : ''}</span>
																</div>
															</Col>
														</Row>
													</div>
													<div className="mt-3 w-100">
														<Row className="justify-content-center align-items-center w-100 mx-auto">
															<Col lg={12} md={12} xs={12} sm={12} className="text-center px-1">
																<Link to="/profile" className="d-flex w-100 justify-content-center align-items-center py-2 py-lg-2 profile-menu-item px-4">
																	<div className="me-2">
																		<svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
																			<g clipPath="url(#clip0_324_1062)">
																				<path d="M15.864 2.63605C14.1641 0.936176 11.904 0 9.5 0C7.09602 0 4.83593 0.936176 3.13602 2.63605C1.43618 4.33593 0.5 6.59602 0.5 9C0.5 11.404 1.43618 13.6641 3.13602 15.3639C4.83593 17.0638 7.09602 18 9.5 18C11.904 18 14.1641 17.0638 15.864 15.3639C17.5638 13.6641 18.5 11.404 18.5 9C18.5 6.59602 17.5638 4.33593 15.864 2.63605ZM9.5 16.9453C7.14914 16.9453 5.03386 15.9187 3.57786 14.2906C4.4805 11.8976 6.79114 10.1953 9.5 10.1953C7.75252 10.1953 6.33594 8.77873 6.33594 7.03125C6.33594 5.28377 7.75252 3.86719 9.5 3.86719C11.2475 3.86719 12.6641 5.28377 12.6641 7.03125C12.6641 8.77873 11.2475 10.1953 9.5 10.1953C12.2089 10.1953 14.5195 11.8976 15.4221 14.2906C13.9661 15.9187 11.8509 16.9453 9.5 16.9453Z" fill="white" />
																			</g>
																			<defs>
																				<clipPath id="clip0_324_1062">
																					<rect width="18" height="18" fill="white" transform="translate(0.5)" />
																				</clipPath>
																			</defs>
																		</svg>
																	</div>
																	<span className="font-weight-400 font-15 text-capitalize ms-1 text-white">{translate(props.lang, "Profile")}</span>
																</Link>
															</Col>
														</Row>
													</div>
													<div className="mt-3 w-100">
														<Row className="justify-content-center align-items-center w-100 mx-auto">
															<Col lg={12} md={12} xs={12} sm={12} className="text-center px-1">
																<div className="d-flex w-100 justify-content-center align-items-center py-2 py-lg-2 profile-menu-item2 px-4" onClick={() => onLogout()}>

																	<div className="me-2">
																		<img src={require('../../../images/icon/logout.svg').default} className="img-fluid header-icon2 mx-auto" id="tooltip-parent4" />
																	</div>
																	<span className="font-weight-400 font-15 text-capitalize ms-1">{translate(props.lang, "Logout")}</span>
																</div>
															</Col>
														</Row>
													</div>

													<div className="light-green-container mt-3 py-3">
														<Row className="mx-0 px-0 align-items-center">
															<Col lg={9} md={9} sm={9} xs={9} className="text-start">
																<span className="font-weight-400 font-12 text-capitalize text-grey-1 d-block mb-1">{translate(props.lang, "Referral Code")}</span>
																<span className={`font-weight-500 font-16 text-black break-all-word`}>{props.auth.profile.referralCode}</span>
															</Col>
															<Col lg={3} md={3} sm={3} xs={3}>
																<img src={require('../../../images/icon/copy.svg').default} className="cursor-pointer img-fluid" alt="copy" onClick={() => copyText(props && props.auth && props.auth.profile.referralCode ? props.auth.profile.referralCode : "", props.lang)} />

															</Col>
														</Row>
														<Row className="mx-0 px-0 align-items-center mt-3">
															<Col lg={9} md={9} sm={9} xs={9} className="text-start">
																<span className="font-weight-400 font-12 text-capitalize text-grey-1 d-block mb-1">{translate(props.lang, "Referral Link")}</span>
																<span className={`font-weight-500 font-16 text-center text-black break-all-word lh-base overflow-wrap-anywhere`}>{props.auth.profile.referralCode ? window.location.origin + '/register?referral=' + props.auth.profile.referralCode : ''}</span>
															</Col>
															<Col lg={3} md={3} sm={3} xs={3}>
																<img src={require('../../../images/icon/copy.svg').default} className="cursor-pointer img-fluid" alt="copy" onClick={() => copyText(props.auth.profile.referralCode ? window.location.origin + '/register?referral=' + props.auth.profile.referralCode : '', props.lang)} />
															</Col>
														</Row>
													</div>
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = state => {
	const { auth, i18n } = state;
	return {
		lang: i18n.lang,
		auth: auth,
	}
}
export default withRouter(connect(mapStateToProps)(Header));

